import React, { useEffect, useMemo, useState } from "react";
import { components } from "react-select";
import Swal from "sweetalert2";
import translator from "../../Extra/Translation/Translate";
import UtilsService, { formatAmount } from "../../../Services/UtilsService";
import SelectInput from "../../Extra/Input/SelectInput";
import FidelityModel, {
    ISubscribeFidelity,
    VirtualType,
} from "../../../Models/Fidelity";
import FidelityService, {
    FidelityOrderPaymentType,
} from "../../../Services/FidelityService/FidelityService";
import alert_message from "../../../Services/Alert/AlertMessage";
import GatewayService from "../../../Services/Gateway/GatewayService";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import WalletService from "../../../Services/Wallets/WalletService";
import { UserCryptoWallet } from "../../../Models/UserWallet";
import { BaseCurrency } from "../../../Models/BaseCurrency";
import { useAssets } from "../../../App/Provider/AssetsProvider";
import { CARD_VIRTUEL_TYPE } from "../../../Models/UserCard";
import { useCardContext } from "../../../App/Provider/CardProvider";
import { useFlyoutContext } from "../../../App/Provider/FlyOutProvider";

interface Option {
    value: FidelityOrderPaymentType | string;
    label: string;
    imageUrl: string;
}

const SingleOption = (props: any) => {
    return (
        <components.SingleValue {...props}>
            <OptionImage {...props.data} />
        </components.SingleValue>
    );
};

const PrincipalAccount = ({ asset }: { asset: UserCryptoWallet }) => {
    const { translate } = translator();
    return (
        <div className="flex items-center">
            <div className="sm:h-8 sm:w-8 md:h-10 md:w-10 lg:h-10 lg:w-10 xl:h-10 xl:w-10 2xl:h-10 2xl:w-10">
                <img
                    src={UtilsService.getPhotoUrl(asset.logo)}
                    alt=""
                    className="h-full"
                />
            </div>
            <div className="block pl-2">
                <div className="text-medium text-slate-400/60 text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm">
                    {translate("WALLET", "BALANCE")}
                </div>
                <div className="text-sm font-bold">
                    {UtilsService.formatAmount(asset.wallet.solde, 2)}{" "}
                    {asset.abbreviation}
                </div>
            </div>
        </div>
    );
};

const OptionImage: React.FC<Option> = (props) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <img
                src={props.imageUrl}
                style={{
                    width: 60,
                    height: 30,
                    marginRight: 10,
                    borderRadius: "50%",
                    objectFit: "contain",
                }}
            />
            {props.label}
        </div>
    );
};

export const SubscribeCardVirtualContent = ({
    props,
    base_currency_system,
    hideTitle = false,
    cardType,
}: {
    props: FidelityModel;
    base_currency_system: BaseCurrency;
    current_fidelity: FidelityModel | undefined;
    cardType: CARD_VIRTUEL_TYPE;
    hideTitle?: boolean;
}) => {
    const { translate } = translator();
    const { selectedCard } = useCardContext();
    const { getPrincipalAccount } = useAssets();
    const [active, setActive] = useState<number>(1);
    const [processing, setProcessing] = useState<boolean>(false);
    const [kalypayOptions, setKalypayOptions] = useState<Array<Option>>([]);
    const [gateways, setGateways] = useState<any[]>([]);
    const [kalypayWallet, setKalypayWallet] = useState<Option | undefined>();
    const [walletFiat, setWalletFiat] = useState<Option | undefined>();
    const [user_fiat, setUserFiat] = useState<UserCryptoWallet>();
    const [discount, setDiscount] = useState<number>(0);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const { user } = useAuthentication();
    const [paymentMethod, setPaymentMethod] =
        useState<FidelityOrderPaymentType>(
            FidelityOrderPaymentType.WALLET_FIAT,
        );
    const {
        flyoutOpen,
        setFlyoutOpen,
        isRefresh,
        activeComponent,
        setActiveComponent,
        setContent,
        setTitle,
    } = useFlyoutContext();

    const [gateway, setGateway] = React.useState<Option | null>();
    const [wallets, setWallets] = useState<Array<UserCryptoWallet>>([]);

    const [currentLoyalty, setCurrentLoyalty] = useState<
        FidelityModel | undefined
    >(undefined);

    const getLoyalty = async () => {
        try {
            const res = await FidelityService.getDataLoyalty();
            setCurrentLoyalty(res.current);
        } catch (err) {
            console.error(err);
        }
    };

    const price_virtual_card = useMemo<number>(() => {
        const price =
            cardType === CARD_VIRTUEL_TYPE.CARD_VIRTUEL
                ? Number(props.price_virtual_card)
                : Number(props.price_virtual_card_ephemere);

        return price;
    }, [props]);

    const CustomOption = (props: any) => {
        return (
            <components.Option {...props} className="bg-select-view">
                <OptionImage {...props.data} />
            </components.Option>
        );
    };

    // console.log({ "selectedCard *****": selectedCard?.fidelity });

    const handleChangeActive = (active: number) => {
        setActive(active);
        getDiscount(active);
    };

    const getTotalWithDiscount = () => {
        const total = price_virtual_card * active;
        let discount_amount = 0;
        if (discount) {
            discount_amount = (total * discount) / 100;
        }
        const total_with_discount = total - discount_amount;
        setTotalAmount(total_with_discount);
    };

    const getDiscount = (active: number) => {
        switch (active) {
            case 3:
                setDiscount(props.discount_3 || 0);
                break;
            case 6:
                setDiscount(props.discount_6 || 0);
                break;
            case 12:
                setDiscount(props.discount_12 || 0);
                break;
            case 24:
                setDiscount(props.discount_24 || 0);
                break;
            default:
                setDiscount(0);
                break;
        }
    };

    const handleSubscribe = async (e: any) => {
        e.preventDefault();
        if (
            paymentMethod === FidelityOrderPaymentType.KALYPAY &&
            !kalypayWallet
        ) {
            alert_message.error(translate("Loyalty", "Please_Choose_Wallet"));
            return;
        }
        if (currentLoyalty) {
            const type_card =
                cardType === CARD_VIRTUEL_TYPE.CARD_VIRTUEL
                    ? VirtualType.VIRTUAL_CARD
                    : VirtualType.VIRTUAL_CARD_U;
            const data: ISubscribeFidelity = {
                fidelityId: Number(currentLoyalty.id),
                nbMonth: active,
                gateway: paymentMethod,
                wallet: principal?.abbreviation,
                type: "VIRTUAL",
                virtualType: type_card,
            };

            await Swal.fire({
                title: translate("Swal_Loyalty", "Confirmation"),
                text: `${translate(
                    "Swal_Loyalty",
                    "Do_You_Want_To_Order_Card",
                )} ?`,
                showCloseButton: true,
                confirmButtonColor:
                    "radial-gradient(circle at center,#3695D2 0%,#303B8E 100%)",
                showCancelButton: true,
                cancelButtonText: translate("Admin_Users", "Cancel"),
            }).then((res) => {
                data.order_card = Boolean(res.isConfirmed);
                if (res.isConfirmed) executeSubscribe(data);
            });
        }
    };

    const executeSubscribe = (subscribe_fidelity: ISubscribeFidelity) => {
        const subscribe = FidelityService.subscribeCardVirtual(
            subscribe_fidelity,
            translate,
        );
        const message =
            FidelityOrderPaymentType.WALLET_FIAT === paymentMethod
                ? translate("CARDS", "Subscription_Successfully_Completed")
                : translate("BACKEND_RESPONSE", "ORDER_CREATED");

        setProcessing(true);
        alert_message
            .promise(
                translate("CARDS", "Subscription_In_Progress"),
                subscribe,
                message,
            )
            .then((data: any) => {
                if (data.redirect) {
                    window.location = data.url;
                } else {
                    // navigate("/");
                    location.reload();
                }
            })
            .finally(() => {
                setProcessing(false);
                setActiveComponent(undefined);
                setFlyoutOpen(false);
            });
    };

    const optionFiats = useMemo<Option[]>(() => {
        const option: Option[] = [];
        wallets.map((elt: UserCryptoWallet) => {
            const data: Option = {
                label: elt.name,
                value: elt.abbreviation,
                imageUrl: UtilsService.getPhotoUrl(elt.logo),
            };

            option.push(data);
        });

        return option;
    }, [wallets]);

    const options = useMemo<Option[]>(() => {
        const kalypay = gateways.find((res: any) => res.id === "KALYPAY");
        const visa = gateways.find((res: any) => res.id === "VISA");
        const data = [];
        if (kalypay)
            data.unshift({
                value: FidelityOrderPaymentType.KALYPAY,
                label: "Kalypay",
                imageUrl: UtilsService.getPulicImage(
                    `/dist/image/Logo-Infinexia-Full.webp`,
                ),
            });

        if (visa)
            data.unshift({
                value: FidelityOrderPaymentType.VISA,
                label: translate("Gateway", "Visa"),
                imageUrl: UtilsService.getPulicImage(
                    `/dist/image/visa-and-master-card-hd.webp`,
                ),
            });
        if (data && data.length > 0) {
            setGateway(data[0]);
        }

        return data;
    }, [gateways]);

    const getAvailableGateway = async () => {
        try {
            const response = await GatewayService.getAvailableGateways();
            setGateways(response);
            const kalypay = response.find((res: any) => res.id === "KALYPAY");
            if (kalypay) {
                const wallets = kalypay.wallets;
                const option: Option[] = [];
                wallets.map((elt: string) => {
                    const kaly_crypto_option: Option = {
                        label: elt,
                        value: elt,
                        imageUrl: UtilsService.getPulicImage(
                            `/dist/image/wallet/${elt}.png`,
                        ),
                    };

                    option.push(kaly_crypto_option);
                });
                setKalypayOptions(option);
            }
        } catch (err: any) {}
    };

    useEffect(() => {
        getTotalWithDiscount();
    }, [active, discount]);

    useEffect(() => {
        getAvailableGateway();
        getLoyalty();
    }, []);

    const getUserWalletFiat = async () => {
        try {
            const response = await WalletService.getUserWallet();
            setWallets(
                response.filter(
                    (elt) =>
                        elt.type === "fiat" && !elt.abbreviation.includes("_"),
                ),
            );
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    useEffect(() => {
        getUserWalletFiat();
    }, []);

    const getAllInfos = (walletFiat: any) => {
        const res = wallets.find(
            (item: UserCryptoWallet) =>
                item.abbreviation === walletFiat?.value &&
                item.name === walletFiat?.label &&
                walletFiat?.value !== FidelityOrderPaymentType.KALYPAY &&
                walletFiat?.value !== FidelityOrderPaymentType.VISA,
        );
        if (res) {
            setUserFiat(res);
            // if (swapConfigurations) {
            //     getSwapMsg(res.type, swapConfigurations);
            // }
        }
    };

    useEffect(() => {
        getAllInfos(walletFiat);
    }, [walletFiat]);

    const handleValue = (selected: Option) => {
        setGateway(selected);
        if (selected) {
            const walletfiat = optionFiats.find(
                (res: any) => res.value === selected.value,
            );
            if (walletfiat) {
                setPaymentMethod(FidelityOrderPaymentType.WALLET_FIAT);
            } else {
                if (selected)
                    setPaymentMethod(
                        selected.value as FidelityOrderPaymentType,
                    );
            }
        }
        getAllInfos(selected);
    };

    const principal = getPrincipalAccount();

    return (
        <div className="relative">
            {/* {!hideTitle && (
                <div className="col-span-12 sm:col-span-12 lg:col-span-3 2xl:col-span-3 flex justify-center items-center">
                    {cardType === CARD_VIRTUEL_TYPE.CARD_VIRTUEL
                        ? translate("WALLET", "VIRTUAL_CARD")
                        : translate("WALLET", "VIRTUAL_CARD2")}
                </div>
            )} */}

            {!hideTitle && (
                <div className="col-span-12 sm:col-span-12 lg:col-span-3 2xl:col-span-3 flex justify-center items-center">
                    <div className="flex justify-center items-center p-2 text-lg font-bold">
                        {cardType === CARD_VIRTUEL_TYPE.CARD_VIRTUEL
                            ? translate("WALLET", "VIRTUAL_CARD")
                            : translate("WALLET", "VIRTUAL_CARD2")}
                    </div>
                </div>
            )}
            <div className="intro-y">
                <div className="md:px-5 lg:px-5 xl:px-5 py-4 border border-slate-400/60 rounded-lg block mt-5 mb-2.5 sm:px-2 bg-dark-content">
                    <div className="hidden">
                        {translate("Fidelity", "Moyent_paiement")}
                    </div>
                    <div className="relative mt-1 w-full group w-selects no-border-select hidden">
                        <SelectInput
                            id="id-credit-asset"
                            options={[...options, ...optionFiats]}
                            components={{
                                Option: CustomOption,
                                SingleValue: SingleOption,
                            }}
                            value={gateway}
                            onChange={handleValue}
                        />
                        <label
                            htmlFor="id-credit-asset"
                            className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                        ></label>
                        {paymentMethod ===
                            FidelityOrderPaymentType.WALLET_FIAT && (
                            <>
                                <div className="absolute top-12 my-2">
                                    {translate("Swap", "Your_Balance")} :{" "}
                                    {user_fiat
                                        ? formatAmount(
                                              user_fiat.wallet.solde,
                                              user_fiat.type === "fiat" ? 2 : 8,
                                          ) +
                                          " " +
                                          `${UtilsService.getAbbreviation(
                                              user_fiat,
                                          )}`
                                        : ""}
                                </div>
                            </>
                        )}
                    </div>
                    {principal && <PrincipalAccount asset={principal} />}

                    {gateway &&
                        gateway.value === FidelityOrderPaymentType.KALYPAY && (
                            <>
                                <div className="mt-4">
                                    {translate("Fidelity", "CHOOSE_WALLET")}
                                </div>
                                <div className="relative mt-1 w-full group w-selects no-border-select mail-border">
                                    <SelectInput
                                        id="id-kp-wallet"
                                        options={kalypayOptions}
                                        components={{
                                            Option: CustomOption,
                                            SingleValue: SingleOption,
                                        }}
                                        value={kalypayWallet}
                                        onChange={(value) => {
                                            setKalypayWallet(value);
                                        }}
                                    />
                                    <label
                                        htmlFor="id-kp-wallet"
                                        className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                    ></label>
                                </div>
                            </>
                        )}

                    {/* {gateway.value === FidelityOrderPaymentType.WALLET_FIAT && (
                    <>
                        <div className="mt-4">
                            {translate("TRANSACTION", "WALLET")}
                        </div>
                        <div className="relative mt-1 w-full group w-selects no-border-select mail-border">
                            <SelectInput
                                id="id-kp-wallet"
                                options={optionFiats}
                                components={{
                                    Option: CustomOption,
                                    SingleValue: SingleOption,
                                }}
                                value={walletFiat}
                                onChange={(value) => {
                                    setWalletFiat(value);
                                }}
                            />
                            <label
                                htmlFor="id-kp-wallet"
                                className="mb-10 absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            ></label>
                            <div className="absolute top-12 my-2">
                                {translate("Swap", "Your_Balance")} :{" "}
                                {user_fiat
                                    ? formatAmount(
                                          user_fiat.wallet.solde,
                                          user_fiat.type === "fiat" ? 2 : 8,
                                      ) +
                                      " " +
                                      `${UtilsService.getAbbreviation(
                                          user_fiat,
                                      )}`
                                    : ""}
                            </div>
                        </div>
                    </>
                )} */}

                    {/* <div className=" pt-5">{translate("Fidelity", "Duree")}</div>
                <div className="justify-start mt-1 flex space-x-2 w-full">
                    <button
                        className={
                            active === 1
                                ? "btn bg-white text-black-400 border-[1px] border-[#303B8E] !px-5 !py-2 relative md-mt sm:w-1/3 md:w-auto lg:w-auto xl:w-auto 2xl:w-auto bt-duration"
                                : "btn bg-white text-black-400 border-[1px] !px-5 !py-2 md-mt sm:w-1/3 md:w-auto lg:w-auto xl:w-auto 2xl:w-auto bt-duration"
                        }
                        type="button"
                        onClick={() => handleChangeActive(1)}
                    >
                        <div className="block">
                            <div className="text-slate-600/60 text-l">
                                {translate("Fidelity", "mois")}
                            </div>
                        </div>
                        {active === 1 && (
                            <div className="corner-ribbon">
                                <span className="cr-inner">
                                    <span className="cr-text"></span>
                                </span>
                            </div>
                        )}
                    </button>
                    <button
                        className={
                            active === 12
                                ? "btn bg-white text-black-400 border-[1px] border-[#303B8E] !px-5 !py-2 relative md-mt sm:w-1/3 md:w-auto lg:w-auto xl:w-auto 2xl:w-auto bt-duration"
                                : "btn bg-white text-black-400 border-[1px] !px-5 !py-2 md-mt sm:w-1/3 md:w-auto lg:w-auto xl:w-auto 2xl:w-auto bt-duration"
                        }
                        type="button"
                        onClick={() => handleChangeActive(12)}
                    >
                        <div className="block">
                            <div className="text-slate-600/60 text-l">
                                {translate("Fidelity", "annual")}
                            </div>
                        </div>

                        {active === 12 && (
                            <div className="corner-ribbon">
                                <span className="cr-inner">
                                    <span className="cr-text"></span>
                                </span>
                            </div>
                        )}
                    </button>
                </div> */}
                    <div className="flex mt-0 Pricemin-h-[50px] h-[50px]">
                        <div className="block"></div>
                    </div>
                    <div className="mt-0 pb-5 flex justify-between">
                        <div className="pt-0 flex w-40 font-bold">
                            <div>
                                {translate("Fidelity", "Price")} :{" "}
                                {props.is_text_price === false
                                    ? UtilsService.getConvertedFormatedAmount2(
                                          price_virtual_card,
                                          base_currency_system,
                                          user?.base_currency,
                                          2,
                                      )
                                    : props.text_price}
                            </div>
                            {discount > 0 && (
                                <div className="text-green-600 font-medium w-[60%] md:!w-[55%] mt-2">
                                    {translate("Fidelity", "Discount")}
                                </div>
                            )}
                        </div>
                        <div className="flex justify-end">
                            {gateway ? (
                                <button
                                    className="btn btn-perfect"
                                    disabled={processing}
                                    onClick={(e) => handleSubscribe(e)}
                                >
                                    {translate("WALLET", "CREATE_CARD")}
                                </button>
                            ) : (
                                <button
                                    className="btn btn-perfect"
                                    disabled={true}
                                >
                                    {translate("WALLET", "CREATE_CARD")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
