import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";

const bg = "/dist/image/bg-top.webp";

const Legal = () => {
    const { translate } = translator();
    return (
        <section className=" text-base text-[#333] font-medium break-words  ">
            <HelmetComponent
                title={translate("SEO", "Title_Legal")}
                description={translate("SEO", "Desc_Legal")}
                link="/legal"
                keywords={translate("SEO", "Keys_Legal")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={true}
            ></HelmetComponent>
            <div
                className="  h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat  flex justify-center items-center text-white"
                style={{ backgroundImage: `url(${bg})` }}
            >
                <h1 className="pt-10 md:!text-3xl text-2xl font-bold tracking-wide text-center">
                    {translate("LEGAL_PAGE", "TITLE")}
                </h1>
            </div>
            <div className=" bg-white py-[100px]">
                <div>
                    <div className=" w-[80%] max-w-[1080px] m-auto sm:text-base py-6 text-base   ">
                        <div className=" pb-10 mb-[36px]    ">
                            <p className="text-base text-left font-normal pb-[5px]">
                                {translate("LEGAL_PAGE", "CREATED_BY")}{" "}
                                <strong>
                                    {translate(
                                        "LEGAL_PAGE",
                                        "INFINEXIA_LIMITED",
                                    )}
                                </strong>
                                .
                            </p>
                            <p className="text-base text-left font-normal pb-[25px]">
                                {translate("LEGAL_PAGE", "COMPAGNY_REGISTERED")}
                            </p>
                            <div className="">
                                <p className="text-base text-left font-normal">
                                    <strong>
                                        {translate("LEGAL_PAGE", "OFFICE_INF")}
                                    </strong>
                                    <br></br>
                                    {translate("LEGAL_PAGE", "ADDRESS_INF1")}
                                    <br></br>
                                    {translate("LEGAL_PAGE", "ADDRESS_INF2")}
                                    <br></br>
                                    {translate("LEGAL_PAGE", "ADDRESS_INF3")}
                                    <br></br>
                                    {translate("LEGAL_PAGE", "ADDRESS_INF4")}
                                </p>
                            </div>
                            <p className="text-base text-left font-normal pt-[25px]">
                                <strong>
                                    {translate("LEGAL_PAGE", "HOST")}
                                </strong>
                            </p>
                            <div className="">
                                <p className="text-base text-left font-normal">
                                    <b>
                                        {translate(
                                            "LEGAL_PAGE",
                                            "PLANETHOSTER",
                                        )}
                                    </b>
                                </p>
                                <p className="text-base text-left font-normal">
                                    {translate("LEGAL_PAGE", "ADDRESS_HOST1")}
                                    <br></br>
                                    {translate("LEGAL_PAGE", "ADDRESS_HOST2")}
                                    <br></br>
                                    {translate("LEGAL_PAGE", "ADDRESS_HOST3")}
                                    <br></br>
                                    {translate("LEGAL_PAGE", "ADDRESS_HOST4")}
                                </p>
                            </div>
                            <p className="text-base text-left font-bold mt-[50px] pb-[5px]">
                                {translate("LEGAL_PAGE", "LEGAL_TITLE_1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_1-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_1-2")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_1-3")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("LEGAL_PAGE", "LEGAL_TITLE_2")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_2-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_2-2")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_2-3")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_2-4")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_2-5")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("LEGAL_PAGE", "LEGAL_TITLE_3")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_3-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_3-2")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("LEGAL_PAGE", "LEGAL_TITLE_4")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_4-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_4-2")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_4-3")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("LEGAL_PAGE", "LEGAL_TITLE_5")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_5-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_5-2")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_5-3")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_5-4")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_5-5")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("LEGAL_PAGE", "LEGAL_TITLE_6")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("LEGAL_PAGE", "PARAGRAPH_6-1")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Legal;
