import React from "react";
import { Provider } from "react-redux";

import "./App.css";
import { store } from "../globalState/Store";
import AuthenticationProvider from "./AuthenticationProvider";
import MessageProvider from "./MessageProvider";
import AppRoutes from "../Routes/AppRoutes";
import { createStore } from "@reduxjs/toolkit";
import reducers from "../globalState/reducers";
import { ChangeAppLanguage } from "../globalState/reducers/Language.reducer";
import LanguageProvider from "./LanguageProvider";
import Base from "../Layouts/Account/Base";
import BaseCurrencyProvider from "./Provider/BaseCurrencyProvider";

const App: React.FC = () => {
    let storage = store;
    if (typeof window !== "undefined") {
        const initialState = (window as any).__INITIAL_STATE__;
        if (initialState) {
            storage = createStore(reducers, {
                auth: initialState.auth,
                language: store.getState().language,
                theme: store.getState().theme,
            });
            storage.dispatch(
                ChangeAppLanguage({
                    language: store.getState().language.language,
                }),
            );
        }
    }

    return (
        <Provider store={storage}>
            <BaseCurrencyProvider>
                <AuthenticationProvider>
                    <MessageProvider>
                        <AppRoutes />
                    </MessageProvider>
                </AuthenticationProvider>
            </BaseCurrencyProvider>
        </Provider>
    );
};

export default App;
