export interface ITransaction {
    crypto_abbreviation: string;
    amount: number;
    token2FA: string;
}

export interface ITransactionParentChild {
    crypto_abbreviation: string;
    amount: number;
}

export enum TransactionType {
    CREDIT = "CREDIT",
    DEBIT = "DEBIT",
}
