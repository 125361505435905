import React from "react";
import AppAuthentication from "../../../Services/Authentication/AppAuthentication";

import { ModalContext } from "../../Extra/Modal/ModalContext";
import translator from "../../Extra/Translation/Translate";
import MessageSvg from "../Svg/MessageSvg";
import { CoOwnerRequest } from "../../../Models/UserAssetCoOwner";
import { useCoOwnerRequestAction } from "../../../Hooks/useCoOwnerRequest";

type CoOwnerInvitationProps = {
    refresh: () => void;
    request: CoOwnerRequest;
};

const RequestContent = ({ request, refresh }: CoOwnerInvitationProps) => {
    const { translate } = translator();
    const { getInformationAsync } = AppAuthentication();
    const { handleModal } = React.useContext(ModalContext);

    const { decline, accept, loading } = useCoOwnerRequestAction(refresh);

    const style = {
        fontFamily: " arial, helvetica, sans-serif",
        fontSize: "12pt",
    };

    return (
        <>
            <div className="text-lg text-center uppercase">
                {translate("INVITATION_REQUEST", "OWNER_TITLE", {
                    account_type: translate(
                        "Earning",
                        request.asset.type === "jackpot" ? "Jackpot" : "Safe",
                    ),
                })}
            </div>

            <div className="p-5 block mt-4 mb-2.5">
                <div className="text-justify">
                    <p>
                        <span style={style}>
                            {translate(
                                "INVITATION_REQUEST",
                                "OWNER_DESCRIPTION",
                                {
                                    first_name_owner: request.owner.firstName,
                                    last_name_owner: request.owner.lastName,
                                    account_name: request.asset.name,
                                },
                            )}
                        </span>
                    </p>
                </div>
            </div>
            <div className="mt-2 flex gap-2 justify-end w-full">
                <button
                    className="btn btn-perfect"
                    onClick={() => {
                        accept(request.id).then((data) => {
                            if (data) handleModal();
                        });
                    }}
                    disabled={loading}
                >
                    {translate("INVITATION_REQUEST", "ACCEPT")}
                </button>
                <button
                    className="btn bg-red-500 hover:bg-red-400 text-white"
                    onClick={() => {
                        decline(request.id).then((data) => {
                            if (data) handleModal();
                        });
                    }}
                    disabled={loading}
                >
                    {translate("INVITATION_REQUEST", "DECLINE")}
                </button>
            </div>
        </>
    );
};

const CoOwnerInvitation = ({ request, refresh }: CoOwnerInvitationProps) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    return (
        <div
            className={`${"bg-not-view p-2 rounded-b-lg rounded-r-lg cursor-pointer mb-2"}`}
            role="button"
            onClick={async () => {
                handleModal(
                    <RequestContent request={request} refresh={refresh} />,
                );
            }}
            title={translate("Notification", "See")}
        >
            <div className="flex justify-between">
                <div>
                    <div className="inline-flex text-xs font-medium items-center">
                        <MessageSvg /> Invitation
                    </div>
                    <div className={"block truncate mt-2 text-xs text-left"}>
                        {translate(
                            "INVITATION_REQUEST",
                            "OWNER_SHORT_DESCRIPTION",
                            {
                                first_name_owner: request.owner.firstName,
                                last_name_owner: request.owner.lastName,
                            },
                        )}
                    </div>
                </div>
                <div className="text-xs self-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-eye"
                    >
                        <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                        <circle cx="12" cy="12" r="3" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default CoOwnerInvitation;
