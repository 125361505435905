import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const getAvailableGateways = async (): Promise<any> => {
    try {
        const request = await axios.get("/gateways/available");

        return request.data;
    } catch (error: any) {
        return Promise.reject(UtilsService.getAxiosErrorMessage(error));
    }
};

const getAvailableGatewaysOut = async (): Promise<any> => {
    try {
        const request = await axios.get("/gateways/out-available");

        return request.data;
    } catch (error: any) {
        return Promise.reject(UtilsService.getAxiosErrorMessage(error));
    }
};

const GatewayService = {
    getAvailableGateways,
    getAvailableGatewaysOut,
};

export default GatewayService;
