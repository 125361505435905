import "animate.css";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import UtilsService from "../../../Services/UtilsService";
import "react-tooltip/dist/react-tooltip.css";
import NotificationService from "../../../Services/NotificationService/NotificationService";
import NotificationModel from "../../../Models/NotificationModel";
import CloseSimpleSvg from "../Svg/CloseSimpleSvg";
import VuSvg from "../Svg/VuSvg";
import MessageSvg from "../Svg/MessageSvg";
import { Tooltip } from "react-tooltip";
import useAuthentication from "../../../Services/Authentication/useAuthentication";

const Notification: React.FC = () => {
    const { user } = useAuthentication();
    const { translate } = translator();
    const [show, setShow] = useState<boolean>(false);
    const [toggle, setToggle] = useState<boolean>(false);
    const [showRedCircle, setShowRedCircle] = useState<boolean>(false);
    const [notificationList, setNotificationList] = useState<
        Array<NotificationModel>
    >([]);
    const getAll = async () => {
        const res = await NotificationService.getAll();
        const not_seen = res.filter(
            (elt: NotificationModel) => elt.is_seen === false,
        );
        if (not_seen.length > 0) {
            setShowRedCircle(true);
        } else {
            setShowRedCircle(false);
        }
        const limitedRes = res.slice(0, 10);
        setNotificationList(limitedRes);
    };

    const seeNotification = async (elt: NotificationModel) => {
        if (elt.is_seen == false) {
            await NotificationService.isSeen(elt.id);
            getAll();
        }
    };
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const startTimer = () => {
        intervalRef.current = setTimeout(() => {
            // Function to be executed every 30 seconds
            getAll();

            // Restart the timer
            startTimer();
        }, 1800000); // 30 seconds in milliseconds
    };
    useEffect(() => {
        getAll();
        startTimer();
    }, []);

    const tooltipRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target as Node)
            ) {
                setShow(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]);

    useEffect(() => {
        const doc = document.getElementById("mobile-header");
        if (show) {
            if (doc && !doc.classList.contains("scrolling2")) {
                doc.classList.add("scrolling2");
                doc.classList.add("via-flyout");
            }
        } else {
            if (doc && doc.classList.contains("via-flyout")) {
                doc.classList.remove("scrolling2");
                doc.classList.remove("via-flyout");
            }
        }
    }, [show]);

    return (
        <div ref={tooltipRef}>
            <button
                type="button"
                className="bg-transparent relative border-none use-tooltip-notif"
                onClick={() => {
                    setShow(!show);
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    icon-name="bell"
                    data-lucide="bell"
                    className="lucide lucide-bell notification__icon text-white mt-1.5"
                >
                    <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                    <path d="M13.73 21a2 2 0 01-3.46 0"></path>
                </svg>
                {showRedCircle === true && (
                    <div className="absolute inline-flex items-center justify-center w-3 h-3 p-1 text-white bg-red-500 rounded-full top-[-0.25px] right-[8px]"></div>
                )}
            </button>
            <div className={` ${show ? "" : "!hidden"}`}>
                <Tooltip
                    anchorSelect=".use-tooltip-notif"
                    place="bottom"
                    events={["click"]}
                    isOpen={show}
                    className={`h-[88vh] w-notif-menu  bg-content-notif p-0 !opacity-100 !top-[50px] shadow-lg right-[400px] relative animate__animated animate__slideInRight !pointer-events-auto`}
                    style={{
                        // zIndex: "1000000000000000000",
                        // backgroundColor: "",
                        // height: "auto",
                        // position: "relative",
                        top: "50px",
                        // boxShadow: "0px 3px 20px #00000030",
                        // width: "500px",
                        padding: "0px 0px",
                    }}
                    opacity={1}
                >
                    <div className="w-full p-2">
                        <div className="flex justify-between pb-4">
                            <div>
                                <h2 className="text-lg p-2 text-bold text-notif">
                                    {translate("Notification", "Notif_title")}
                                </h2>
                            </div>
                            <button
                                type="button"
                                className="!bg-red-500 border-none absolute top-5 right-5 !rounded-lg md-right-notif "
                                onClick={() => {
                                    setShow(false);
                                }}
                            >
                                <CloseSimpleSvg />
                            </button>
                        </div>
                        <div className="flex justify-end mr-2">
                            <button
                                type="button"
                                className="btn btn-perfect"
                                onClick={async () => {
                                    await NotificationService.markAllSeen();
                                    getAll();
                                }}
                            >
                                {translate("Notification", "Mark_ALL_Seen")}
                            </button>
                        </div>
                        <div className="p-2 w-full">
                            {notificationList.map(
                                (elt: NotificationModel, key: number) => (
                                    <div
                                        className={`${
                                            !!elt.is_seen
                                                ? "bg-view p-2 rounded-b-lg rounded-r-lg cursor-pointer mb-2"
                                                : "bg-not-view p-2 rounded-b-lg rounded-r-lg cursor-pointer mb-2"
                                        }`}
                                        role="button"
                                        onClick={() => seeNotification(elt)}
                                        title={translate("Notification", "See")}
                                        key={key}
                                    >
                                        <div className="flex justify-between">
                                            <div className="inline-flex text-xs font-medium items-center">
                                                {!!elt.is_seen ? (
                                                    <VuSvg />
                                                ) : (
                                                    <MessageSvg />
                                                )}
                                                &nbsp;
                                                {elt.wallet_history
                                                    ? translate(
                                                          "TransactionType",
                                                          elt.wallet_history
                                                              .type,
                                                      )
                                                    : elt.co_owner
                                                      ? translate(
                                                            "CO_OWNER_NOTIFICATION",
                                                            elt.co_owner.type,
                                                        )
                                                      : elt.title}
                                            </div>
                                            <div className="text-xs">
                                                {UtilsService.getBeautifulFormatedDateWithTime(
                                                    elt.created_at,
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                !toggle
                                                    ? "block truncate mt-2 text-xs text-left"
                                                    : "block mt-2 text-xs text-left"
                                            }
                                        >
                                            {elt.wallet_history?.getTransactionDetails(
                                                user!,
                                                translate,
                                            )}

                                            {elt.co_owner &&
                                                translate(
                                                    "CO_OWNER_NOTIFICATION",
                                                    `DESCRIPTION_${elt.co_owner.type}`,
                                                    {
                                                        first_name:
                                                            elt.co_owner.user
                                                                .first_name,
                                                        last_name:
                                                            elt.co_owner.user
                                                                .last_name,
                                                        account:
                                                            elt.co_owner.wallet
                                                                .name,
                                                    },
                                                )}
                                        </div>
                                    </div>
                                ),
                            )}
                        </div>
                    </div>
                </Tooltip>
            </div>

            {/* <div
                className={
                    show
                        ? "block relative animate__animated animate__backInDown"
                        : "animate__animated animate__backOutUp"
                }
            >
                <div className="w-[300px] absolute right-0 top-6 bg-transparent z-50">
                </div>
            </div> */}
        </div>
    );
};

export default Notification;
