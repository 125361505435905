export interface IJoinAccountRequestPayload {
    recipient_uid: string;
}

export interface OUserJoinAccountRequest {
    id: number;
    offeror: {
        uid: string;
        last_name: string;
        first_name: string;
    };
    recipient: {
        uid: string;
        last_name: string;
        first_name: string;
    };
    created_at: Date;
}

export enum JoinRequestStatus {
    PENDING = "pending",
    ACCEPTED = "accepted",
    DECLINED = "declined",
}
