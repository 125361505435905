import GovernanceVote, { IGovernanceVote } from "../../Models/GovernanceVote";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const map = (item: any): GovernanceVote => {
    const governance = new GovernanceVote(item.id, item.status);

    governance.status = item.status;
    governance.created_at = item.created_at;
    governance.updated_at = item.updated_at;
    governance.governanceId = item.governance.id;
    governance.id = item.id;
    return governance;
};

const createOrUpdate = async (payload: IGovernanceVote, translate: any) => {
    try {
        const result = await axios.post(`/governance-vote`, {
            ...payload,
        });

        return Promise.resolve(translate("Governance", `${result.data.code}`));
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(
            translate("Governance", `${err.response.data.code}`),
        );
    }
};

const userVote = async () => {
    return axios
        .get(`/governance-vote/user-governance`)
        .then(({ data }) => {
            const vote: GovernanceVote[] = data.map((item: any) => map(item));

            return vote;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const GovernanceVoteService = {
    createOrUpdate,
    userVote,
};

export default GovernanceVoteService;
