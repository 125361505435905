import UserAssetCoOwner, {
    CoOwnerRequest,
} from "../../Models/UserAssetCoOwner";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";
const mapUserAssetCoOwner = (item: any): UserAssetCoOwner => {
    const user = new UserAssetCoOwner();
    user.id = item.id;
    user.user = item.user;
    user.credit = item.credit;
    user.debit = !!item.debit;
    user.user_asset = !!item.user_asset;

    return user;
};
const saveUser = async (perso_uid: string, abrev: string): Promise<any> => {
    return axios
        .post(`/user-asset-co-owner/add-user`, {
            uid: perso_uid,
            slug: abrev,
        })
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllByAbbrev = async (
    abrev: string,
): Promise<Array<UserAssetCoOwner>> => {
    return axios
        .get(`/user-asset-co-owner/${abrev}`)
        .then(({ data }) => {
            const userAssetCoOwners: UserAssetCoOwner[] = data.map(
                (item: any) => mapUserAssetCoOwner(item),
            );
            return userAssetCoOwners;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateCreditDebit = async (id: number, type: string): Promise<any> => {
    return axios
        .post(`/user-asset-co-owner/update-credit-debit/${id}`, {
            type: type,
        })
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const deleteUserAssetCoById = async (id: number) => {
    return axios
        .delete(`/user-asset-co-owner/${id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getRequest = async (): Promise<CoOwnerRequest[]> => {
    return axios
        .get(`/user-asset-co-owner/pending-request`)
        .then((res) => Promise.resolve(res.data))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const acceptRequest = async (id: number): Promise<string> => {
    return axios
        .put(`/user-asset-co-owner/${id}/accept`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err, true));
        });
};

const declineRequest = async (id: number): Promise<string> => {
    return axios
        .put(`/user-asset-co-owner/${id}/decline`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err, true));
        });
};

const quitRequest = async (abbreviation: string): Promise<string> => {
    return axios
        .put(`/user-asset-co-owner/${abbreviation}/quit`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err, true));
        });
};

const UserAssetCoOwnerService = {
    saveUser,
    getAllByAbbrev,
    updateCreditDebit,
    deleteUserAssetCoById,
    getRequest,
    acceptRequest,
    declineRequest,
    quitRequest,
};
export default UserAssetCoOwnerService;
