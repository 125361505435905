import UserWallet, { OUserAsset } from "./UserWallet";

export interface ICrypto {
    name: string;
    abbreviation: string;
    status: boolean;
    default_roi: number;
    logo?: File;
    current_price?: number;
    lower_stacking?: number;
    withdrawal_fee?: number;
    withdrawal_fee_type?: string;
    description?: string;
    asset_stacking?: string;
    category?: string[];
    standby_crypto?: boolean;
    purchase?: boolean;
    swap_status?: boolean;
    withdraw_status?: boolean;
    swap_fee_type?: string;
    swap_fee?: number;
    purchase_fees_margin?: number;
    service_fee?: number;
    minimum_deposit?: number;
    minimum_withdraw?: number;
    maximum_deposit?: number;
    monthly_max_withdraw?: number;
    currency?: string;
}

export default class Crypto {
    id?: number;
    name!: string;
    abbreviation!: string;
    current_price?: number;
    previous_price?: number;
    default_roi!: number;
    logo!: string;
    created_at?: Date;
    updated_at?: Date;
    status?: boolean;
    user_wallet?: UserWallet[];
    type?: string;
    lower_stacking?: number;
    withdrawal_fee?: number;
    withdrawal_fee_type?: string;
    description?: string;
    purchase_fees_margin?: number;
    service_fee?: number;
    minimum_deposit?: number;
    minimum_withdraw?: number;
    maximum_deposit?: number;
    deposit_address?: {
        memo?: string;
        address?: string;
        network?: string;
    };

    is_deposit_kalypay?: boolean;
    standby_crypto?: boolean;
    purchase?: boolean;
    is_new?: boolean;
    swap_status?: boolean;
    withdraw_status?: boolean;
    deposit_status?: boolean;
    is_stable_coin?: boolean;
    swap_fee_type?: WithdrawalFeeType;
    swap_fee?: number;
    rank!: number;
    price_change_percentage_24?: number;
    coingeckoid?: number;
    rank_earning!: number;
    show_earning?: boolean;
    monthly_max_withdraw?: number;
    payment_method_config?: any; //FiatPaymentConfig
    currency?: string;
    is_earning_whitebit?: boolean;
    user_asset?: OUserAsset;
    is_personal?: boolean;
    personal_asset_detail?: OUserAsset;

    constructor(
        name: string,
        abbreviation: string,
        default_roi: number,
        rank: number,
        currency: string,
    ) {
        this.name = name;
        this.abbreviation = abbreviation;
        this.default_roi = default_roi;
        this.rank = rank;
        this.currency = currency;
    }
}

export enum WithdrawalFeeType {
    PERCENT = "percent",
    FIX = "fix",
}

export interface FeeConfig {
    fee: number;
    type: WithdrawalFeeType;
}

export interface WhitebitBalance {
    [key: string]: any;
}

export interface EarningSystem {
    [key: string]: any;
}

export enum UserAssetAction {
    FIAT_ACCOUNT = "fiat_account",
    SUB_ACCOUNT = "sub_account",
    JACKPOT = "jackpot",
    PIGGY_BANK = "piggy_bank",
    JOIN_ACCOUNT = "join_account",
    ACCOUNT_18 = "account_18",
}

export interface IUserAsset {
    logo?: any;
    currency: string;
    action: UserAssetAction;
    name: string;
    show_amount: boolean;
    show_objective: boolean;
    objective: number;
    jackpot_description?: string;
    jackpot_slug?: string;
    crop_data?: CropData;
}

export interface IUserAssetUpdate {
    logo?: any;
    name: string;
    objective?: string;
    jackpot_description?: string;
    jackpot_slug?: string;
    show_objective?: boolean;
    show_amount?: boolean;
    crop_data?: CropData;
    cropped_logo?: any;
}

export interface IJackpotPaymentPayload {
    // wallet_abbreviation: string;
    user_uid: string;
    amount: number;
    jackpot_slug: string;
    method: "infinexia" | "cb" | "payeer";
    participant?: string;
}

export interface MinOCrypto {
    name: string;
    abbreviation: string;
    description?: string;
    current_price: number;
    previous_price: number;
    default_roi: number;
    logo: string;
    lower_stacking: number;
}

export interface OJackpotPayment {
    id: number;
    wallet: MinOCrypto;
    amount: number;
    target_wallet: MinOCrypto;
}

export enum JackpotPaymentStatus {
    PENDING = "pending",
    EXPIRATED = "expirated",
    CANCELLED = "cancelled",
    COMPLETED = "completed",
}

export interface PotsPayload {
    slug: string;
}

export interface OCryptoPots {
    logo: string;
    name: string;
    user_name: string;
    jackpot_description: string;
    objective: string;
    show_objective: boolean;
    show_amount: boolean;
    amount_symbol?: any;
    crop_data: CropData;
    cropped_logo: string;
}

export interface CropData {
    x: string;
    y: string;
    zoom: number;
    crop_x: number;
    crop_y: number;
}

export interface CryptoChartData {
    abbr: string;
    labels: string[];
    data: number[];
}
export interface ICryptoPrices {
    abbreviations: string[];
}
export type IMarketTrends = string[];
export interface MarketData {
    market_cap: {
        usd: number;
        // Add other currencies if needed
    };
    total_volume: {
        usd: number;
    };
    circulating_supply: number;
    max_supply: number;
    low_24h: {
        usd: number;
    };
    high_24h: {
        usd: number;
    };
    price_change_24h: number;
    atl: {
        usd: number;
    };
    atl_change_percentage: number;
    ath: {
        usd: number;
    };
    ath_change_percentage: number;
}

export enum MethodPaymetJackpot {
    INFINEXIA = "infinexia",
    CB = "cb",
    PAYEER = "payeer",
}
