import { IMarketTrends } from "../../Models/Crypto";
import { IUserSignUp } from "../../Models/User";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const verifyToken = async () => {
    try {
        const result = await axios.post(`/verify-token`);
        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const signIn = async (
    username: string,
    password: string,
    recaptcha_token: string | null,
    rememberMe: boolean,
) => {
    try {
        const result = await axios.post(`/auth/sign-in`, {
            login: username,
            password,
            token: recaptcha_token,
            rememberMe: rememberMe,
        });

        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
            message: err.message,
        };
    }
};

const switchToJoin = async () => {
    try {
        const result = await axios.get(`/auth/switch-to-join-account`);

        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
            message: err.message,
        };
    }
};

const switchToReal = async () => {
    try {
        const result = await axios.get(`/auth/switch-to-origin-account`);

        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
            message: err.message,
        };
    }
};

const verify2FA = async (token: string) => {
    try {
        const result = await axios.post(`/auth/verify-2fa`, {
            token,
        });

        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
            message: err.message,
        };
    }
};

const logout = async () => {
    try {
        return await axios.get(`/auth/logout`);
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getInformation = async (token?: string, cookies?: string) => {
    try {
        const result = await axios.get(`/user/information`, {
            headers: {
                ...(token && {
                    Authorization: token,
                }),
                ...(cookies && {
                    Cookie: cookies,
                }),
            },
        });
        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getUser = async (token?: string, cookies?: string) => {
    const result = await getInformation(token, cookies);

    if (result.error && result.response) {
        //check 2FA
        if (result.response.data.code === "2FA_NEEDED") {
            return {
                user: undefined,
                fa2: true,
            };
        }

        return {
            user: undefined,
            fa2: false,
        };
    }

    const user = result.response.data;

    return {
        user: user,
        fa2: false,
    };
};

const signUp = async (payload: IUserSignUp, repeatPassword: string) => {
    try {
        if (payload.password !== repeatPassword) {
            return Promise.reject(
                "Password and repeat password does not match",
            );
        }
        const result = await axios.post(`/auth/signup`, {
            ...payload,
        });

        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
            message: err.message,
        };
    }
};

const updateMarketTrends = async (payload: IMarketTrends): Promise<string> => {
    return axios
        .post("/user/market-trends", payload)
        .then(() => {
            return Promise.resolve("Mise à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

export {
    verifyToken,
    signIn,
    logout,
    getInformation,
    signUp,
    verify2FA,
    switchToJoin,
    switchToReal,
    getUser,
    updateMarketTrends,
};
