import React, { useEffect, useRef, useState } from "react";
import {
    FieldValues,
    RegisterOptions,
    useController,
    useForm,
    useFormContext,
} from "react-hook-form";
import {
    ExclamationCircleIcon,
    EyeIcon,
    EyeSlashIcon,
} from "@heroicons/react/20/solid";
import NumberInput from "../../../Components/Extra/Input/InputNumber";
import { formatAmount } from "../../../Services/UtilsService";

type KpInputProps = {
    label?: string;
    name: string;
    type: string;
    placeholder?: string;
    disabled?: boolean;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    options?: RegisterOptions<FieldValues, string>;
    className?: string;
    decimal?: number;
    required?: boolean;
};

const KpInput = ({
    label,
    name,
    type,
    placeholder,
    prefix,
    suffix,
    inputProps,
    options,
    className,
    disabled = false,
    decimal = 0,
    required = false,
}: KpInputProps) => {
    const {
        register,
        formState: { errors },
        setValue,
    } = useFormContext();
    const [showPassword, setShowPassword] = React.useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { ref, ...rest } = register(name);
    const { field } = useController({
        name,
        rules: { required: required },
    });

    const triggerClickOnIcon = () => {
        inputRef.current?.showPicker();
    };

    const valueRef = React.useRef<HTMLInputElement>(null);

    const [value, dispatchValue] = React.useReducer(
        (state: string, newValue: string) => {
            newValue = newValue.replace(".", ",");

            const [formattedWholeValue, decimalValue = "0"] =
                newValue.split(",");

            const significantValue = formattedWholeValue.replace(/\s+/g, "");
            const floatValue = parseFloat(
                significantValue + "." + decimalValue.slice(0, decimal),
            );

            if (isNaN(floatValue) === false) {
                const n = formatAmount(floatValue, decimal);
                if (Number(floatValue) !== field.value) {
                    field.value = floatValue;
                }
                if (newValue.includes(",") && !n.includes(",")) {
                    return n + "," + decimalValue;
                }
                return n;
            }
            return "0";
        },
        formatAmount(field.value),
    );

    useEffect(() => {
        if (type === "number") dispatchValue(formatAmount(field.value));
    }, [field.value]);

    useEffect(() => {
        setValue(name, field.value);
    }, [field.value]);

    return (
        <div
            className={`${className} relative w-full ${
                disabled ? "pointer-events-none opacity-50" : ""
            }`}
        >
            <div className={`relative `}>
                {label && (
                    <label
                        htmlFor={name}
                        className="absolute top-2 left-1 z-2 origin-[0] -translate-y-4 scale-75 !bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                    >
                        {label} {options?.required && "*"}
                    </label>
                )}
                {prefix !== undefined && (
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        {prefix}
                    </div>
                )}
                {type === "number" ? (
                    <>
                        <div onClick={() => valueRef.current?.focus()}>
                            <input
                                type="text"
                                // pattern="d+(,d{6})?"
                                value={value}
                                className={`w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 py-3 text-sm text-gray-900 focus:outline-none focus:ring-0  w-full ${
                                    prefix !== undefined ? "pl-5" : ""
                                } ${errors[name] ? "pr-10 !border-red" : ""} ${
                                    suffix !== undefined ? "pr-10" : ""
                                }
      ${type === "number" ? "appearance-none " : " "}

       placeholder:text-gray-500 focus:ring-0 sm:text-sm text-right`}
                                name={name}
                                onChange={(e) => dispatchValue(e.target.value)}
                                ref={valueRef}
                                disabled={disabled}
                            />
                        </div>

                        <input
                            ref={inputRef}
                            {...rest}
                            type={"hidden"}
                            id={name}
                            placeholder={placeholder}
                            {...inputProps}
                            disabled={disabled}
                            className={` w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm  focus:outline-none focus:ring-0  w-full ${
                                prefix !== undefined ? "pl-5" : ""
                            } ${errors[name] ? "pr-10" : ""} ${
                                suffix !== undefined ? "pr-10" : ""
                            }
          ${type === "number" ? "appearance-none " : " "}

           placeholder:text-gray-500 focus:ring-0 sm:text-sm`}
                        />
                    </>
                ) : (
                    <input
                        ref={(c) => {
                            ref(c);
                            inputRef.current = c;
                        }}
                        {...rest}
                        type={
                            showPassword
                                ? "text"
                                : type === "card"
                                  ? "number"
                                  : type
                        }
                        id={name}
                        placeholder={placeholder}
                        {...inputProps}
                        disabled={disabled}
                        className={` w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0  w-full ${
                            prefix !== undefined ? "pl-5" : ""
                        } ${errors[name] ? "pr-10" : ""} ${
                            suffix !== undefined ? "pr-10" : ""
                        }
          ${type === "number" ? "appearance-none " : " "}

           placeholder:text-gray-500 focus:ring-0 sm:text-sm`}
                    />
                )}

                {errors[name] ? (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                        />
                    </div>
                ) : suffix !== undefined ? (
                    <div
                        onClick={() => triggerClickOnIcon()}
                        className="absolute inset-y-0 right-0 z-[1] flex cursor-pointer items-center  pr-3"
                    >
                        {suffix}
                    </div>
                ) : (
                    type === "password" && (
                        <div
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute inset-y-0 right-0 z-10 flex cursor-pointer items-center pr-3 font-medium "
                        >
                            {showPassword ? (
                                <EyeIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                />
                            ) : (
                                <EyeSlashIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                />
                            )}
                        </div>
                    )
                )}
            </div>
            {errors[name] && (
                <p
                    id={`${name}-error`}
                    className="ml-1 mt-1 text-sm text-red-500"
                >
                    {errors[name]?.message as any}
                </p>
            )}
        </div>
    );
};

export default KpInput;
