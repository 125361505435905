import React from "react";
import { useState, useEffect } from "react";
// import logo from "../assets/images/Logo-Infinexia.webp";
import { Bars3Icon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../../globalState/hook";
import translator from "../../../Components/Extra/Translation/Translate";
import LanguageSwitcher from "../../Account/Language/Language";

const Header = () => {
    const location = useLocation();
    const { translate } = translator();
    const { lang } = useParams();
    const { isAuthenticated } = useAppSelector((state) => state.auth);
    const [toggleMenu, setToggleMenu] = useState(false);
    const isHome = true;
    // const isHome = ["/", "/home", "/accueil"].includes(location.pathname);
    const [scroll, setScroll] = useState(isHome);
    const [has_dropdown, setDropdwon] = useState(false);
    const [currentTarget, setCurrentTarget] = useState(
        typeof window != "undefined" ? window.location.hash : "",
    );

    //Change background header on scroll
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 40 || !isHome)
            setScroll(true); // => scroll position
        else setScroll(false);
    };

    const handleDropdown = () => {
        setDropdwon(!has_dropdown);
    };

    const currentUrl = typeof window != "undefined" ? window.location.href : "";
    const hasBusinessUrl = currentUrl.includes("/business");

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [location.pathname]);

    useEffect(() => {
        const targetElement = document.getElementById(
            currentTarget.replace("#", ""),
        );
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentTarget]);

    return (
        <div
            className={`${
                scroll ? "bg-white text-black" : "text-white  "
            }  transition-all ${
                isHome ? "fixed" : "sticky"
            } mdg-shadow top-0 w-full z-50 font-medium text-lg`}
        >
            <nav className=" sticky top-0 ">
                <div className="flex px-2 lg:!px-24  items-center h-[66px] md:h-full">
                    <div className="xl:w-72 w-96">
                        <a href={lang ? `/${lang}` : "/"}>
                            <img
                                className={` xl:w-72  w-96 ${
                                    scroll ? "invert" : "invert-0"
                                }`}
                                src={"/dist/image/Logo-Infinexia.webp"}
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="w-full px-2 lg:!px-10 justify-center">
                        <div className=" hidden xl:flex w-full justify-center">
                            <div className="flex justify-center w-full">
                                <ul className=" flex justify-center font-bold ">
                                    <li
                                        className=" xl:mx-4 my-auto cursor-pointer"
                                        key={"Individuals"}
                                    >
                                        <NavLink
                                            className=" py-5"
                                            to={lang ? `/${lang}` : "/"}
                                            onClick={() =>
                                                setCurrentTarget("individuals")
                                            }
                                        >
                                            {translate(
                                                "HOME_PAGE",
                                                "Individuals",
                                            )}
                                        </NavLink>
                                    </li>
                                    <li
                                        className=" xl:mx-4 my-auto cursor-pointer"
                                        key={"Business"}
                                    >
                                        <NavLink
                                            className="py-5"
                                            to={
                                                lang
                                                    ? `/${lang}/business`
                                                    : "/business"
                                            }
                                            onClick={() =>
                                                setCurrentTarget("business")
                                            }
                                        >
                                            {translate(
                                                "BUSINESS_PAGE",
                                                "Business",
                                            )}
                                        </NavLink>
                                    </li>
                                    <li
                                        className=" xl:mx-4 my-auto cursor-pointer"
                                        key={"Prices"}
                                    >
                                        <NavLink
                                            className="py-5"
                                            to={
                                                hasBusinessUrl
                                                    ? `${
                                                          lang ? `/${lang}` : ""
                                                      }/business/prices`
                                                    : `${
                                                          lang ? `/${lang}` : ""
                                                      }/prices`
                                            }
                                            onClick={() =>
                                                setCurrentTarget(
                                                    hasBusinessUrl
                                                        ? `${
                                                              lang
                                                                  ? `/${lang}`
                                                                  : ""
                                                          }/business/prices`
                                                        : `${
                                                              lang
                                                                  ? `/${lang}`
                                                                  : ""
                                                          }/prices`,
                                                )
                                            }
                                        >
                                            {translate(
                                                "Swal_Loyalty",
                                                "Prices",
                                            )}
                                        </NavLink>
                                    </li>
                                    {/* <li className=' lg:ml-3 cursor-pointer'>Tarifs</li> */}

                                    {/* DROPDWON */}

                                    <div className=" xl:mx-4 my-auto justify-center group ">
                                        <NavLink
                                            className="flex justify-between py-5"
                                            to="#"
                                            onClick={() =>
                                                setCurrentTarget("infinexia-18")
                                            }
                                        >
                                            <span>
                                                {translate(
                                                    "ABOUT_PAGE",
                                                    "About",
                                                )}
                                            </span>
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="h-6 w-6 pt-1"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                    />
                                                </svg>
                                            </span>
                                        </NavLink>

                                        <div className="invisible absolute  w-52 flex  flex-col bg-white rounded py-1 px-4 pt-5 text-gray-800 mdg-shadow group-hover:visible">
                                            <NavLink
                                                className="block border-b border-gray-100 py-1 font-semibold text-black px-2 hover:bg-black/5"
                                                to={
                                                    lang
                                                        ? `/${lang}/support`
                                                        : "/support"
                                                }
                                            >
                                                {translate(
                                                    "SUPPORT_PAGE",
                                                    "Customer_Service",
                                                )}
                                            </NavLink>
                                            <NavLink
                                                className="block border-b border-gray-100 py-1 font-semibold text-black px-2 hover:bg-black/5"
                                                to={
                                                    lang
                                                        ? `/${lang}/about`
                                                        : "/about"
                                                }
                                            >
                                                {translate(
                                                    "ABOUT_PAGE",
                                                    "The_Company",
                                                )}
                                            </NavLink>

                                            <NavLink
                                                className="block border-gray-100 py-1 font-semibold text-black px-2 hover:bg-black/5"
                                                to={
                                                    lang
                                                        ? `/${lang}/partners`
                                                        : "/partners"
                                                }
                                            >
                                                {translate(
                                                    "AMBASSADOR_PAGE",
                                                    "Ambassador",
                                                )}
                                            </NavLink>
                                            <NavLink
                                                className="block border-b border-gray-100 py-1 font-semibold text-black px-2 hover:bg-black/5"
                                                to="https://infinexia.tawk.help/"
                                            >
                                                {translate(
                                                    "HOME_PAGE",
                                                    "Help_Center",
                                                )}
                                            </NavLink>
                                        </div>
                                    </div>
                                </ul>
                                {/* Two button */}
                                {!isAuthenticated ? (
                                    <div className=" xl:ml-20 flex gap-3 py-5">
                                        <NavLink
                                            to={
                                                lang
                                                    ? `/${lang}/login`
                                                    : "/login"
                                            }
                                            className="btn btn-perfect text-white border !border-white px-4 rounded-lg ifx-bouton py-1"
                                        >
                                            {translate("HOME_PAGE", "Sign_In")}
                                        </NavLink>

                                        <NavLink
                                            to={
                                                lang
                                                    ? `/${lang}/register`
                                                    : "/register"
                                            }
                                            className="text-white border !border-white px-4 rounded-lg ifx-bouton-bw py-1 "
                                        >
                                            {translate(
                                                "HOME_PAGE",
                                                "Create_An_Account",
                                            )}
                                        </NavLink>
                                    </div>
                                ) : (
                                    <div className=" xl:ml-20 flex gap-3 py-5">
                                        <NavLink
                                            to="/dashboard"
                                            className="btn btn-perfect text-white border !border-white px-4 rounded-lg ifx-bouton py-1"
                                        >
                                            {translate(
                                                "HOME_PAGE",
                                                "My_Account",
                                            )}
                                        </NavLink>
                                    </div>
                                )}
                            </div>

                            <div className=" xl:ml-20 flex gap-3 py-5">
                                <div className="intro-x  w-8 h-6  mr-3 pt-1 sm:mr-6 language">
                                    <LanguageSwitcher doc />
                                </div>
                            </div>
                        </div>

                        <div className=" xl:hidden  text-black flex justify-end ">
                            <div
                                className={`${
                                    scroll ? " text-black" : "text-white"
                                } intro-x w-8 h-6 mt-[4px] md:!mt-[2px] mr-3 sm:mr-6 language `}
                            >
                                <LanguageSwitcher doc />
                            </div>
                            <button
                                onClick={() => setToggleMenu(!toggleMenu)}
                                aria-label="Toggle"
                            >
                                <Bars3Icon
                                    className={`h-8 w-8 ${
                                        scroll ? " text-black" : "text-white"
                                    } `}
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className={`text-black fixed z-40 w-full  bg-gray-100 overflow-hidden flex text-center items-center flex-col xl:hidden gap-12  origin-top duration-700 ${
                        !toggleMenu ? "h-0" : "h-full"
                    }`}
                >
                    <div className="py-8 w-full">
                        <ul className="flex flex-col gap-3 font-bold tracking-wider w-full">
                            <li className="" key={"Individuals2"}>
                                <NavLink
                                    onClick={() => setToggleMenu(!toggleMenu)}
                                    to={lang ? `/${lang}` : "/"}
                                >
                                    {translate("HOME_PAGE", "Individuals")}
                                </NavLink>
                            </li>
                            <li key={"Business2"}>
                                <NavLink
                                    onClick={() => setToggleMenu(!toggleMenu)}
                                    to={
                                        lang ? `/${lang}/business` : "/business"
                                    }
                                >
                                    {" "}
                                    {translate("BUSINESS_PAGE", "Business")}
                                </NavLink>
                            </li>
                            <li key={"Prices2"}>
                                <NavLink
                                    onClick={() => setToggleMenu(!toggleMenu)}
                                    to={
                                        hasBusinessUrl
                                            ? `${
                                                  lang ? `/${lang}` : ""
                                              }/business/prices`
                                            : `${lang ? `/${lang}` : ""}/prices`
                                    }
                                >
                                    {" "}
                                    {translate("Swal_Loyalty", "Prices")}
                                </NavLink>
                            </li>
                            <li className=" relative" key={"About"}>
                                <ul
                                    className={`text-black text-center   z-40 w-full py-3 mb-3 px-10  overflow-hidden flex flex-col xl:hidden gap-3  ${" block"}`}
                                >
                                    <li
                                        onClick={handleDropdown}
                                        className={` cursor-pointer p-1 w-full ${" bg-slate-200 "}`}
                                        key={"About2"}
                                    >
                                        {" "}
                                        {translate("ABOUT_PAGE", "About")}
                                    </li>
                                    <li key={"Customer_Service"}>
                                        <NavLink
                                            to="/support"
                                            onClick={() =>
                                                setToggleMenu(!toggleMenu)
                                            }
                                        >
                                            {translate(
                                                "SUPPORT_PAGE",
                                                "Customer_Service",
                                            )}
                                        </NavLink>
                                    </li>
                                    <li key={"The_Company"}>
                                        <NavLink
                                            to={`${
                                                lang ? `/${lang}` : ""
                                            }/about`}
                                            onClick={() =>
                                                setToggleMenu(!toggleMenu)
                                            }
                                        >
                                            {" "}
                                            {translate(
                                                "ABOUT_PAGE",
                                                "The_Company",
                                            )}
                                        </NavLink>
                                    </li>
                                    <li key={"Ambassador"}>
                                        <NavLink
                                            to={`${
                                                lang ? `/${lang}` : ""
                                            }/partners`}
                                            onClick={() =>
                                                setToggleMenu(!toggleMenu)
                                            }
                                        >
                                            {" "}
                                            {translate(
                                                "AMBASSADOR_PAGE",
                                                "Ambassador",
                                            )}
                                        </NavLink>
                                    </li>
                                    <li key={"Help_Center"}>
                                        <NavLink to="https://infinexia.tawk.help/">
                                            {translate(
                                                "HOME_PAGE",
                                                "Help_Center",
                                            )}
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            {!isAuthenticated ? (
                                <>
                                    <li key={"Sign_In"}>
                                        <NavLink
                                            to={`${
                                                lang ? `/${lang}` : ""
                                            }/login`}
                                            onClick={() =>
                                                setToggleMenu(!toggleMenu)
                                            }
                                            className="btn btn-perfect text-white border !border-white px-4 rounded-lg ifx-bouton py-1"
                                        >
                                            {translate("HOME_PAGE", "Sign_In")}
                                        </NavLink>
                                    </li>
                                    <li key={"Create_An_Account"}>
                                        <NavLink
                                            to={`${
                                                lang ? `/${lang}` : ""
                                            }/register`}
                                            onClick={() =>
                                                setToggleMenu(!toggleMenu)
                                            }
                                            className="btn text-white border !border-white px-4 rounded-lg ifx-bouton-bw  py-1"
                                        >
                                            {translate(
                                                "HOME_PAGE",
                                                "Create_An_Account",
                                            )}
                                        </NavLink>
                                    </li>
                                </>
                            ) : (
                                <li key={"My_Account"}>
                                    <NavLink
                                        to="/dashboard"
                                        className="btn btn-perfect  text-white border !border-white px-4 rounded-lg ifx-bouton py-1"
                                    >
                                        {translate("HOME_PAGE", "My_Account")}
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>

                {/* mobile navigation */}
            </nav>
        </div>
    );
};

export default Header;
