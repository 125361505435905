import React from "react";
import { useAddAccountsContext } from "../../../App/Provider/AddAccountsProvider";
import WidgetsDropdownSvg from "../../Extra/Svg/WidgetsDropdownSvg";
import translator from "../../Extra/Translation/Translate";
import {
    FlyOutComponent,
    useFlyoutContext,
} from "../../../App/Provider/FlyOutProvider";

const BtnFlyOutAddCardVirtual = () => {
    const { translate } = translator();
    const { setActiveComponent, setContent, setTitle } = useFlyoutContext();
    return (
        <button
            type="button"
            className=" btn-perfect py-2 px-4 text-sm rounded-lg w-56"
            onClick={() => {
                setActiveComponent(FlyOutComponent.CARD_VIRTUAL_FORM);
                setTitle(`${translate("WALLET", "ADD_CARD")}`);
            }}
        >
            <span>{translate("WALLET", "ADD_CARD")}</span>
        </button>
    );
};

export default BtnFlyOutAddCardVirtual;
