import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import Transaction, {
    COMMISSION_TRANSACTIONS,
    OUT_TRANSACTIONS,
    WalletHistoryStatus,
    WalletHistoryType,
} from "../../Models/Transaction";
import TransactionService from "../../Services/Wallets/TransactionService";
import UtilsService, { formatAmount } from "../../Services/UtilsService";
import { DatatableFilter } from "../../Models/DatatableFilter";
import DataTableWithPaginatedData, {
    DataTableColums,
} from "../Extra/DataTable/DataTableWithPaginatedData/DataTableWithPaginatedData";
import TransactionResponsiveTable, {
    DataResponsiveTableColums,
} from "../Extra/TableResponsive/TransactionResponsiveTable";
import ICSVTransaction from "../../Models/TransactionCSV";
import * as XLSX from "xlsx";
import translator from "../Extra/Translation/Translate";
import { useTransactionDetail } from "../../App/Provider/TransactionProvider";
import EyeSvg from "../Extra/Svg/EyeSvg";
import { useFlyoutContext } from "../../App/Provider/FlyOutProvider";
import { useParams } from "react-router-dom";
import SelectInput from "../Extra/Input/SelectInput";
import { useAssets } from "../../App/Provider/AssetsProvider";
import { UserAssetAction } from "../../Models/Crypto";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import { string } from "zod";
export const CsvCont = ({ res_csv }: any) => {
    const getSwapinAmount = (description: string) => {
        if (description.includes("vers")) {
            const amount = description.split("vers ")[1].split(" ")[0];
            return amount;
        }
        return "";
    };

    const map = (item: Transaction): ICSVTransaction => {
        const type = TransactionService.formatType(item.type);
        const date = UtilsService.getBeautifulFormatedDateWithTime(
            new Date(item.date),
        );

        const received_amount =
            type === "Dépôt"
                ? `${item.amount}`
                : item.type === "swapout"
                  ? getSwapinAmount(item.description)
                  : "";
        const sended_amount =
            type === "Retrait" || type === "Échange" ? `${item.amount}` : "";
        const transaction = new ICSVTransaction(
            type,
            date,
            received_amount,
            sended_amount,
            item.user_wallet.abbreviation,
            item.fee || 0,
            item.description,
            "",
        );

        transaction.type = TransactionService.formatType(item.type);
        transaction.date = item.date;
        transaction.wallet =
            type === "Retrait"
                ? ""
                : type === "Échange"
                  ? item.target?.abbreviation || ""
                  : item.user_wallet.abbreviation;
        transaction.crypto_out =
            item.type === "staking"
                ? item.user_wallet.abbreviation
                : type === "Échange"
                  ? item.user_wallet.abbreviation
                  : item.target?.abbreviation || "";
        transaction.fee = item.fee || 0;
        transaction.crypto_fee = "";
        transaction.platform = "Infinexia";
        transaction.description = item.description;
        transaction.label = "";
        return transaction;
    };

    const exportToXlsx = async () => {
        const filtered_res_csv: Transaction[] = res_csv.filter(
            (elt: Transaction) =>
                elt.status === WalletHistoryStatus.COMPLETED &&
                elt.type !== WalletHistoryType.SWAPIN &&
                elt.type !== WalletHistoryType.STOP_STAKING &&
                elt.type !== WalletHistoryType.PURCHASE_FIDELITY,
        );

        if (filtered_res_csv) {
            const assetsCSV: ICSVTransaction[] = filtered_res_csv.map((item) =>
                map(item),
            );

            const setColumnWidth = (worksheet: XLSX.WorkSheet) => {
                const worksheetCol = worksheet["!cols"] || [];
                worksheetCol[0] = { wpx: 50 * 1.3 };
                worksheetCol[1] = { wpx: 100 * 1.3 };
                worksheetCol[2] = { wpx: 100 * 1.3 };
                worksheetCol[3] = { wpx: 100 * 1.3 };
                worksheetCol[4] = { wpx: 100 * 1.3 };
                worksheetCol[5] = { wpx: 110 * 1.3 };
                worksheetCol[6] = { wpx: 50 * 1.3 };
                worksheetCol[7] = { wpx: 110 * 1.3 };
                worksheetCol[8] = { wpx: 70 * 1.3 };
                worksheetCol[9] = { wpx: 210 * 1.3 };
                worksheetCol[10] = { wpx: 70 * 1.3 };
                worksheet["!cols"] = worksheetCol;
            };

            const columns = [
                { label: "Type", key: "type" },
                { label: "Date", key: "date" },
                { label: "Montant reçu", key: "received_amount" },
                { label: "Monnaie ou jeton reçu", key: "wallet" },
                { label: "Montant envoyé", key: "sended_amount" },
                { label: "Monnaie ou jeton envoyé", key: "crypto_out" },
                { label: "Frais", key: "fee" },
                { label: "Monnaie ou jeton des frais", key: "crypto_fee" },
                { label: "Plateforme", key: "platform" },
                { label: "Description", key: "description" },
                { label: "Label", key: "label" },
            ];

            const sheetData = [
                columns.map((column) => column.label), // Titres de colonne
                ...assetsCSV.map((item) =>
                    columns.map(
                        (column) => item[column.key as keyof ICSVTransaction],
                    ),
                ), // Données
            ];

            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            const workbook = XLSX.utils.book_new();
            setColumnWidth(worksheet);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

            // Génère un nom de fichier unique en ajoutant la date et l'heure actuelles
            const date = new Date()
                .toISOString()
                .slice(0, 19)
                .replace(/[-:]/g, "");
            const fileName = `transactions_${date}.xlsx`;

            XLSX.writeFile(workbook, fileName, { cellStyles: true });
        }
    };

    const handleExportClick = (e: any) => {
        e.preventDefault();
        exportToXlsx();
    };

    return (
        <button
            type="button"
            className="btn bg-transparent !border-none  md:w-auto lg:w-auto xl:w-auto h-[42px] mt-[5px] !p-2"
            onClick={(e) => handleExportClick(e)}
        >
            <img
                src={UtilsService.getPulicImage("/dist/image/icon_image.webp")}
                className="h-[40px] w-[40px] object-fill"
                alt=""
            />
        </button>
    );
};

const TransactionTable = () => {
    const { setFlyoutOpen } = useFlyoutContext();
    const { setTransaction } = useTransactionDetail();
    const { user } = useAuthentication();
    const [assets, setAssets] = useState<Array<Transaction>>([]);
    const { assets: wallets } = useAssets();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [increment, setIncrementor] = useState<number>(0);
    const [direction, setDirection] = useState<"ASC" | "DESC">("DESC");
    const [filterIndex, setFilterIndex] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(10);
    const [nbPage, setNbPage] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOrderByDate, setIsOrderByDate] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const { translate } = translator();

    const { wallet } = useParams();

    const setDates = (date1?: string, date2?: string) => {
        if (date1 && date2) {
            setStartDate(new Date(date1));
            setEndDate(new Date(date2));
        } else {
            setStartDate(undefined);
            setEndDate(undefined);
        }
    };

    const colums: DataTableColums[] = [
        {
            index: "description",
            title: "TRANSACTION_CUSTOMER.Description",
            content: (row: Transaction) => {
                return row.getTransactionDetails(user!, translate);
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "date",
            title: "TRANSACTION_CUSTOMER.Date",
            order: true,
            sort: () => {
                setDirection(direction === "ASC" ? "DESC" : "ASC");
            },
            content: (row: Transaction) => {
                return row.date;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "status",
            title: "Transaction.Status",
            content: (row: Transaction) => {
                return (
                    <div>{translate("WalletHistoryStatus", row.status)}</div>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "amount",
            title: "Transaction.Amount",
            content: (row: Transaction) => {
                const type = row.type;
                const includedValues = OUT_TRANSACTIONS;
                const commissions_types = COMMISSION_TRANSACTIONS;

                const txt = `${
                    !includedValues.includes(type) ? "+ " : "- "
                } ${formatAmount(
                    row.amount,
                    row.user_wallet.type === "crypto"
                        ? 8
                        : commissions_types.includes(type)
                          ? 5
                          : 2,
                )}`;

                let class_name = !includedValues.includes(type)
                    ? "text-green-600"
                    : "text-red-500";

                if (row.status === WalletHistoryStatus.CANCELLED) {
                    class_name = "text-red-500";
                } else if (
                    row.status === WalletHistoryStatus.PENDING ||
                    row.status === WalletHistoryStatus.INITIALIZED ||
                    row.type === WalletHistoryType.STAKING
                ) {
                    class_name = "text-yellow-500";
                }
                return <div className={class_name}>{txt}</div>;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "Action",
            title: "",
            content: (row: Transaction) => {
                return (
                    <button
                        onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                        ) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            setFlyoutOpen(true);
                            setTransaction(row);
                        }}
                        className="btn btn-perfect"
                    >
                        <EyeSvg />
                    </button>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
        },
    ];

    const column: DataResponsiveTableColums[] = [
        {
            index: "date",
            title: "TRANSACTION_CUSTOMER.Date",
            content: (row: Transaction) => {
                return row.date;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "description",
            title: "TRANSACTION_CUSTOMER.Description",
            content: (row: Transaction) => {
                return row.getTransactionDetails(user!, translate);
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "amount",
            title: "Transaction.Amount",
            content: (row: Transaction) => {
                const type = row.type;
                const includedValues = OUT_TRANSACTIONS;

                const txt = `${
                    !includedValues.includes(type) ? "+ " : "- "
                } ${formatAmount(
                    row.amount,
                    row.user_wallet.type === "crypto"
                        ? 8
                        : COMMISSION_TRANSACTIONS.includes(type)
                          ? 5
                          : 2,
                )}`;
                let class_name = !includedValues.includes(type)
                    ? "text-green-600"
                    : "text-red-500";

                if (row.status === WalletHistoryStatus.CANCELLED) {
                    class_name = "text-red-500";
                } else if (
                    row.status === WalletHistoryStatus.PENDING ||
                    row.status === WalletHistoryStatus.INITIALIZED
                ) {
                    class_name = "text-yellow-500";
                }
                return <div className={class_name}>{txt}</div>;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "status",
            title: "Transaction.Status",
            content: (row: Transaction) => {
                return (
                    <div>{translate("WalletHistoryStatus", row.status)}</div>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "Action",
            title: "",
            content: (row: Transaction) => {
                return (
                    <button
                        onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                        ) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            setFlyoutOpen(true);
                            setTransaction(row);
                        }}
                        className="btn btn-perfect"
                    >
                        <EyeSvg />
                    </button>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
        },
    ];

    const [filterByWallet, setFilterByWallet] = useState<string>(
        wallet ?? "principal",
    );

    const principal = wallets.find(
        (asset) =>
            asset.abbreviation === (user?.base_currency?.currency || "USD"),
    );

    const getUserTransactionsPaginate = useCallback(async () => {
        setIsLoading(true);
        const filter: DatatableFilter = {
            page: currentPage,
            size: perPage,
            search: search,
            // wallet:
            //     filterByWallet === "principal"
            //         ? principal
            //             ? String(principal.id)
            //             : undefined
            //         : filterByWallet === "fiat"
            //           ? wallets
            //                 .filter((asset) => asset.type === "fiat")
            //                 .map((fiat) => fiat.id) // Filtrer par fiat (IDs uniquement)
            //                 .join(",")
            //           : filterByWallet === "staking"
            //             ? wallets
            //                   .filter((asset) => asset.type === "staking")
            //                   .map((staking) => staking.id) // Filtrer par épargnes (IDs uniquement)
            //                   .join(",")
            //             : filterByWallet === "crypto"
            //               ? wallets
            //                     .filter((asset) => asset.type === "crypto")
            //                     .map((crypto) => crypto.id) // Filtrer par cryptos (IDs uniquement)
            //                     .join(",")
            //               : filterByWallet === "card"
            //                 ? wallets
            //                       .filter((asset) => asset.type === "card")
            //                       .map((card) => card.id) // Filtrer par cartes bancaires (IDs uniquement)
            //                       .join(",")
            //                 : filterByWallet, // Sinon, utilise le wallet sélectionné
        };
        let filter_wallet: string | undefined;
        switch (filterByWallet) {
            case "principal":
                filter_wallet = principal ? String(principal.id) : undefined;
                break;
            case "card":
                filter_wallet = "CARD";
                break;
            case "fiat":
                filter_wallet = "FIAT";
                break;
            case "savings":
                filter_wallet = "STAKING";
                break;
            case "cash":
                filter_wallet = "CASH";
                break;
            case "cryptos":
                filter_wallet = "CRYPTO";
                break;
            default:
                filter_wallet = filterByWallet;
        }
        filter.wallet = filter_wallet;
        if (startDate) {
            filter.start_date = startDate;
        }
        if (endDate) {
            filter.end_date = endDate;
        }
        // Vérifie le contenu du filtre
        console.log("Filtre appliqué pour la requête : ", filter);
        try {
            setIsOrderByDate(!isOrderByDate);
            const res = await TransactionService.getUserTransactionsPaginate(
                filter,
                direction === "ASC" ? "1" : undefined,
            );
            console.log("Réponse de l'API : ", res);
            setAssets([...res.data]);
            setNbPage(res.lastPage);
        } catch (err: any) {
        } finally {
            setIsLoading(false);
        }
    }, [increment]);

    useEffect(() => {
        setIncrementor(increment + 1);
    }, [currentPage, perPage, search, startDate, endDate]);

    useEffect(() => {
        setCurrentPage(0);
        setIncrementor(increment + 1);
    }, [startDate, endDate, filterByWallet]);

    useEffect(() => {
        setCurrentPage(0);
        setIncrementor(increment + 1);
    }, [direction]);

    const handlePageChange = useCallback((page: number) => {
        setCurrentPage(page + 1);
    }, []);

    const handlePerRowsChange = useCallback((newPerPage: number) => {
        setPerPage(newPerPage);
    }, []);

    const onSearchInputChange = useCallback(async (txt: string) => {
        setSearch(txt);
    }, []);
    useEffect(() => {
        getUserTransactionsPaginate();
    }, [getUserTransactionsPaginate]);

    const ExtraFilter = () => {
        const banks = wallets.filter(
            (asset) =>
                asset.is_personal &&
                asset.personal_asset_detail &&
                asset.personal_asset_detail.action ===
                    UserAssetAction.PIGGY_BANK,
        );
        const jackpots = wallets.filter(
            (asset) =>
                asset.is_personal &&
                asset.personal_asset_detail &&
                asset.personal_asset_detail.action === UserAssetAction.JACKPOT,
        );

        const sub_account = wallets.filter(
            (asset) =>
                asset.is_personal &&
                asset.personal_asset_detail &&
                asset.personal_asset_detail.action ===
                    UserAssetAction.SUB_ACCOUNT,
        );

        const principal = wallets.find(
            (asset) =>
                asset.abbreviation === (user?.base_currency?.currency || "USD"),
        );

        const fiat = wallets.filter((asset) => asset.type === "fiat");

        const crypto = wallets.filter((asset) => asset.type === "cryptos");

        const cards = wallets.filter((asset) => asset.type === "card");

        const options = [
            {
                label: translate("Dashboard", "Portfolio_fiat"),
                value: principal ? principal.id : "principal",
            },
            {
                label: translate("Wallets", "Fiat_Accounts"),
                value: "fiat",
            },
            ...sub_account.map((bank) => ({
                label: translate("REF_ACCOUNT", "SUB_ACCOUNT", {
                    name: bank.name,
                }),
                value: bank.id,
            })),
            {
                label: translate("Wallets", "Savings_Accounts"),
                value: "savings",
            },
            {
                label: translate("Wallets", "Cryptos_Accounts"),
                value: "cryptos",
            },
            ...banks.map((bank) => ({
                label: translate("TRANSACTION_FILTER", "BANK", {
                    name: bank.name,
                }),
                value: bank.id,
            })),
            ...jackpots.map((bank) => ({
                label: translate("TRANSACTION_FILTER", "JACKPOT", {
                    name: bank.name,
                }),
                value: bank.id,
            })),
            ...cards.map((card) => ({
                label: translate("Wallet_Online", "Card", {
                    name: card.name,
                }),
                value: card.id,
            })),
            //{
            // label: translate("Cards", "Cards"),
            // value: "card",
            //},
        ];

        return (
            <div>
                <select
                    className="form-select box mt-3 !w-[200px] sm:mt-0 dark:!bg-black dark:!text-white/80 dark:!border-slate-600 rounded-lg"
                    onChange={(e) => setFilterByWallet(e.target.value)}
                    defaultValue={filterByWallet}
                >
                    {options.map((option) => (
                        <option value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
        );
    };

    return (
        <>
            <DataTableWithPaginatedData
                className="table-report mt-3 table co-hidden box dark:!border-2 dark:!border-slate-700"
                columns={colums}
                data={assets}
                handlePaginationChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchInputChange={onSearchInputChange}
                nbPage={nbPage}
                direction={direction}
                setDirection={setDirection}
                filterIndex={filterIndex}
                setFilterIndex={setFilterIndex}
                searchByDate={setDates}
                exportCSV={true}
                exportCSVPdf={true}
                extraFilter={<ExtraFilter />}
            />
            {isLoading && <div>Loading...</div>}
            <TransactionResponsiveTable
                className="simple-hidden"
                columns={column}
                onSearchInputChange={onSearchInputChange}
                data={assets}
                searchByDate={setDates}
                handlePerRowsChange={handlePerRowsChange}
                pageLength={nbPage}
                nbPage={nbPage}
                handlePaginationChange={handlePageChange}
                exportCSV={true}
            />
        </>
    );
};

export default TransactionTable;
