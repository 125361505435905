import React from "react";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";
import translator from "../../../Components/Extra/Translation/Translate";
import { useParams } from "react-router-dom";

const bg = "/dist/image/bg-top.webp";

const Support = () => {
    const { translate } = translator();
    const { lang } = useParams();
    return (
        <section className=" text-[#333] font-medium break-words ">
            <HelmetComponent
                title={translate("SEO", "Title_Support")}
                description={translate("SEO", "Desc_Support")}
                link="/support"
                keywords={translate("SEO", "Keys_Support")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>
            <div
                className=" h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat  flex justify-center items-center text-white"
                style={{ backgroundImage: `url(${bg})` }}
            >
                <h1 className="pt-10 md:!text-3xl text-2xl font-bold tracking-wide text-center">
                    {translate("SUPPORT_PAGE", "Infinexia_Customer_Service")}
                </h1>
            </div>
            <div className=" bg-white py-[100px]">
                <div>
                    <div className=" w-[80%] max-w-[1080px] m-auto  py-6 md:text-lg  sm:text-base     ">
                        <div className=" pb-10    ">
                            <h3 className=" md:!text-3xl text-2xl font-bold pb-[10px] ">
                                {translate("SUPPORT_PAGE", "Customer_Service")}
                            </h3>
                            <p className=" text-lg font-normal break-words text-left pb-[18px]  ">
                                {translate(
                                    "SUPPORT_PAGE",
                                    "Even_Though_Infinexia",
                                )}
                            </p>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate(
                                    "SUPPORT_PAGE",
                                    "Our_Team_Generally_Responds",
                                )}
                            </p>
                            <p className=" text-lg font-normal ">
                                {translate(
                                    "SUPPORT_PAGE",
                                    "For_A_Quicker_Response",
                                )}{" "}
                                <a
                                    href="/https://infinexia.tawk.help/"
                                    className="cursor-pointer underline text-[#0c71c3]"
                                >
                                    {translate(
                                        "SUPPORT_PAGE",
                                        "The_Help_Center",
                                    )}
                                </a>{" "}
                                {translate(
                                    "SUPPORT_PAGE",
                                    "Which_Answers_The_Most",
                                )}
                            </p>
                        </div>
                        <div className=" pb-10 ">
                            <h3 className=" md:!text-3xl text-2xl font-bold pb-[10px]">
                                {translate(
                                    "SUPPORT_PAGE",
                                    "Working_With_Infinexia",
                                )}
                            </h3>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate(
                                    "SUPPORT_PAGE",
                                    "Would_You_Like_To_Work",
                                )}{" "}
                                <a
                                    href={`${lang ? `/${lang}` : ""}/partners`}
                                    className="cursor-pointer underline text-[#0c71c3]"
                                >
                                    {translate("SUPPORT_PAGE", "The_Form")}
                                </a>{" "}
                                {translate(
                                    "SUPPORT_PAGE",
                                    "Dedicated_To_This_Purpose",
                                )}
                            </p>
                        </div>
                        <div className=" pb-10">
                            <h3 className=" md:!text-3xl text-2xl font-bold pb-[10px]">
                                {translate(
                                    "SUPPORT_PAGE",
                                    "Are_You_A_Press_Service",
                                )}{" "}
                            </h3>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate(
                                    "SUPPORT_PAGE",
                                    "You_Are_A_Journalist",
                                )}
                            </p>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate(
                                    "SUPPORT_PAGE",
                                    "You_Can_Contact_Us",
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Support;
