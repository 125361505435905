import React from "react";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";

const InitializePayment = () => {
    const { translate } = translator();
    return (
        <>
            <Banner title={translate("APP", "Application_marchand")}></Banner>
        </>
    );
};

export default InitializePayment;
