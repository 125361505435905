import {
    IJackpotPaymentPayload,
    OJackpotPayment,
} from "../../../Models/Crypto";
import { DepositResponse } from "../../../Models/Deposit";
import axios from "../../AxiosInstance";
import UtilsService from "../../UtilsService";

const jackpotPayment = async (
    payload: IJackpotPaymentPayload,
    translate: any,
): Promise<any> => {
    return axios
        .post(`/jackpot-payment`, payload)
        .then(() => {
            return Promise.resolve(translate("JACKPOT", "CheckYourAccount"));
        })
        .catch((err: any) => {
            return Promise.reject(err.response.data);
        });
};

const executePaymentPayeerCB = async (
    payload: IJackpotPaymentPayload,
    translate: any,
): Promise<any> => {
    return axios
        .post(`/jackpot-payment`, payload)
        .then((res) => {
            const data = res.data as DepositResponse;
            if (data.code === "ORDER_CREATED" || data.code === "success") {
                return Promise.resolve(data);
            } else {
                return Promise.reject(
                    UtilsService.getAxiosErrorMessage(data.message),
                );
            }
        })
        .catch((err: any) => {
            return Promise.reject(err.response.data);
        });
};

const getUserJackpotPayment = async (): Promise<OJackpotPayment[]> => {
    return axios
        .get(`/jackpot-payment/user-payment`)
        .then(({ data }) => {
            return data as OJackpotPayment[];
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const acceptPayment = async (id: number) => {
    return await axios
        .put(`/jackpot-payment/accept/${id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const declinePayment = async (id: number) => {
    return await axios
        .put(`/jackpot-payment/cancel/${id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const JackpotPaymentService = {
    jackpotPayment,
    getUserJackpotPayment,
    acceptPayment,
    declinePayment,
    executePaymentPayeerCB,
};

export default JackpotPaymentService;
