import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import translator from "../Translation/Translate";

export interface TabItem {
    path: string;
    name: string;
}

interface TabNavProps {
    items: TabItem[];
}

const TabNav = ({ items }: TabNavProps) => {
    const { translate } = translator();

    const [ulClick, setUlClick] = useState<boolean>(false);

    return (
        <ul className="flex flex-wrap text-sm font-medium md:w-max lg:w-max xl:w-max ul-bg-mode text-center text-gray-500 border-gray-200 rounded-t-lg">
            {items.map((item: TabItem, index: number) => {
                return (
                    <li className="" key={index}>
                        <NavLink
                            to={item.path}
                            aria-current="page"
                            className={(navData) =>
                                navData.isActive || (!ulClick && index === 0)
                                    ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                                    : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                            }
                            onClick={(e) => setUlClick(true)}
                        >
                            {translate(".", item.name)}
                        </NavLink>
                    </li>
                );
            })}
        </ul>
    );
};

export default TabNav;
