import React from "react";
import loadable from "@loadable/component";

import AnonymousRouteProvider from "./RouteProvider/AnonymousRouteProvider";
import DocBase from "../Doc/Layout/DocBase";
import LandingBase from "../Layouts/Accueil/Base/base";
import PricesBusiness from "../Layouts/Accueil/pages/pricesBusiness";
import About from "../Layouts/Accueil/pages/about";
import Business from "../Layouts/Accueil/pages/business";
import Landing from "../Layouts/Accueil/pages/home";
import Partners from "../Layouts/Accueil/pages/partners";
import Support from "../Layouts/Accueil/pages/support";
import InitializePayment from "../Doc/pages/InitializePayment";
import Introduction from "../Doc/pages/Introduction";
import Integration from "../Doc/pages/Integration";
import Transfer from "../Doc/pages/Transfer";

import InfinexiaPayment from "../Landing/Payment/InfinexiaPayment";
import Register from "../Components/Authentication/Register/Register";
import Login from "../Components/Authentication/Login/Login";
import Error from "../Layouts/Error/Error";
import Prices from "../Layouts/Accueil/pages/prices";
import TermAndConditions from "../Layouts/Accueil/pages/term-and-conditions";
import PrivacyPolicy from "../Layouts/Accueil/pages/privacyPolicy";
import Legal from "../Layouts/Accueil/pages/legal";
import Pots from "../Layouts/Pots/Pots";
import LanguageProvider from "../App/LanguageProvider";
import IndividualFees from "../Layouts/Accueil/pages/Individual-fees";
import BusinessFees from "../Layouts/Accueil/pages/Business-fees";
import JoinAccount from "../Layouts/Accueil/pages/join-account";
import ChildAccount from "../Layouts/Accueil/pages/child-account";
import Referrals from "../Layouts/Accueil/pages/referrals";

const ForgotPassword = loadable(
    () =>
        import(
            "../Components/Authentication/Password/ForgotPassword/ForgotPassword"
        ),
);
const NewPassword = loadable(
    () =>
        import("../Components/Authentication/Password/NewPassword/NewPassword"),
);

const PaymentSuccess = loadable(
    () => import("../Layouts/Payment/PaymentSuccess"),
);

const PaymentError = loadable(() => import("../Layouts/Payment/PaymentError"));

const TemplateEmail = loadable(
    () => import("../Components/Extra/Email/TemplateEmail"),
);

const Maintenance = loadable(() => import("../Layouts/Maintenace/Maintenance"));

const PublicRoutes = [
    {
        path: "/",
        element: <LanguageProvider />,
        children: [
            {
                path: "",
                element: <LandingBase />,
                children: [
                    {
                        path: "/home",
                        element: <Landing />,
                    },
                    {
                        path: "/accueil",
                        element: <Landing />,
                    },
                    {
                        path: "/",
                        element: <Landing />,
                    },
                    {
                        path: "/support",
                        element: <Support />,
                    },
                    {
                        path: "/about",
                        element: <About />,
                    },
                    {
                        path: "/prices",
                        element: <Prices />,
                    },
                    {
                        path: "/business/prices",
                        element: <PricesBusiness />,
                    },
                    {
                        path: "/partners",
                        element: <Partners />,
                    },
                    {
                        path: "/business",
                        element: <Business />,
                    },
                    {
                        path: "/legal",
                        element: <Legal />,
                    },
                    {
                        path: "/terms-and-conditions",
                        element: <TermAndConditions />,
                    },
                    {
                        path: "/privacy-policy",
                        element: <PrivacyPolicy />,
                    },
                    {
                        path: "/fees",
                        element: <IndividualFees />,
                    },
                    {
                        path: "business/fees",
                        element: <BusinessFees />,
                    },
                    {
                        path: "/referral",
                        element: <Referrals />,
                    },
                ],
            },
            {
                path: "documentation",
                element: <DocBase />,
                children: [
                    {
                        path: "pay-with-infinexia",
                        element: <InitializePayment />,
                    },
                    {
                        path: "introduction",
                        element: <Introduction />,
                    },
                    {
                        path: "payment",
                        element: <Integration />,
                    },
                    {
                        path: "transfer",
                        element: <Transfer />,
                    },
                ],
            },
            {
                path: "payment-error",
                element: <PaymentError />,
            },
            {
                path: "payment-checkout",
                element: <InfinexiaPayment />,
            },
            {
                path: "thank-you",
                element: <PaymentSuccess />,
            },
            {
                element: <AnonymousRouteProvider />,
                children: [
                    {
                        path: "login",
                        element: <Login />,
                    },
                    {
                        path: "register/:parrain?",
                        element: <Register />,
                    },
                    {
                        path: "forgot-password",
                        element: <ForgotPassword />,
                    },
                    {
                        path: "reset-password",
                        element: <NewPassword />,
                    },
                ],
            },
            {
                path: "email",
                element: <TemplateEmail />,
            },
            {
                path: "maintenance",
                element: <Maintenance />,
            },
            {
                path: "pots/:slug",
                element: <Pots />,
            },
            {
                path: ":lang",
                children: [
                    {
                        path: "pots/:slug",
                        element: <Pots />,
                    },
                    {
                        path: "maintenance",
                        element: <Maintenance />,
                    },
                    {
                        path: "documentation",
                        element: <DocBase />,
                        children: [
                            {
                                path: "pay-with-infinexia",
                                element: <InitializePayment />,
                            },
                            {
                                path: "introduction",
                                element: <Introduction />,
                            },
                            {
                                path: "payment",
                                element: <Integration />,
                            },
                            {
                                path: "transfer",
                                element: <Transfer />,
                            },
                        ],
                    },
                    {
                        path: "payment-error",
                        element: <PaymentError />,
                    },
                    {
                        path: "payment-checkout",
                        element: <InfinexiaPayment />,
                    },
                    {
                        path: "thank-you",
                        element: <PaymentSuccess />,
                    },
                    {
                        element: <AnonymousRouteProvider />,
                        children: [
                            {
                                path: "login",
                                element: <Login />,
                            },
                            {
                                path: "register/:parrain?",
                                element: <Register />,
                            },
                            {
                                path: "forgot-password",
                                element: <ForgotPassword />,
                            },
                            {
                                path: "reset-password",
                                element: <NewPassword />,
                            },
                        ],
                    },
                    {
                        path: "",
                        element: <LandingBase />,
                        children: [
                            {
                                path: "home",
                                element: <Landing />,
                            },
                            {
                                path: "accueil",
                                element: <Landing />,
                            },
                            {
                                path: "",
                                element: <Landing />,
                            },
                            {
                                path: "support",
                                element: <Support />,
                            },
                            {
                                path: "about",
                                element: <About />,
                            },
                            {
                                path: "prices",
                                element: <Prices />,
                            },
                            {
                                path: "business/prices",
                                element: <PricesBusiness />,
                            },
                            {
                                path: "partners",
                                element: <Partners />,
                            },
                            {
                                path: "business",
                                element: <Business />,
                            },
                            {
                                path: "legal",
                                element: <Legal />,
                            },
                            {
                                path: "terms-and-conditions",
                                element: <TermAndConditions />,
                            },
                            {
                                path: "privacy-policy",
                                element: <PrivacyPolicy />,
                            },
                            {
                                path: "fees",
                                element: <IndividualFees />,
                            },
                            {
                                path: "business/fees",
                                element: <BusinessFees />,
                            },
                            {
                                path: "referral",
                                element: <Referrals />,
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        path: "*",
        element: <Error />,
    },
];

export default PublicRoutes;
