import React, { useEffect, useState } from "react";
import translator from "../Extra/Translation/Translate";
import GouvernanceItems from "./GouvernanceItems";
import GovernanceService from "../../Services/GovernanceService/GovernanceService";
import Governance from "../../Models/Governance";
import GovernanceVoteService from "../../Services/GovernanceVoteService/GovernanceVoteService";
import GovernanceVote from "../../Models/GovernanceVote";
import useLanguage from "../../Services/Hooks/useLanguage";
import { LangType } from "../../Models/Lang";

const Gouvernance = () => {
    const { translate } = translator();
    const [governanceList, setGovernanceList] = useState<Governance[]>([]);
    // const [ableToVote, setAbleToVote] = useState<boolean>(false);
    const [userVote, setUserVote] = useState<GovernanceVote[]>([]);

    const { language } = useLanguage();
    const lang =
        language.toLocaleLowerCase() == "fr"
            ? LangType.FR
            : language.toLocaleLowerCase() == "en"
              ? LangType.EN
              : LangType.ES;

    const getAll = async () => {
        try {
            const res = await GovernanceService.getAllActive();
            setGovernanceList(res);
        } catch (error) {}
    };

    /*const getAbilityToVote = async () => {
        try {
            const res = await FidelityService.getLoyaltyData();
            if (res.current && res.current.klc_value > 0) setAbleToVote(true);
        } catch (err: any) {}
    };*/

    const getUserVote = async () => {
        try {
            const res = await GovernanceVoteService.userVote();
            setUserVote(res);
        } catch (err: any) {}
    };

    useEffect(() => {
        getAll();
        // getAbilityToVote();
        getUserVote();
    }, []);
    return (
        <div>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <div className="intro-y mt-5 items-center">
                                <h1 className="mr-auto text-2xl font-medium !capitalize">
                                    {translate("Governance", "Governance")}
                                </h1>
                            </div>

                            <div className="mt-5 grid grid-cols-12 gap-6">
                                {governanceList.map(
                                    (elt: Governance, index: number) => (
                                        <GouvernanceItems
                                            governance={elt}
                                            key={index}
                                            refresh={getAll}
                                            // ableToVote={ableToVote}
                                            userVote={userVote}
                                            lang={lang}
                                        />
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Gouvernance;
