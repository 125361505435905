import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuthentication from "../../Services/Authentication/useAuthentication";
import axiosApiInstance, { refreshMyToken } from "../../Services/AxiosInstance";
import Loader from "../../Layouts/Loader/Loader";

interface IProps {
    children?: JSX.Element;
}

const PrivateRouteProvider = ({ children }: IProps) => {
    const { isAuthenticated, user } = useAuthentication();
    const location = useLocation();
    const auth = axiosApiInstance.defaults.headers.common["Authorization"];

    const [fetching, setFetching] = useState<boolean>(!auth && isAuthenticated);

    useEffect(() => {
        if (isAuthenticated && !auth) {
            refreshMyToken()
                .catch((err) => {
                    setFetching(false);
                })
                .then(() => {
                    setFetching(false);
                });
        }
    }, []);

    const { pathname } = location;

    if (isAuthenticated) {
        if (pathname && !pathname.includes("/settings")) {
            if (
                user &&
                (!user.address ||
                    !user.country ||
                    !user.phone ||
                    !user.date_of_birth)
            ) {
                return <Navigate to="/settings?show_modal=yes" replace />;
            }
        }
        if (fetching) return <Loader />;

        return children ? children : <Outlet />;
    } else {
        return (
            <Navigate
                to={"/login"}
                state={{ from: location.pathname }}
                replace
            />
        );
    }
};

export default PrivateRouteProvider;
