import React from "react";
import Select from "react-tailwindcss-select";
import { Option as DOption } from "react-tailwindcss-select/dist/components/type";

import translator from "../../../Components/Extra/Translation/Translate";
import { useState } from "react";
import SaveBouton from "../../../Components/Extra/Bouton/SaveBouton";
import { IAmbassador } from "../../../Models/User";
import AmbassadorService from "../../../Services/Ambassador/AmbassadorService";
import alert_message from "../../../Services/Alert/AlertMessage";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";

const bg = "/dist/image/bg-top.webp";
const Ambassadeurs = "/dist/image/Ambassadeurs.webp";

const Partners = () => {
    const { translate } = translator();

    const [lastName, setLastName] = useState<string>("");
    const [firstName, setFristName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [instagramProfil, setInstagramProfil] = useState<string>("");
    const [youtubeProfil, setYoutubeProfil] = useState<string>("");
    const [tiktokProfil, setTiktokProfil] = useState<string>("");
    const [facebookProfil, setFacebookProfil] = useState<string>("");
    const [snapchatProfil, setSnapchatProfil] = useState<string>("");
    const [otherProfil, setOtherProfil] = useState<string>("");
    const [numberOfCommunity, setNumberOfCommunity] = useState<string>("");
    const [activityArea, setActivityArea] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [agree, setAgree] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    const statusList: Array<DOption> = [
        {
            label: translate("AMBASSADOR_PAGE", "Select_Your_Status"),
            value: "",
        },
        {
            label: translate("AMBASSADOR_PAGE", "Content_Creator"),
            value: translate("AMBASSADOR_PAGE", "Content_Creator"),
        },
        {
            label: translate("AMBASSADOR_PAGE", "Influencer"),
            value: translate("AMBASSADOR_PAGE", "Influencer"),
        },
        {
            label: translate("AMBASSADOR_PAGE", "Networker"),
            value: translate("AMBASSADOR_PAGE", "Networker"),
        },
        {
            label: translate("AMBASSADOR_PAGE", "Other"),
            value: translate("AMBASSADOR_PAGE", "Other"),
        },
    ];
    const [status, setStatus] = useState<DOption>(statusList[0]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (agree) {
            const payload: IAmbassador = {
                last_name: lastName,
                first_name: firstName,
                phone: phone,
                email: email,
                country: country,
                status: status.value,
                insta_profil: instagramProfil,
                youtube_profil: youtubeProfil,
                tiktok_profil: tiktokProfil,
                facebook_profil: facebookProfil,
                snapchat_profil: snapchatProfil,
                other_profil: otherProfil,
                number_of_community: Number(numberOfCommunity) || 0,
                activity_area: activityArea,
                description: description,
            };
            setProcessing(true);
            const transact = AmbassadorService.create(payload);
            alert_message
                .promise(translate("AMBASSADOR_PAGE", "In_Progress"), transact)
                .then(() => {
                    // navigate("/child-accounts");
                })
                .finally(() => {
                    setProcessing(false);
                });
        }
    };
    return (
        <>
            {/* First section */}
            <section className="text-lg text-[white] font-medium break-words">
                <HelmetComponent
                    title={translate("SEO", "Title_Ambassador")}
                    description={translate("SEO", "Desc_Ambassador")}
                    link="/partners"
                    keywords={translate("SEO", "Keys_Ambassador")}
                    imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                    largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                    addPostfixTitle={true}
                    noIndex={false}
                ></HelmetComponent>
                <div
                    className=" h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat  flex justify-center items-center text-white"
                    style={{ backgroundImage: `url(${bg})` }}
                >
                    <div className="md:!pt-[50px] pt-[40px] w-[90%] m-auto">
                        <h1 className="pt-5 md:!text-3xl text-2xl font-bold tracking-wide text-center">
                            {translate("AMBASSADOR_PAGE", "Ambassador")}
                        </h1>
                        <p className="md:!text-xl text-center tracking-wider text-lg pt-5">
                            {translate(
                                "AMBASSADOR_PAGE",
                                "Benefit_From_Additional",
                            )}
                        </p>
                    </div>
                </div>
            </section>

            {/* Second section */}
            <section className="bg-white">
                <div className=" -z-40 lg:grid w-[90%] max-w-7xl mx-auto my-[125px] ">
                    <div className=" lg:flex justify-between grid">
                        <div
                            className=" lg:w-[46%] my-auto"
                            data-aos="fade-right"
                            data-aos-offset="200"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="800"
                        >
                            <div className="text-lg my-8">
                                <h2 className=" md:!text-3xl text-2xl font-bold">
                                    {translate(
                                        "AMBASSADOR_PAGE",
                                        "Become_An_Ambassador",
                                    )}
                                </h2>
                                <p className=" my-3 font-normal">
                                    {translate(
                                        "AMBASSADOR_PAGE",
                                        "As_An_Ambassador_You_Have",
                                    )}
                                </p>
                                <p className="font-normal">
                                    {translate(
                                        "AMBASSADOR_PAGE",
                                        "Join_Us_Now_To_Become",
                                    )}
                                </p>
                            </div>
                        </div>
                        <div
                            className=" lg:w-[46%] "
                            data-aos="fade-left"
                            data-aos-offset=""
                            data-aos-easing=""
                            data-aos-duration="800"
                        >
                            <div className=" ">
                                <img
                                    className=" rounded-xl"
                                    src={Ambassadeurs}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Third section */}
            <section className="bg-[#F1F5F9]">
                <div className=" text-xl text-black -z-40 pb-36 w-[90%] max-w-7xl mx-auto  ">
                    <div className="  text-center  py-24">
                        <h3 className=" md:!text-3xl text-2xl font-bold ">
                            {translate(
                                "AMBASSADOR_PAGE",
                                "Ask_To_Join_Ambassador_Program",
                            )}
                        </h3>
                        <p className="text-lg font-normal">
                            {translate(
                                "AMBASSADOR_PAGE",
                                "Complete_The_Form_Below_Providing",
                            )}
                        </p>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="grid grid-cols-2 gap-3 text-black">
                            <div className="w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(String(e.target.value))
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    required
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Last_Name",
                                    )}
                                />
                            </div>
                            <div className=" w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) =>
                                        setFristName(String(e.target.value))
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    required
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "First_Name",
                                    )}
                                />
                            </div>
                            <div className=" w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={phone}
                                    onChange={(e) =>
                                        setPhone(String(e.target.value))
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    required
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Phone",
                                    )}
                                />
                            </div>
                            <div className=" w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={email}
                                    onChange={(e) =>
                                        setEmail(String(e.target.value))
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    required
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Email",
                                    )}
                                />
                            </div>
                            <div className=" w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={country}
                                    onChange={(e) =>
                                        setCountry(String(e.target.value))
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    required
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Country",
                                    )}
                                />
                            </div>
                            <div className=" w-full group   w-selects-front col-span-2 md:!col-span-1">
                                <Select
                                    options={statusList}
                                    primaryColor="text-[black]"
                                    value={status}
                                    onChange={(val: any) => setStatus(val)}
                                />
                            </div>
                            <div className=" w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={instagramProfil}
                                    onChange={(e) =>
                                        setInstagramProfil(
                                            String(e.target.value),
                                        )
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Instagram_profil",
                                    )}
                                />
                            </div>
                            <div className=" w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={youtubeProfil}
                                    onChange={(e) =>
                                        setYoutubeProfil(String(e.target.value))
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Youtube_profil",
                                    )}
                                />
                            </div>
                            <div className=" w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={tiktokProfil}
                                    onChange={(e) =>
                                        setTiktokProfil(String(e.target.value))
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Tiktok_profil",
                                    )}
                                />
                            </div>
                            <div className=" w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={facebookProfil}
                                    onChange={(e) =>
                                        setFacebookProfil(
                                            String(e.target.value),
                                        )
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Facebook_profil",
                                    )}
                                />
                            </div>
                            <div className="w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={snapchatProfil}
                                    onChange={(e) =>
                                        setSnapchatProfil(
                                            String(e.target.value),
                                        )
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Snapchat_profil",
                                    )}
                                />
                            </div>
                            <div className=" w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={otherProfil}
                                    onChange={(e) =>
                                        setOtherProfil(String(e.target.value))
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Other_profil",
                                    )}
                                />
                            </div>
                            <div className=" w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={numberOfCommunity}
                                    onChange={(e) =>
                                        setNumberOfCommunity(
                                            String(e.target.value),
                                        )
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    required
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Number_of_community",
                                    )}
                                />
                            </div>

                            <div className="w-full group col-span-2 md:!col-span-1">
                                <input
                                    type="text"
                                    value={activityArea}
                                    onChange={(e) =>
                                        setActivityArea(String(e.target.value))
                                    }
                                    min={0}
                                    className="peer block w-full appearance-none rounded-lg bg-white !text-[black] border-1 px-2.5 pb-2.5 pt-4 text-sm focus:outline-none focus:ring-0"
                                    required
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Activity_area",
                                    )}
                                />
                            </div>
                            <div className="w-full group col-span-2">
                                <textarea
                                    id="message"
                                    rows={4}
                                    className="border-1 peer block w-full rounded-lg appearance-none bg-white px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                    placeholder={translate(
                                        "AMBASSADOR_PAGE",
                                        "Your_description",
                                    )}
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                ></textarea>
                            </div>
                        </div>
                        <div className="intro-x mt-4 flex items-center text-xs text-slate-600 sm:text-sm">
                            <input
                                id="agreement-field"
                                type="checkbox"
                                className="form-check-input mr-2 border"
                                required
                                checked={agree}
                                onChange={(e) => {
                                    setAgree(e.target.checked);
                                }}
                            />
                            <label
                                className="cursor-pointer select-none"
                                htmlFor="agreement-field"
                            >
                                {translate("Register", "Agree_Infinexia")}
                                <a
                                    className="text-slate-500 mx-1"
                                    href="https://infinexia.com/privacy-policy/"
                                >
                                    {translate("SignIn", "Privacy_Policy")}
                                </a>{" "}
                            </label>
                            .
                        </div>
                        <div className="mt-5 text-right">
                            <button
                                type="submit"
                                disabled={processing}
                                className="btn btn-perfect text-center text-base px-4 rounded-lg ifx-bouton p-2 py-3 text-white font-medium my-6"
                            >
                                {translate("AMBASSADOR_PAGE", "Send")}
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default Partners;
