import React from "react";
import { NavLink } from "react-router-dom";
import UtilsService from "../../Services/UtilsService";
import LanguageSwitcher from "../Account/Language/Language";

const HeadPayment = () => {
    return (
        <div className="top-bar-checkout mdg-nav-bar mdg-theme-nav">
            <nav aria-label="breadcrumb" className="-intro-x mr-auto">
                <div className="p-4 sm:w-64">
                    <NavLink
                        to="/"
                        className="active mdg-logo mx-auto !mt-[-10px] flex h-10 w-full justify-center text-orange-500 hover:text-yellow-500"
                        aria-current="page"
                    >
                        <img
                            src={UtilsService.getPulicImage(
                                "/dist/image/Logo-Infinexia.webp",
                            )}
                            className="h-[50px] w-auto"
                            alt="Infinexia Logo"
                        />
                    </NavLink>
                </div>
            </nav>

            <div className="intro-x w-8 h-6 mr-3 sm:mr-6 language">
                <LanguageSwitcher />
            </div>
            <div className="flex items-center justify-end">
                <div className="intro-x dropdown mr-5 flex h-7 w-5 items-center">
                    {/* <ThemeSwitcher /> */}
                </div>
            </div>
        </div>
    );
};

export default HeadPayment;
