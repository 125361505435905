import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

export interface ParentRequest {
    id: number;
    partenar: {
        first_name: string;
        last_name: string;
    };
    child: {
        first_name: string;
        last_name: string;
    };
}

export enum ParentsStatus {
    PENDING = "pending",
    ACCEPTED = "accepted",
    DECLINED = "declined",
}

const getAllRequest = async (): Promise<Array<ParentRequest>> => {
    return axios
        .get(`/notification/tutor-request`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateStatus = async (
    status: ParentsStatus,
    id: number,
): Promise<string> => {
    return axios
        .post(`/notification/tutor-request`, { status, id })
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteUserParent = async (id: number): Promise<string> => {
    return axios
        .post(`/user/delete-user-parent`, { id })
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const ParentService = {
    getAllRequest,
    updateStatus,
    deleteUserParent,
};

export default ParentService;
