import React from "react";
import { Outlet } from "react-router-dom";
import TabNav, { TabItem } from "../../../Components/Extra/Tab/TabNav";
import translator from "../../../Components/Extra/Translation/Translate";

const navigation2: TabItem[] = [
    {
        path: "",
        name: "SUBSCRIPTIONS.LIST",
    },
];

const LoyaltyConfig = () => {
    const { translate } = translator();

    return (
        <>
            <div className="intro-y mt-5 mb-2 items-center">
                <h2 className="intro-y mt-5 font-medium text-lg bg-white px-3 p-2 rounded">
                    {translate("SUBSCRIPTIONS", "LIST")}
                </h2>
            </div>
            <div className="mt-5 pt-5">
                <TabNav items={navigation2} />
                <div className="intro-y col-span-12 box">
                    <div className="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 lg:flex-row relative p-3">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoyaltyConfig;
