import React from "react";

const ChevronsDownPricesSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-chevrons-down mx-auto sm:w-[30px] sm:h-[30px] md:w-[40px] md:h-[40px]"
        >
            <path d="m7 6 5 5 5-5" />
            <path d="m7 13 5 5 5-5" />
        </svg>
    );
};

export default ChevronsDownPricesSvg;
