import { LangType } from "./Lang";

interface Translation {
    title: string;
    description: string;
    lang: string;
}

export interface INotice {
    title: string;
    description: string;
    status: boolean;
    photo?: File;
    is_external_link?: boolean;
    title_en?: string;
    description_en?: string;
    title_es?: string;
    description_es?: string;
    lang: LangType;
    title_de?: string;
    description_de?: string;
    title_pt?: string;
    description_pt?: string;
    title_it?: string;
    description_it?: string;
    title_pl?: string;
    description_pl?: string;
    title_nl?: string;
    description_nl?: string;
    title_ro?: string;
    description_ro?: string;
    title_cs?: string;
    description_cs?: string;
    title_hu?: string;
    description_hu?: string;
    title_sv?: string;
    description_sv?: string;
    title_el?: string;
    description_el?: string;
    title_bg?: string;
    description_bg?: string;
    title_da?: string;
    description_da?: string;
    title_fi?: string;
    description_fi?: string;
    title_sk?: string;
    description_sk?: string;
    title_hr?: string;
    description_hr?: string;
    title_li?: string;
    description_li?: string;
    title_sl?: string;
    description_sl?: string;
    title_lv?: string;
    description_lv?: string;
    title_ee?: string;
    description_ee?: string;
}

export default class Notice {
    id?: number;
    rank!: number;
    title!: string;
    description!: string;
    status!: boolean;
    photo?: string;
    created_at?: Date;
    updated_at?: Date;
    is_external_link?: boolean;
    title_en?: string;
    description_en!: string;
    title_es!: string;
    description_es!: string;
    lang!: LangType;
    title_de?: string;
    description_de?: string;
    title_pt?: string;
    description_pt?: string;
    title_it?: string;
    description_it?: string;
    title_pl?: string;
    description_pl?: string;
    title_nl?: string;
    description_nl?: string;
    title_ro?: string;
    description_ro?: string;
    title_cs?: string;
    description_cs?: string;
    title_hu?: string;
    description_hu?: string;
    title_sv?: string;
    description_sv?: string;
    title_el?: string;
    description_el?: string;
    title_bg?: string;
    description_bg?: string;
    title_da?: string;
    description_da?: string;
    title_fi?: string;
    description_fi?: string;
    title_sk?: string;
    description_sk?: string;
    title_hr?: string;
    description_hr?: string;
    title_li?: string;
    description_li?: string;
    title_sl?: string;
    description_sl?: string;
    title_lv?: string;
    description_lv?: string;
    title_ee?: string;
    description_ee?: string;

    constructor(
        lang: LangType,
        title: string,
        description: string,
        status: boolean,
    ) {
        this.title = title;
        this.description = description;
        this.status = status;
        this.lang = lang;
    }
}
