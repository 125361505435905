import Notice, { INotice } from "../../Models/Notice";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const map = (item: any): Notice => {
    const notice = new Notice(
        item.lang,
        item.title,
        item.description,
        item.status,
    );

    notice.status = item.status;
    notice.rank = item.rank;
    notice.description = item.description;
    notice.description_en = item.description_en;
    notice.description_es = item.description_es;
    notice.title_en = item.title_en;
    notice.title_es = item.title_es;
    notice.status = item.status;
    notice.created_at = item.created_at;
    notice.updated_at = item.updated_at;
    notice.photo = UtilsService.getPhotoUrl(item.photo);
    notice.id = item.id;
    notice.is_external_link = item.is_external_link;

    notice.description_de = item.description_de;
    notice.description_pt = item.description_pt;
    notice.title_de = item.title_de;
    notice.title_pt = item.title_pt;
    return notice;
};

const createFormData = (payload: INotice): FormData => {
    const formData = new FormData();
    formData.append("title", payload.title);
    formData.append("description", payload.description);
    formData.append("lang", payload.lang);
    if (payload.status) {
        formData.append("status", "1");
    }
    if (payload.photo) {
        formData.append("photo", payload.photo);
    }
    if (payload.is_external_link) {
        formData.append("is_external_link", "1");
    }
    // if (payload.title_en) {
    //     formData.append("title_en", payload.title_en);
    // }
    // if (payload.title_es) {
    //     formData.append("title_es", payload.title_es);
    // }
    // if (payload.description_en) {
    //     formData.append("description_en", payload.description_en);
    // }
    // if (payload.description_es) {
    //     formData.append("description_es", payload.description_es);
    // }
    // if (payload.title_de) {
    //     formData.append("title_de", payload.title_de);
    // }
    // if (payload.description_de) {
    //     formData.append("description_de", payload.description_de);
    // }
    // if (payload.title_pt) {
    //     formData.append("title_pt", payload.title_pt);
    // }
    // if (payload.description_pt) {
    //     formData.append("description_pt", payload.description_pt);
    // }

    const languages = [
        "en",
        "es",
        "de",
        "pt",
        "it",
        "pl",
        "nl",
        "ro",
        "cs",
        "hu",
        "sv",
        "el",
        "bg",
        "da",
        "fi",
        "sk",
        "hr",
        "li",
        "sl",
        "lv",
        "ee",
    ];

    for (const lang of languages) {
        const title_label = `title_${lang}`;
        const title = (payload as any)[title_label];

        const desc_label = `description_${lang}`;
        const description = (payload as any)[desc_label];

        formData.append(title_label, title ?? payload.title);

        formData.append(desc_label, description ?? payload.description);
    }

    return formData;
};

const getAll = async (): Promise<Array<Notice>> => {
    return axios
        .get(`/notice`)
        .then(({ data }) => {
            const assets: Notice[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const findAll = async (): Promise<Array<Notice>> => {
    return axios
        .get(`/notice/all`)
        .then(({ data }) => {
            // const assets: Notice[] = data.map((item: any) => map(item));

            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllByUser = async (): Promise<Array<Notice>> => {
    return axios
        .get(`/user-information`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const MarkAllAsRead = async (): Promise<any> => {
    return axios
        .put(`/user-information/mark-all-as-read`, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const orderList = async (payload: string) => {
    try {
        const result = await axios.put(`/notice/order-list`, {
            payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const getById = async (id: number): Promise<Notice> => {
    return axios
        .get(`/notice/${id}`)
        .then(({ data }) => {
            const asset: Notice = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteById = async (support_id?: number) => {
    return axios
        .delete(`/notice/${support_id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const create = async (payload: INotice): Promise<Notice | string> => {
    const formData = createFormData(payload);

    return axios
        .post("/notice", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Information créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const update = async (notice_id: number, payload: INotice): Promise<string> => {
    const formData = createFormData(payload);

    return axios
        .put(`/notice/${notice_id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const addUserInformation = async (id: number): Promise<string> => {
    return axios
        .put(`/user-information/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const NoticeService = {
    getAll,
    deleteById,
    getById,
    create,
    update,
    orderList,
    getAllByUser,
    addUserInformation,
    MarkAllAsRead,
    findAll,
};

export default NoticeService;
