import React, { useEffect, useRef } from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import { useState } from "react";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";
import ChevronsDownPricesSvg from "../../../Components/Extra/Svg/ChevronsDownPricesSvg";
import { NavLink, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import "../../../App/swiper.css";
import IndividualPriceTable from "../../../Components/Fidelity/IndividualPriceTable";
import IndividualPriceCards from "../../../Components/Fidelity/IndividualPriceCards";

const bg = "/dist/image/bg-top.webp";

const Prices = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const [active, setActive] = useState<"blue" | "black" | "gold">("blue");
    const swiperRef = useRef<SwiperType | null>(null);
    const [SwiperComponent, setSwiperComponent] = useState<any>(null);
    const [SwiperSlideComponent, setSwiperSlideComponent] = useState<any>(null);

    useEffect(() => {
        // Fonction asynchrone pour importer Swiper dynamiquement
        const loadSwiper = async () => {
            const { Swiper, SwiperSlide } = await import("swiper/react");
            setSwiperComponent(() => Swiper);
            setSwiperSlideComponent(() => SwiperSlide);
        };

        loadSwiper();
    }, []);

    const handleBusinessClick = () => {
        window.location.href = "/business/prices"; // Redirige vers la page des prix pour les entreprises
    };

    const handleSlideChange = (swiper: SwiperType) => {
        const slideIndex = swiper.activeIndex;
        const states: Array<"blue" | "gold" | "black"> = [
            "blue",
            "gold",
            "black",
        ];
        setActive(states[slideIndex % states.length]);
    };

    const handleButtonClick = (color: "blue" | "gold" | "black") => {
        setActive(color);
        const slideIndex = ["blue", "gold", "black"].indexOf(color);
        if (swiperRef.current) {
            swiperRef.current.slideTo(slideIndex);
        }
    };

    return (
        <>
            <section className=" text-[#333] font-medium break-words ">
                <HelmetComponent
                    title={translate("SEO", "Title_Prices")}
                    description={translate("SEO", "Desc_Prices")}
                    link="/prices"
                    keywords={translate("SEO", "Keys_Prices")}
                    imageCard="/dist/image/Fav-Infinexia-512x512.png"
                    largeTwitterCard="/dist/image/Fav-Infinexia-512x512.png"
                    addPostfixTitle={true}
                    noIndex={false}
                ></HelmetComponent>
                <div
                    className="h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat flex flex-col justify-center items-center text-white"
                    style={{ backgroundImage: `url(${bg})` }}
                >
                    <h1 className="pt-[100px] md:!text-3xl text-2xl font-bold tracking-wide text-center">
                        {translate("PRICES_PAGE", "TITLE_2")}
                    </h1>
                    <div className="mt-10 text-black text-sm md:!text-lg px-2 pb-2 md:!pb-0 w-11/12 md:!w-1/4">
                        <div className="w-full md:w-full bg-[#F1F5F9] rounded-xl grid grid-cols-2 gap-1 p-1 items-center mx-auto">
                            <NavLink
                                to=""
                                className="px-[16px] py-[8px] ifx-bouton-banner rounded-lg text-white text-center"
                            >
                                {translate("CARD_TYPE", "PARTICULAR")}
                            </NavLink>
                            <NavLink
                                to="/business/prices"
                                className="px-[16px] py-[8px] text-black text-center"
                            >
                                {translate("CARD_TYPE", "BUSINESS")}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                {/* Mobile Only */}
                <div className="!bg-[#F1F5F9] pt-5 sticky sm-block text-sm top-[50px] ... px-2 z-10">
                    <div className="w-full bg-white rounded-xl grid grid-cols-3 gap-1 p-1">
                        <button
                            className={`px-[16px] py-[8px] ${
                                active === "blue"
                                    ? "ifx-bouton-banner rounded-lg text-white"
                                    : "text-black"
                            } `}
                            onClick={() => handleButtonClick("blue")}
                        >
                            Blue
                        </button>
                        <button
                            className={`px-[16px] py-[8px] ${
                                active === "gold"
                                    ? "ifx-bouton-banner rounded-lg text-white"
                                    : "text-black"
                            } `}
                            onClick={() => handleButtonClick("gold")}
                        >
                            Gold
                        </button>
                        <button
                            className={`px-[16px] py-[8px] ${
                                active === "black"
                                    ? "ifx-bouton-banner rounded-lg text-white"
                                    : "text-black"
                            } `}
                            onClick={() => handleButtonClick("black")}
                        >
                            Black
                        </button>
                    </div>
                </div>
                <div className="bg-[#F1F5F9] md:hidden mt-4 mb-5 z-1">
                    {SwiperComponent && SwiperSlideComponent && (
                        <SwiperComponent
                            spaceBetween={20}
                            slidesPerView={1}
                            onSlideChange={handleSlideChange}
                            onSwiper={(swiper: SwiperType) =>
                                (swiperRef.current = swiper)
                            }
                        >
                            <SwiperSlideComponent>
                                <div className="bg-[#F1F5F9] pt-2 px-1">
                                    <IndividualPriceCards
                                        active={active}
                                        handleButtonClick={handleButtonClick}
                                        handleSlideChange={handleSlideChange}
                                    />
                                    <div className="mt-[25px] md:!mt-[40px] mb-[5px] text-gray-400 animate-[bounce_4s_ease-in-out_infinite] ">
                                        <ChevronsDownPricesSvg />
                                    </div>
                                    <div className="bg-[#F1F5F9] w-full flex justify-center items-center px-1">
                                        <IndividualPriceTable
                                            active={active}
                                            handleButtonClick={
                                                handleButtonClick
                                            }
                                            handleSlideChange={
                                                handleSlideChange
                                            }
                                        />
                                    </div>
                                </div>
                            </SwiperSlideComponent>
                        </SwiperComponent>
                    )}
                </div>

                {/* Desktop Only */}
                <div className="hidden md:block bg-white pb-5 md:!pb-0">
                    <IndividualPriceCards
                        active={active}
                        handleButtonClick={handleButtonClick}
                        handleSlideChange={handleSlideChange}
                    />
                </div>
                <div className="hidden md:block mt-[20px] md:!mt-[30px] pb-[30px] text-gray-400 animate-[bounce_4s_ease-in-out_infinite] ">
                    <ChevronsDownPricesSvg />
                </div>
                <div className="hidden md:block bg-[#F1F5F9] pt-5">
                    <div className="text-xl text-black -z-40 pb-36 w-full  px-2 md:!px-0 md:!w-[90%] max-w-7xl mx-auto">
                        <div className="text-center pt-[50px] hidden md:!block">
                            <h3 className="md:!text-3xl text-2xl font-bold ">
                                {translate(
                                    "PRICES_PAGE",
                                    "Comparative_Accounts",
                                )}
                            </h3>
                        </div>
                        <div className="mt-[75px] px-0 lg:px-24 hidden md:!block">
                            <table className="w-full border-collapse ">
                                <thead>
                                    <tr className="text-xl text-black font-bold mb-1">
                                        <th className="w-[70%] py-2 px-2"></th>
                                        <th className="w-[10%] py-2 px-4">
                                            Blue
                                        </th>
                                        <th className="w-[10%] py-2 px-4">
                                            Gold
                                        </th>
                                        <th className="w-[10%] py-2 px-4">
                                            Black
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="hidden md:block w-full">
                            <IndividualPriceTable
                                active={active}
                                handleButtonClick={handleButtonClick}
                                handleSlideChange={handleSlideChange}
                                disableDarkMode={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Prices;
