import React from "react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import PaymentService, {
    PayementAccess,
    PayementInformation,
} from "../../Services/Payment/Payment";
import alert_message from "../../Services/Alert/AlertMessage";
import UtilsService from "../../Services/UtilsService";
import KpInput from "../../Components/Extra/Input/KpInput";
import HeadPayment from "../../Layouts/Landing/HeadPayment";
import Header from "../../Layouts/Accueil/Base/header";

interface PaymentAuthType {
    account: string;
    password: string;
    code?: string;
}
const InfinexiaPayment = () => {
    const { translate } = translator();
    const [paymentData, setPaymentData] = useState<PayementInformation>();
    const [processing, setProcessing] = useState<boolean>(true);
    const [token, setToken] = useState<PayementAccess>();

    const location = useLocation();

    // Use the URLSearchParams API to parse and access query parameters
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get("ref");

    const getPaymentData = async (ref: string) => {
        setProcessing(true);
        try {
            const data = await PaymentService.getInformation(ref);

            setPaymentData(data);
        } catch (err: any) {
            alert_message.error(err);
        }
        setProcessing(false);
    };
    const form = useForm<PaymentAuthType>({});

    const authentication = async (data: PaymentAuthType) => {
        setProcessing(true);
        try {
            const response = await PaymentService.authorisation({
                account: data.account,
                ref: ref || "",
                password: data.password,
            });

            setToken(response);
        } catch (err: any) {
            alert_message.error(translate("CHECKOUT_API_MESSAGE", err));
        }
        setProcessing(false);
    };

    const accept = async () => {
        setProcessing(true);
        try {
            const response = await PaymentService.processPayment(
                token?.access_token || "",
                ref || "",
            );

            alert_message.success(
                translate("CHECKOUT_API_MESSAGE", "PAYMENT_DONE_WITH_SUCCESS"),
            );
            setTimeout(function () {
                window.location = response.redirect;
            }, 3000);
        } catch (err: any) {
            alert_message.error(translate("CHECKOUT_API_MESSAGE", err));
            setProcessing(false);
        }
    };

    const cancel = async () => {
        setProcessing(true);
        try {
            const response = await PaymentService.cancelPayment(ref || "");

            alert_message.success(
                translate(
                    "CHECKOUT_API_MESSAGE",
                    "PAYMENT_CANCELED_WITH_SUCCESS",
                ),
            );
            setTimeout(function () {
                window.location = response.redirect;
            }, 3000);
        } catch (err: any) {
            alert_message.error(translate("CHECKOUT_API_MESSAGE", err));
            setProcessing(false);
        }
    };

    useEffect(() => {
        getPaymentData(ref || "");
    }, [ref]);

    return (
        <div>
            <HeadPayment />
            <div className="login h-screen !p-0">
                <div className=" mx-auto px-2 max-w-5xl sm:h-[calc(100dvh-0px)] mobile-overflow-y-scroll">
                    <div className="block grid-cols-12 gap-4 xl:grid">
                        <div className="col-span-12 z-[10] my-40 ">
                            <div className=" rounded-lg text-lg border-1 ">
                                <div
                                    className={`border-2 border-black rounded-lg ${
                                        processing || !paymentData ? "blur" : ""
                                    }`}
                                >
                                    <div className="bg-black p-5 ">
                                        <div className="flex justify-between ">
                                            <div className="flex">
                                                <div className="ml-3 text-white">
                                                    {translate(
                                                        "Checkout_API",
                                                        "Ordre_de_paiement",
                                                    )}
                                                </div>
                                            </div>
                                            <div className="text-green-500 uppercase text-bold sm:text-right">
                                                {translate(
                                                    "Checkout_API",
                                                    "Paiement_sécurisé",
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box">
                                        <div className="justify-center mx-auto max-w-[500px] text-black dark:text-white py-10 px-5">
                                            <div className="">
                                                <div className="">
                                                    <strong>
                                                        {" "}
                                                        {translate(
                                                            "Checkout_API",
                                                            "Paiement_vers",
                                                        )}{" "}
                                                        :{" "}
                                                    </strong>
                                                    <span>
                                                        {
                                                            paymentData?.payee_name
                                                        }{" "}
                                                        ({paymentData?.account})
                                                    </span>
                                                </div>
                                                <div className="">
                                                    <strong>
                                                        {" "}
                                                        {translate(
                                                            "Checkout_API",
                                                            "Montant",
                                                        )}
                                                    </strong>
                                                    <span>
                                                        {" "}
                                                        {
                                                            paymentData?.amount
                                                        }{" "}
                                                        {paymentData?.wallet}
                                                    </span>
                                                </div>
                                                <div className="">
                                                    <strong>
                                                        {" "}
                                                        {translate(
                                                            "Checkout_API",
                                                            "Reference",
                                                        )}
                                                    </strong>
                                                    <span>
                                                        {" "}
                                                        {paymentData?.reference}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="text-center mt-10">
                                                {translate(
                                                    "Checkout_API",
                                                    token
                                                        ? "VALIDATE_PAYMENT"
                                                        : "Connectez_vous_infinexia",
                                                )}
                                            </div>
                                            <div className="border-2 border-black rounded-lg px-5 py-10 text-center my-5">
                                                {!token && (
                                                    <FormProvider {...form}>
                                                        <form
                                                            onSubmit={form.handleSubmit(
                                                                authentication,
                                                            )}
                                                        >
                                                            <div className="mt-3 grid grid-cols-12 gap-4">
                                                                <div className="col-span-12">
                                                                    <KpInput
                                                                        name="account"
                                                                        placeholder={translate(
                                                                            "Checkout_API",
                                                                            "Email_or_account_number",
                                                                        )}
                                                                        type="text"
                                                                        label={translate(
                                                                            "Checkout_API",
                                                                            "Email_or_account_number",
                                                                        )}
                                                                    ></KpInput>
                                                                </div>
                                                                <div className="col-span-12">
                                                                    <KpInput
                                                                        name="password"
                                                                        placeholder={translate(
                                                                            "Checkout_API",
                                                                            "Password",
                                                                        )}
                                                                        type="password"
                                                                        label={translate(
                                                                            "Checkout_API",
                                                                            "Password",
                                                                        )}
                                                                    ></KpInput>
                                                                </div>
                                                                <div className="col-span-12">
                                                                    <div className="flex text-center justify-center gap-3 h-[70px]">
                                                                        <button
                                                                            type="submit"
                                                                            disabled={
                                                                                processing
                                                                            }
                                                                            className="btn btn-perfect mt-1 w-56 border-[3px] border-[#3695D2] sm:h-[50px] sm:text-base"
                                                                        >
                                                                            {translate(
                                                                                "Checkout_API",
                                                                                "Connexion",
                                                                            )}
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn bg-transparent mt-1 w-56 text-black border-2 hover:!border-[3px] border-[#3695D2] sm:h-[50px] sm:text-base"
                                                                            disabled={
                                                                                processing
                                                                            }
                                                                            onClick={() =>
                                                                                (window.location.href =
                                                                                    "https://infinexia.com/register")
                                                                            }
                                                                        >
                                                                            {translate(
                                                                                "Checkout_API",
                                                                                "Créer_compte",
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </FormProvider>
                                                )}

                                                {token && (
                                                    <>
                                                        <div className="text-center mt-3">
                                                            {translate(
                                                                "Checkout_API",
                                                                "You_balance",
                                                            )}{" "}
                                                            {UtilsService.formatValue(
                                                                token.wallet
                                                                    .solde,
                                                                token.wallet
                                                                    .type,
                                                            )}{" "}
                                                            {
                                                                paymentData?.wallet
                                                            }
                                                        </div>
                                                        <div className="flex text-center justify-center gap-2 mt-5">
                                                            <button
                                                                type="button"
                                                                onClick={accept}
                                                                disabled={
                                                                    processing
                                                                }
                                                                className="btn btn-perfect w-56"
                                                            >
                                                                {translate(
                                                                    "Checkout_API",
                                                                    "Finaliser_paiement",
                                                                )}
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-span-12 text-center">
                                                <button
                                                    type="button"
                                                    className="text-base text-center mt-5 hover:text-red-700"
                                                    disabled={processing}
                                                    onClick={cancel}
                                                >
                                                    {translate(
                                                        "Checkout_API",
                                                        "Cancel",
                                                        {
                                                            merchant_name:
                                                                paymentData?.merchant_name ||
                                                                "",
                                                        },
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfinexiaPayment;
