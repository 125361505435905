import React from "react";

const OneRightSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            icon-name="chevron-right"
            className="lucide lucide-chevron-right w-4 h-4"
            data-lucide="chevron-right"
        >
            <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
    );
};

export default OneRightSvg;
