import axios from "../AxiosInstance";
import { KycConfiguration, SwapConfiguration } from "../../Models/Swap";
import UtilsService from "../UtilsService";
import { TransferConfiguration } from "../../Models/Transfer";
import {
    CountryBlacklistConfiguration,
    GeneralConfiguration,
    ISwapFeeConfiguration,
} from "../../Models/GeneralSetting";

const getSwapSetting = async (): Promise<SwapConfiguration> => {
    return axios
        .get(`/configuration/swap`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const swapSetting = async (
    payload: SwapConfiguration,
): Promise<SwapConfiguration | string> => {
    return axios
        .post("/configuration/swap", payload)
        .then(() => {
            return Promise.resolve("Configuration de swap avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getKycSetting = async (): Promise<KycConfiguration> => {
    return axios
        .get(`/configuration/kyc`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const kycSetting = async (
    payload: KycConfiguration,
): Promise<KycConfiguration | string> => {
    return axios
        .post("/configuration/kyc", payload)
        .then(() => {
            return Promise.resolve("Configuration de kyc avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const BaseCurrencySetting = async (payload: any): Promise<any> => {
    return axios
        .post("/configuration/base-currency", payload)
        .then(() => {
            return Promise.resolve("Configuration de devise avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const getBaseCurrencySetting = async (): Promise<any> => {
    return axios
        .get("/configuration/get-base-currency")
        .then((data) => {
            return data.data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const getBaseCurrency = async (): Promise<{
    symbol: string;
    unit: number;
    currency: string;
}> => {
    return axios
        .get("/configuration/base-currency-setting")
        .then((data) => {
            return data.data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const transferSetting = async (
    payload: TransferConfiguration,
): Promise<TransferConfiguration | string> => {
    return axios
        .post("/configuration/transfer", payload)
        .then(() => {
            return Promise.resolve("Configuration de transfert avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getTransferSetting = async (): Promise<TransferConfiguration> => {
    return axios
        .get(`/configuration/transfer`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const generalSetting = async (
    payload: GeneralConfiguration,
): Promise<string> => {
    return axios
        .post("/configuration/general", payload)
        .then(() => {
            return Promise.resolve("Configuration générale avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getGeneralSetting = async (): Promise<GeneralConfiguration> => {
    return axios
        .get(`/configuration/general`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const countriesBlacklistSetting = async (
    payload: CountryBlacklistConfiguration,
): Promise<string> => {
    return axios
        .post("/configuration/countries-blacklist", payload)
        .then(() => {
            return Promise.resolve("Configuration générale avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getCountriesBlacklistSetting =
    async (): Promise<CountryBlacklistConfiguration> => {
        return axios
            .get(`/configuration/countries-blacklist`)
            .then(({ data }) => {
                return data;
            })
            .catch((err: any) => {
                return Promise.reject(UtilsService.getAxiosErrorMessage(err));
            });
    };

const updateFeeSwapSetting = async (
    payload: ISwapFeeConfiguration,
): Promise<string> => {
    return axios
        .post(`/configuration/update-swap-fee`, payload)
        .then(() => {
            return Promise.resolve(
                "Configuration des frais de swap avec succès",
            );
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getFeeSwapSetting = async (): Promise<ISwapFeeConfiguration> => {
    return axios
        .get(`/configuration/get-swap-fee`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const SettingService = {
    getSwapSetting,
    swapSetting,
    getKycSetting,
    kycSetting,
    transferSetting,
    getTransferSetting,
    BaseCurrencySetting,
    getBaseCurrencySetting,
    getBaseCurrency,
    generalSetting,
    getGeneralSetting,
    countriesBlacklistSetting,
    getCountriesBlacklistSetting,
    updateFeeSwapSetting,
    getFeeSwapSetting,
};

export default SettingService;
