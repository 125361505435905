export interface BaseCurrency {
    currency: string;
    symbol: string;
    unit: number;
}

export enum EurUsdGbp {
    EUR = "EUR",
    USD = "USD",
    GBP = "GBP",
}
