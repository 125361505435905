import { UserCryptoWalletWithDuration } from "../../Components/Earn/Earn";
import Crypto, {
    CropData,
    ICrypto,
    IUserAsset,
    IUserAssetUpdate,
    OCryptoPots,
    PotsPayload,
} from "../../Models/Crypto";
import { FiatPaymentConfig } from "../../Models/FiatPaymentConfig";
import axios from "../AxiosInstance";
import {
    AssetNetwork,
    DepositWithdrawNetwork,
    IDepositWithdrawNetwork,
} from "../DepositAdress/DepositAdress";
import UtilsService from "../UtilsService";
import fs from "fs";

const createFormData = (payload: ICrypto): FormData => {
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("abbreviation", payload.abbreviation);
    formData.append("roi", `` + payload.default_roi);
    if (payload.status) {
        formData.append("status", "1");
    }
    if (payload.standby_crypto) {
        formData.append("standby_crypto", "1");
    }
    if (payload.swap_status) {
        formData.append("swap_status", `` + payload.swap_status);
    }
    if (payload.withdraw_status) {
        formData.append("withdraw_status", `` + payload.withdraw_status);
    }
    if (payload.description) {
        formData.append("description", payload.description);
    }
    if (payload.logo) {
        formData.append("logo", payload.logo);
    }
    if (payload.current_price) {
        formData.append("current_price", `` + payload.current_price);
    }
    if (Number(payload.lower_stacking) > 0) {
        formData.append("lower_stacking", `` + payload.lower_stacking);
    }
    if (payload.withdrawal_fee) {
        formData.append("withdrawal_fee", `` + payload.withdrawal_fee);
    }
    if (payload.swap_fee) {
        formData.append("swap_fee", `` + payload.swap_fee);
    }
    if (payload.purchase_fees_margin) {
        formData.append(
            "purchase_fees_margin",
            `` + payload.purchase_fees_margin,
        );
    }
    if (payload.service_fee) {
        formData.append("service_fee", `` + payload.service_fee);
    }
    if (payload.minimum_deposit) {
        formData.append("minimum_deposit", `` + payload.minimum_deposit);
    }
    if (payload.maximum_deposit) {
        formData.append("maximum_deposit", `` + payload.maximum_deposit);
    }
    if (payload.minimum_withdraw) {
        formData.append("minimum_withdraw", `` + payload.minimum_withdraw);
    }
    if (payload.swap_fee_type) {
        formData.append("swap_fee_type", payload.swap_fee_type);
    }
    if (payload.withdrawal_fee_type) {
        formData.append("withdrawal_fee_type", payload.withdrawal_fee_type);
    }

    if (payload.asset_stacking) {
        formData.append("asset_stacking", payload.asset_stacking);
    }
    if (payload.category) {
        formData.append("category", JSON.stringify(payload.category));
    }
    if (payload.monthly_max_withdraw) {
        formData.append(
            "monthly_max_withdraw",
            `` + payload.monthly_max_withdraw,
        );
    }
    if (payload.currency) {
        formData.append("currency", `` + payload.currency);
    }

    return formData;
};

const createUserAssetFormData = (payload: IUserAsset): FormData => {
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("action", payload.action);
    formData.append("objective", String(payload.objective));
    formData.append("currency", payload.currency);
    if (payload.logo) {
        formData.append("logo", payload.logo);
    }
    if (payload.jackpot_description) {
        formData.append("jackpot_description", payload.jackpot_description);
    }
    if (payload.jackpot_slug) {
        formData.append("jackpot_slug", payload.jackpot_slug);
    }
    formData.append("show_amount", String(payload.show_amount));
    formData.append("show_objective", String(payload.show_objective));
    if (payload.crop_data) {
        formData.append("crop_data", JSON.stringify(payload.crop_data));
    }

    return formData;
};

const updateUserAssetFormData = (payload: IUserAssetUpdate): FormData => {
    const formData = new FormData();
    formData.append("name", payload.name);

    if (payload.logo) {
        formData.append("files", payload.logo);
    }
    if (payload.jackpot_description) {
        formData.append("jackpot_description", payload.jackpot_description);
    }
    if (payload.jackpot_slug) {
        formData.append("jackpot_slug", payload.jackpot_slug);
    }
    if (payload.objective) {
        formData.append("objective", payload.objective);
    }
    if (payload.crop_data) {
        formData.append("crop_data", JSON.stringify(payload.crop_data));
    }
    if (payload.cropped_logo) {
        formData.append("files", payload.cropped_logo);
    }
    formData.append("show_amount", String(payload.show_amount));
    formData.append("show_objective", String(payload.show_objective));
    return formData;
};

const default_payment_method_config: FiatPaymentConfig = {
    deposit: {
        visa: false,
        transfer: {
            status: false,
            accounts: [],
        },
        mobile: {
            status: false,
            country: [],
        },
        perfect_money: false,
        payeer: false,
        gtp: false,
    },
    withdraw: {
        mobile: {
            status: false,
            country: [],
        },
        perfect_money: false,
        payeer: false,
        gtp: false,
        transfer: {
            status: false,
            account_name: "",
        },
    },
};

const map = (item: any): Crypto => {
    const crypto = new Crypto(
        item.name,
        item.abbreviation,
        item.default_roi,
        item.rank,
        item.currency,
    );

    crypto.status = item.status;
    crypto.created_at = item.created_at;
    crypto.updated_at = item.updated_at;
    crypto.logo = UtilsService.getPhotoUrl(item.logo);
    crypto.current_price = item.current_price;
    crypto.previous_price = item.previous_price;
    crypto.logo = UtilsService.getPhotoUrl(item.logo);
    crypto.id = item.id;
    crypto.user_wallet = item.user_wallet;
    crypto.type = item.type;
    crypto.description = item.description;
    crypto.lower_stacking = item.lower_stacking;
    crypto.withdrawal_fee = item.withdrawal_fee;
    crypto.withdrawal_fee_type = item.withdrawal_fee_type;
    crypto.deposit_address = item.deposit_address;
    crypto.is_deposit_kalypay = item.is_deposit_kalypay;
    crypto.standby_crypto = item.standby_crypto;
    crypto.purchase = item.purchase;
    crypto.swap_status = item.swap_status;
    crypto.withdraw_status = item.withdraw_status;
    crypto.deposit_status = item.deposit_status;
    crypto.swap_fee_type = item.swap_fee_type;
    crypto.swap_fee = item.swap_fee;
    crypto.purchase_fees_margin = item.purchase_fees_margin;
    crypto.service_fee = item.service_fee;
    crypto.minimum_deposit = item.minimum_deposit;
    crypto.minimum_withdraw = item.minimum_withdraw;
    crypto.maximum_deposit = item.maximum_deposit;
    crypto.is_stable_coin = item.is_stable_coin;
    crypto.is_new = item.is_new;
    crypto.rank_earning = item.rank_earning;
    crypto.show_earning = item.show_earning;
    crypto.price_change_percentage_24 = item.price_change_percentage_24 ?? 0;
    crypto.monthly_max_withdraw = item.monthly_max_withdraw ?? 0;
    crypto.is_earning_whitebit = Boolean(item.is_earning_whitebit);
    crypto.is_personal = item.is_personal;
    crypto.personal_asset_detail = item.user_asset;

    crypto.payment_method_config = item.payment_method_config
        ? JSON.parse(item.payment_method_config)
        : item.type.toLowerCase() == "fiat"
          ? default_payment_method_config
          : undefined;

    crypto.user_asset = item.user_asset || undefined;
    return crypto;
};

const getAll = async (): Promise<Array<Crypto>> => {
    return axios
        .get(`/crypto`)
        .then(({ data }) => {
            const assets: Crypto[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllEarning = async (): Promise<Array<Crypto>> => {
    return axios
        .get(`/crypto/earning`)
        .then(({ data }) => {
            const assets: Crypto[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllWithDuration = async (): Promise<
    Array<UserCryptoWalletWithDuration>
> => {
    return axios
        .get(`/crypto/with-duration`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateStatus = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/update-crypto-status/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateWithDrawStatus = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/update-withdraw-status/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateIsStableCoin = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/update-stable-coin-status/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const updateStandbyStatus = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/update-crypto-standby/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const updateIsNew = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/update-is-new/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const updateDepositStatus = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/update-deposit-status/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const updatePurchaseStatus = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/update-crypto-purchase/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const updateShowEarning = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/update-show-earning/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const updateSwapStatus = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/update-crypto-swap-status/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllAsset = async (): Promise<Array<Crypto>> => {
    return axios
        .get(`/crypto/all-crypto`)
        .then(({ data }) => {
            const assets: Crypto[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllChildAsset = async (child_uid: string): Promise<Array<Crypto>> => {
    return axios
        .get(`/crypto/all-child-crypto/${child_uid}`)
        .then(({ data }) => {
            const assets: Crypto[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getById = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const create = async (payload: ICrypto): Promise<Crypto | string> => {
    const formData = createFormData(payload);

    return axios
        .post("/crypto", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Asset créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const updateIsEarningWhitebit = async (id: number): Promise<Crypto> => {
    return axios
        .get(`/crypto/update-is-earning-whitebit/${id}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const update = async (
    id: number,
    payload: ICrypto,
    isFiat?: boolean,
): Promise<string> => {
    const formData = createFormData(payload);

    return axios
        .put(`/crypto/${id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return isFiat
                ? Promise.resolve("Fiat mis à jour avec succès")
                : Promise.resolve("Asset mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteById = async (id: number, translate: any) => {
    return axios
        .delete(`/crypto/${id}`)
        .then((res) =>
            Promise.resolve(translate("RESPONSES_ASSET", `${res.data.code}`)),
        )
        .catch((err: any) => {
            return Promise.reject(
                translate(
                    "RESPONSES_ASSET",
                    UtilsService.getAxiosErrorMessage(err, true),
                ),
            );
        });
};

const getFiatWallets = async (): Promise<Array<Crypto>> => {
    return axios
        .get(`/crypto/get-fiat-wallet`)
        .then(({ data }) => {
            const assets: Crypto[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getActiveFiatWallets = async (): Promise<Array<Crypto>> => {
    return axios
        .get(`/crypto/get-active-fiat-wallet`)
        .then(({ data }) => {
            const assets: Crypto[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getCryptoWallets = async (): Promise<Array<Crypto>> => {
    return axios
        .get(`/crypto/get-crypto-wallet`)
        .then(({ data }) => {
            const assets: Crypto[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getByAbbreviation = async (abbr: string): Promise<Crypto> => {
    return axios
        .get(`/crypto/get-by-abbreviation/${abbr}`)
        .then(({ data }) => {
            const asset: Crypto = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllWithoutKNT = async (): Promise<Array<Crypto>> => {
    return axios
        .get(`/crypto/all-assets`)
        .then(({ data }) => {
            const assets: Crypto[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAssetWithrawNetwork = async (
    asset: string,
): Promise<Array<AssetNetwork>> => {
    return axios
        .get(`/crypto/withdraw-network?asset=${asset}`)
        .then(({ data }) => {
            return data as AssetNetwork[];
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const orderList = async (payload: string) => {
    try {
        const result = await axios.put(`/crypto/order-list`, {
            payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const orderEarnigList = async (payload: string) => {
    try {
        const result = await axios.put(`/crypto/order-rank-earning-list`, {
            payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const createOrUpdateAssetConfig = async (
    abbr: string,
    payload: IDepositWithdrawNetwork,
): Promise<string> => {
    return axios
        .post(`/crypto/asset-config/${abbr}`, payload)
        .then(() => {
            return Promise.resolve(
                `${abbr.toUpperCase()} a été configuré avec succès`,
            );
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getFiatPaymentConfig = async (
    abbr: string,
): Promise<FiatPaymentConfig> => {
    return axios
        .get(`/crypto/fiat-config/${abbr}`)
        .then(({ data }) => {
            return data as FiatPaymentConfig;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getWithrawDepostNetwork = async (
    asset: string,
): Promise<DepositWithdrawNetwork> => {
    return axios
        .get(`/deposit-wallet/deposit-withdraw-network/${asset}`)
        .then(({ data }) => {
            return data as DepositWithdrawNetwork;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const updateFiat = async (
    id: number,
    payload: ICrypto,
): Promise<Crypto | string> => {
    const formData = createFormData(payload);

    return axios
        .post(`/crypto/update-fiat/${id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Fiat mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const createFiat = async (payload: ICrypto): Promise<Crypto | string> => {
    const formData = createFormData(payload);

    return axios
        .post("/crypto/add-fiat", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Asset créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const createUserAsset = async (
    payload: IUserAsset,
    translate: (text: string, text2: string) => string,
): Promise<Crypto | string> => {
    const formData = createUserAssetFormData(payload);
    return axios
        .post("/crypto/add-user-asset", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
            const { data } = res;
            return Promise.resolve(translate("JACKPOT_PIGGY_BANK", data.code));
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateUserAsset = async (
    payload: IUserAssetUpdate,
    crypto_id: number,
    translate: any,
): Promise<Crypto | string> => {
    console.log(payload);
    const formData = updateUserAssetFormData(payload);
    return axios
        .put(`/crypto/update-user-asset/${crypto_id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) =>
            Promise.resolve(translate("RESPONSES_ASSET", `${res.data.code}`)),
        )
        .catch((err: any) => {
            return Promise.reject(
                translate(
                    "RESPONSES_ASSET",
                    UtilsService.getAxiosErrorMessage(err, true),
                ),
            );
        });
};

const getAllAssetPots = async (): Promise<Array<Crypto>> => {
    return axios
        .get(`/crypto/all-crypto-pots`)
        .then(({ data }) => {
            const assets: Crypto[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAssetPots = async (payload: PotsPayload): Promise<OCryptoPots> => {
    return axios
        .get(`/crypto/crypto-pots`, { params: payload })
        .then(({ data }) => {
            return data as OCryptoPots;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateUserAssetCropData = async (
    slug: string,
    crop_data: CropData,
    translate: any,
): Promise<Crypto | string> => {
    return axios
        .put(`/crypto/update-user-asset-crop-data/${slug}`, { ...crop_data })
        .then((res) =>
            Promise.resolve(translate("RESPONSES_ASSET", `${res.data.code}`)),
        )
        .catch((err: any) => {
            return Promise.reject(
                translate(
                    "RESPONSES_ASSET",
                    UtilsService.getAxiosErrorMessage(err, true),
                ),
            );
        });
};

const CryptoService = {
    getAll,
    create,
    update,
    deleteById,
    getById,
    getFiatWallets,
    getCryptoWallets,
    getByAbbreviation,
    getAllWithoutKNT,
    getAllAsset,
    getAssetWithrawNetwork,
    updateStatus,
    updateStandbyStatus,
    updateSwapStatus,
    orderList,
    updateWithDrawStatus,
    updateIsStableCoin,
    updateIsNew,
    getAllWithDuration,
    updateDepositStatus,
    updateShowEarning,
    getAllEarning,
    orderEarnigList,
    updatePurchaseStatus,
    createOrUpdateAssetConfig,
    getFiatPaymentConfig,
    getWithrawDepostNetwork,
    updateFiat,
    createFiat,
    updateIsEarningWhitebit,
    createUserAsset,
    updateUserAsset,
    getAllAssetPots,
    getAssetPots,
    getActiveFiatWallets,
    getAllChildAsset,
    updateUserAssetCropData,
};

export default CryptoService;
