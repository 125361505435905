import React from "react";
import { useRoutes } from "react-router-dom";

import AccountRoutes from "./AccountRoutes";
import AdminRoutes from "./AdminRoutes";
import PublicRoutes from "./PublicRoutes";

const AppRoutes = () => {
    const routes = useRoutes([
        ...PublicRoutes,
        ...AccountRoutes,
        ...AdminRoutes,
    ]);

    return <>{routes}</>;
};

export default AppRoutes;
