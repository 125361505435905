import GovernanceVote from "./GovernanceVote";
import { LangType } from "./Lang";

export interface IGovernance {
    title: string;
    subtitle: string;
    description: string;
    status: boolean;
    default_no: number;
    default_yes: number;
    start_date?: string;
    end_date?: string;
    photo?: File;
    title_en?: string;
    subtitle_en?: string;
    description_en?: string;
    title_es?: string;
    subtitle_es?: string;
    description_es?: string;
    lang: LangType;
}

export default class Governance {
    id!: number;
    default_no!: number;
    default_yes!: number;
    title!: string;
    subtitle!: string;
    description!: string;
    status!: boolean;
    photo?: string;
    rank!: number;
    start_date!: string;
    end_date!: string;
    created_at?: Date;
    updated_at?: Date;
    vote?: GovernanceVote[];
    title_en?: string;
    subtitle_en?: string;
    description_en!: string;
    title_es?: string;
    subtitle_es?: string;
    description_es!: string;
    lang!: LangType;
    constructor(
        title: string,
        subtitle: string,
        description: string,
        status: boolean,
        default_no: number,
        default_yes: number,
        lang: LangType,
    ) {
        this.title = title;
        this.subtitle = subtitle;
        this.description = description;
        this.status = status;
        this.default_no = default_no;
        this.default_yes = default_yes;
        this.lang = lang;
    }
}
