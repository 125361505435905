import React from "react";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";
import useTheme from "../../../Services/Hooks/useTheme";
import UtilsService from "../../../Services/UtilsService";

const ThemeSwitcher = () => {
    const { mode, setAppMode } = useTheme();

    const changeTheme = () => {
        const newTheme = mode === AppMode.LIGHT ? AppMode.DARK : AppMode.LIGHT;
        setAppMode(newTheme);
    };

    return (
        <button onClick={() => changeTheme()}>
            <img
                src={
                    AppMode.LIGHT === mode
                        ? UtilsService.getPulicImage(
                              "/dist/image/mode-sombre.webp",
                          )
                        : UtilsService.getPulicImage(
                              "/dist/image/brightness.webp",
                          )
                }
                alt="mode dark"
            />
        </button>
    );
};

export default ThemeSwitcher;
