import Transaction from "./Transaction";

export default class NotificationModel {
    id!: number;
    title!: string;
    description!: string;
    is_seen!: boolean;
    created_at!: Date;
    updated_at!: Date;
    wallet_history?: Transaction;
    co_owner?: {
        user: {
            first_name: string;
            last_name: string;
        };
        wallet: {
            name: string;
        };
        type: string;
    };

    constructor(title: string, description: string, is_seen: boolean) {
        this.title = title;
        this.description = description;
        this.is_seen = is_seen;
    }
}
