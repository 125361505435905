import React from "react";
import { ChangeEvent, useState } from "react";
import { CsvCont } from "../../../Transactions/TransactionsTable";
import DateRangePicker from "../../Input/DateRangePicker";
import translator from "../../Translation/Translate";
import DataTablePageLength from "../pagination/DataTablePageLength";
import { ModalProvider } from "../../Modal/ModalContext";
import BtnExportExelPdf from "../../../Transactions/Print/ModalExport";

const DataTableFilter = ({
    currentData,
    tableButton,
    perPage,
    setPerPage,
    handlePerRowsChange,
    onSearchInputChange,
    startDate,
    endDate,
    search,
    exportCSV,
}: {
    currentData: any[];
    tableButton?: React.ReactNode;
    perPage: number;
    setPerPage: (page: number) => void;
    handlePerRowsChange?: (newPerPage: number) => void;
    onSearchInputChange?: (findText: string) => void;
    startDate?: string;
    endDate?: string;
    search?: (startDate?: string, endDate?: string) => void;
    exportCSV?: boolean;
}) => {
    const [filter, setFilter] = useState<string>();
    const { translate } = translator();

    const [dates, setDates] = useState<{
        startDate: string | undefined;
        endDate: string | undefined;
    }>({ startDate: undefined, endDate: undefined });

    return (
        <div className="intro-y col-span-12 md-flex simple-hidden flex-wrap justify-between items-center sm:flex-nowrap justify-center-mobile">
            {tableButton}
            <div className="mx-auto text-slate-500 md:block simple-hidden">
                {search && (
                    <>
                        <div className="bock md:space-x-2 my-2">
                            <div className="flex space-x-1">
                                {/* <input
                                    type="date"
                                    className="form-control box md-w-30 my-1"
                                    value={dates.startDate}
                                    onChange={(e: any) => {
                                        setDates({
                                            ...dates,
                                            startDate: e.target.value,
                                        });
                                    }}
                                />
                                <input
                                    type="date"
                                    className="form-control box md-w-30 my-1"
                                    value={dates.endDate}
                                    onChange={(e: any) => {
                                        setDates({
                                            ...dates,
                                            endDate: e.target.value,
                                        });
                                    }}
                                /> */}
                                <DateRangePicker
                                    setDates={setDates}
                                    dates={dates}
                                ></DateRangePicker>
                                {/* <DateRangePicker
                                    format="dd-MM-yyyy"
                                    className="md-range my-1"
                                    onChange={(value: any) => {
                                        if (!value) {
                                            setDates({
                                                startDate: undefined,
                                                endDate: undefined,
                                            });
                                        } else {
                                            setDates({
                                                startDate: value[0],
                                                endDate: value[1],
                                            });
                                        }
                                    }}
                                /> */}
                                <button
                                    type="button"
                                    className="btn btn-perfect my-1 w-[20%] h-[42px]"
                                    onClick={() =>
                                        search &&
                                        search(
                                            dates.startDate || "",
                                            dates.endDate || "",
                                        )
                                    }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-search"
                                    >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line
                                            x1="21"
                                            x2="16.65"
                                            y1="21"
                                            y2="16.65"
                                        ></line>
                                    </svg>
                                </button>
                                <button
                                    className="btn btn-perfect md-w-full my-1 !hidden"
                                    type="button"
                                    onClick={() => {
                                        if (search) search();
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-eye"
                                    >
                                        <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                                        <circle cx="12" cy="12" r="3" />
                                    </svg>
                                    {/* {translate("DataTable", "All_view")} */}
                                </button>

                                {!!exportCSV && (
                                    <ModalProvider>
                                        <div className="w-[20%] h-[39px]">
                                            <BtnExportExelPdf dates={dates} />
                                        </div>
                                    </ModalProvider>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="w-full sm:mt-0 sm:ml-auto sm:w-auto md:ml-0 flex justify-between simple-hidden space-x-1">
                <div className="w-1/3">
                    <DataTablePageLength
                        perPage={perPage}
                        setPerPage={setPerPage}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </div>
                <div className="relative w-2/3 text-slate-500 input-search-mobile mr-2">
                    <input
                        type="text"
                        value={filter ? filter : ""}
                        onChange={(e) => {
                            setFilter(e.target.value);
                            if (onSearchInputChange)
                                onSearchInputChange(e.target.value);
                        }}
                        className="form-control box w-full"
                        placeholder={translate("DataTable", "Search")}
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="absolute lucide lucide-search right-2 top-2"
                    >
                        <circle cx="11" cy="11" r="8" />
                        <path d="m21 21-4.3-4.3" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default DataTableFilter;
