import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import { NavLink, useParams } from "react-router-dom";

const bg = "/dist/image/bg-top.webp";
const logo = "/dist/image/Logo-Infinexia.webp";

export const Footer = ({ in_pots }: { in_pots?: boolean }) => {
    const { translate } = translator();
    const { lang } = useParams();
    return (
        <footer
            {...(in_pots && {
                "data-aos": "fade",
                "data-aos-offset": "fade",
                "data-aos-easing": "fade",
                "data-aos-duration": "800",
            })}
        >
            <div
                className=" bg-cover bg-no-repeat text-2xl "
                style={{ backgroundImage: `url(${bg})` }}
            >
                <div className="max-w-7xl mx-auto text-white">
                    {/* Top area: Blocks */}
                    <div className="grid md:grid-cols-12 gr lg:gap-16 gap-y-16 py-10 w-[90%] mx-auto justify-between">
                        {/* 1st block */}
                        <div className="col-span-12 md:!col-span-3 ">
                            <div className="w-auto">
                                <a href={lang ? `/${lang}` : "/"}>
                                    <img
                                        className="md:!w-[225px] w-[300px] sm:!w-[225px] mx-auto"
                                        src={logo}
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>

                        {/* 2nd block */}
                        <div className="col-span-12 md:!col-span-3 text-center md:!text-left">
                            <h3 className=" font-bold mb-2 ">
                                {translate("HOME_PAGE", "Accounts")}
                            </h3>
                            <ul className="text-base">
                                <li className="mb-2">
                                    <NavLink
                                        to={
                                            lang
                                                ? `/${lang}/#individuals`
                                                : "/#individuals"
                                        }
                                        className="  transition duration-150 ease-in-out"
                                    >
                                        {translate(
                                            "HOME_PAGE",
                                            "Personals_Accounts",
                                        )}
                                    </NavLink>
                                </li>

                                <li className="mb-2">
                                    <NavLink
                                        to={
                                            lang
                                                ? `/${lang}/business`
                                                : "/business"
                                        }
                                        className="  transition duration-150 ease-in-out"
                                    >
                                        {translate(
                                            "BUSINESS_PAGE",
                                            "Business_Accounts",
                                        )}
                                    </NavLink>
                                </li>

                                <li className="mb-2">
                                    <NavLink
                                        to={
                                            lang ? `/${lang}/prices` : "/prices"
                                        }
                                        className=" "
                                    >
                                        {translate("Swal_Loyalty", "Prices")}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        {/* 3rd block */}
                        <div className="col-span-12 md:!col-span-3 text-center md:!text-left">
                            <h3 className=" font-bold mb-2">
                                {translate("HOME_PAGE", "Learn_More")}
                            </h3>
                            <ul className="text-base">
                                <li className="mb-2">
                                    <NavLink
                                        to={lang ? `/${lang}/about` : "/about"}
                                        className=" "
                                    >
                                        {translate("ABOUT_PAGE", "About_Us")}
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink
                                        to={
                                            lang
                                                ? `/${lang}/support`
                                                : "/support"
                                        }
                                        className=""
                                    >
                                        {translate(
                                            "SUPPORT_PAGE",
                                            "Customer_Service",
                                        )}
                                    </NavLink>
                                </li>

                                <li className="mb-2">
                                    <NavLink
                                        to="https://infinexia.tawk.help"
                                        className=" "
                                    >
                                        {translate("HOME_PAGE", "Help_Center")}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        {/* 4th block */}
                        <div className="col-span-12 md:!col-span-3 text-center md:!text-left">
                            <h3 className="te font-bold mb-2">
                                {" "}
                                {translate("HOME_PAGE", "Informations")}
                            </h3>
                            <ul className="text-base">
                                <li className="mb-2">
                                    <NavLink
                                        to={lang ? `/${lang}/legal` : "/legal"}
                                        className=" "
                                    >
                                        {translate("HOME_PAGE", "Legal_Notice")}
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink
                                        to={
                                            lang
                                                ? `/${lang}/terms-and-conditions`
                                                : "/terms-and-conditions"
                                        }
                                        className=" "
                                    >
                                        {translate(
                                            "HOME_PAGE",
                                            "Terms_And_Conditions",
                                        )}
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink
                                        to={
                                            lang
                                                ? `/${lang}/privacy-policy`
                                                : "/privacy-policy"
                                        }
                                        className=""
                                    >
                                        {translate("HOME_PAGE", "Privacy")}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        {/* 5th block */}
                    </div>

                    {/* Bottom area */}
                </div>
            </div>

            {/* Social as
                <div className="w-full  md:flex justify-between text-center">
                    <ul className="flex items-center justify-center order-1  md:!mb-0 mb-4 ">
                        <li>
                            <NavLink
                                to="#0"
                                className="flex justify-center items-center hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                aria-label="Facebook"
                            >
                                <img className=" w-8" src={fb} alt="" />
                            </NavLink>
                        </li>
                        <li className="ml-4">
                            <NavLink
                                to="#0"
                                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                aria-label="Twitter"
                            >
                                <img className=" w-8" src={twitter} alt="" />
                            </NavLink>
                        </li>
                        <li className="ml-4">
                            <NavLink
                                to="#0"
                                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                aria-label="Instagram"
                            >
                                <img className=" w-8" src={instagram} alt="" />
                            </NavLink>
                        </li>
                    </ul>
                     Social as */}

            {/* Copyrights note */}
            <div className="border-t p-3">
                {/* Social as */}
                <div className="w-full text-center">
                    {/* Copyrights note */}
                    <div className="text-base my-auto text-center">
                        {translate("HOME_PAGE", "Copyright")}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
