import React, { useEffect, useRef, useState } from "react";
import translator from "../../Extra/Translation/Translate";
import UtilsService from "../../../Services/UtilsService";
import { ModalContext, ModalProvider } from "../../Extra/Modal/ModalContext";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import img_virtual1 from "../../../images/Virtual-purple.png";
import img_virtual2 from "../../../images/Virtual-silver.png";
import img_physical from "../../../images/Physical.png";
import ItemCardAccount from "../../Wallets/AddAccounts/CardAccount";
import { CARD_VIRTUEL_TYPE, CARD_TYPE } from "../../../Models/UserCard";
import {
    FlyOutComponent,
    useFlyoutContext,
} from "../../../App/Provider/FlyOutProvider";
import { Transition } from "@headlessui/react";
import FormCardVirtual from "../CardVirtual/formCard";
import { NavLink } from "react-router-dom";
import FidelityModel from "../../../Models/Fidelity";

export default function CreateCardVirtualFlyOut({
    type = "MODAL",
    showTitle = true,
}: {
    type?: "CARD_VIRTUEL" | "CARD_VIRTUEL_U" | "PHYSICAL_CARD" | "MODAL";
    showTitle?: boolean;
}) {
    const { user } = useAuthentication();
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);
    const [typeCard, setTypeCard] = useState<CARD_VIRTUEL_TYPE>();
    const [cardType, setCardType] = useState<CARD_TYPE>();
    const [currentLoyalty, setCurrentLoyalty] = useState<
        FidelityModel | undefined
    >();
    const handleCardTypeChange = (newType: CARD_TYPE) => {
        setCardType(newType);
    };
    const [showForm, setShowForm] = useState<boolean>(false);
    const { setActiveComponent, activeComponent, setContent, setTitle, title } =
        useFlyoutContext();
    const panelContent = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const doc = document.getElementById("mobile-header");
        if (doc) {
            if (showForm) {
                doc.classList.add("scrolling4");
            } else {
                doc.classList.remove("scrolling4");
            }
        }
    }, [showForm]);
    const Menu = () => {
        return (
            <div className="relative">
                <>
                    <div className="col-span-12 sm:col-span-12 lg:col-span-3 2xl:col-span-3 flex justify-center items-center">
                        {/* <div className="flex justify-center items-center p-2 text-lg font-bold">
                            {translate("WALLET", "ADD_CARD")}
                        </div> */}
                    </div>
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 lg:col-span-12 ">
                            <div className="intro-y">
                                <div className="col-span-12 sm:col-span-12 lg:col-span-3 2xl:col-span-3 flex justify-center items-center">
                                    <div className="mt-2 flex justify-center items-center p-2 text-lg font-bold">
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "left",
                                            }}
                                        >
                                            <ItemCardAccount
                                                icon={
                                                    <img
                                                        src={UtilsService.getPulicImage(
                                                            img_physical,
                                                        )}
                                                        className="h-14 w-[100%] rounded"
                                                    />
                                                }
                                                title={translate(
                                                    "WALLET",
                                                    "PHYSICAL_CARD",
                                                )}
                                                description={translate(
                                                    "WALLET",
                                                    "PHYSICAL_CARD_TEXT",
                                                )}
                                                modalContent={""}
                                                onClick={() => {
                                                    setCardType(
                                                        CARD_TYPE.PHYSICAL_CARD,
                                                    );
                                                    setShowForm(true);
                                                    // setActiveComponent(FlyOutComponent.CARD_VIRTUAL_FORM);
                                                    setActiveComponent(
                                                        undefined,
                                                    );
                                                    setTitle(
                                                        `${translate(
                                                            "WALLET",
                                                            "PHYSICAL_CARD",
                                                        )}`,
                                                    );
                                                }}
                                            />
                                            <ItemCardAccount
                                                icon={
                                                    <img
                                                        src={UtilsService.getPulicImage(
                                                            img_virtual1,
                                                        )}
                                                        className="h-10 w-[100%] rounded"
                                                    />
                                                }
                                                title={translate(
                                                    "WALLET",
                                                    "VIRTUAL_CARD",
                                                )}
                                                description={translate(
                                                    "WALLET",
                                                    "VIRTUAL_CARD_TEXT",
                                                )}
                                                modalContent={""}
                                                onClick={() => {
                                                    setTypeCard(
                                                        CARD_VIRTUEL_TYPE.CARD_VIRTUEL,
                                                    );
                                                    setShowForm(true);
                                                    // setActiveComponent(FlyOutComponent.CARD_VIRTUAL_FORM);
                                                    setActiveComponent(
                                                        undefined,
                                                    );
                                                    setTitle(
                                                        `${translate(
                                                            "WALLET",
                                                            "VIRTUAL_CARD",
                                                        )}`,
                                                    );
                                                }}
                                            />
                                            <ItemCardAccount
                                                icon={
                                                    <img
                                                        src={UtilsService.getPulicImage(
                                                            img_virtual2,
                                                        )}
                                                        className="h-10 w-[100%] rounded"
                                                    />
                                                }
                                                title={translate(
                                                    "WALLET",
                                                    "VIRTUAL_CARD2",
                                                )}
                                                description={translate(
                                                    "WALLET",
                                                    "VIRTUAL_CARD_TEXT2",
                                                )}
                                                modalContent={""}
                                                onClick={() => {
                                                    setTypeCard(
                                                        CARD_VIRTUEL_TYPE.CARD_VIRTUEL_U,
                                                    );
                                                    setShowForm(true);
                                                    setActiveComponent(
                                                        undefined,
                                                    );
                                                    setTitle(
                                                        `${translate(
                                                            "WALLET",
                                                            "VIRTUAL_CARD2",
                                                        )}`,
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center bottom-24 fixed w-full md:hidden">
                                <NavLink to="/cards">
                                    <button
                                        type="button"
                                        className="btn-perfect py-2 px-4 text-sm rounded-lg w-56 mr-5"
                                    >
                                        {translate(
                                            "SUBSCRIPTION",
                                            currentLoyalty &&
                                                (currentLoyalty.name ===
                                                    "Blue" ||
                                                    currentLoyalty.name ===
                                                        "Gold")
                                                ? "Upgrade"
                                                : "Modif_subs",
                                        )}
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        );
    };
    return (
        <>
            <Transition
                show={activeComponent == FlyOutComponent.CARD_VIRTUAL_FORM}
                unmount={false}
                as="div"
                id="transaction-details"
                ref={panelContent}
                className="fixed inset-0 sm:left-auto z-50 shadow-xl overflow-hidden w-full md:!w-auto"
                enter="transition-transform duration-200 ease-in-out"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition-transform duration-200 ease-in-out"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
            >
                <div className="relative pb-[100px] top-0 z-[9999] bg-slate-50 dark:bg-black overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-l border-slate-200 dark:border-slate-700 w-full md:!w-[390px] h-[calc(100dvh-64px)]">
                    <div className="w-full fixed bg-white dark:bg-black z-10">
                        <div className="max-w-sm mx-auto lg:max-w-none mt-3">
                            <button
                                onClick={() => {
                                    setActiveComponent(undefined);
                                    // setShowForm(false);
                                }}
                                className="absolute top-[10px] right-0 group p-2 z-10 mr-4"
                            >
                                <svg
                                    className="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                                </svg>
                            </button>
                            <div className="text-lg  text-slate-800 dark:text-slate-100 font-normal text-center mb-1">
                                <div className="flex justify-center mb-2">
                                    <div className="text-center font-bold">
                                        {title}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className=" col-span-12 p-3 mt-10"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                        }}
                    >
                        <Menu />
                    </div>
                </div>
            </Transition>

            <Transition
                show={showForm}
                unmount={false}
                as="div"
                id="transaction-details"
                ref={panelContent}
                className="fixed inset-0 sm:left-auto z-50 shadow-xl overflow-hidden w-full md:!w-auto"
                enter="transition-transform duration-200 ease-in-out"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition-transform duration-200 ease-in-out"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
            >
                <div className="relative pb-[100px] top-0 z-[9999] bg-slate-50 dark:bg-black overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-l border-slate-200 dark:border-slate-700 w-full md:!w-[390px] h-[calc(100dvh-64px)]">
                    <div className="w-full fixed bg-white dark:bg-black z-10">
                        <div className="max-w-sm mx-auto lg:max-w-none mt-3">
                            <button
                                onClick={() => {
                                    setActiveComponent(
                                        FlyOutComponent.CARD_VIRTUAL_FORM,
                                    );
                                    setShowForm(false);
                                    setTitle(
                                        `${translate("WALLET", "ADD_CARD")}`,
                                    );
                                }}
                                className="absolute top-[10px] right-0 group p-2 z-10 mr-4"
                            >
                                <svg
                                    className="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                                </svg>
                            </button>
                            <div className="text-lg  text-slate-800 dark:text-slate-100 font-normal text-center mb-1">
                                <div className="flex justify-center mb-2">
                                    <div className="text-center font-bold">
                                        {title}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className=" col-span-12 p-5 mt-10"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                        }}
                    >
                        {typeCard && (
                            <FormCardVirtual cardType={typeCard} hideTitle />
                        )}
                    </div>
                </div>
            </Transition>
        </>
    );
}
