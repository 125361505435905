import { useEffect, useMemo, useState } from "react";
import SettingService from "../../../Services/Setting/SettingService";
import FidelityModel from "../../../Models/Fidelity";
import FidelityService from "../../../Services/FidelityService/FidelityService";
import { CARD_VIRTUEL_TYPE } from "../../../Models/UserCard";
import { SubscribeCardVirtualContent } from "./SubscribeView";
import { useCardContext } from "../../../App/Provider/CardProvider";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";

const FormCardVirtual = ({
    cardType,
    hideTitle = false,
}: {
    cardType: CARD_VIRTUEL_TYPE;
    hideTitle?: boolean;
}) => {
    const {
        selectedCard,

        userCards2,
    } = useCardContext();

    const { base: baseCurrency } = useBaseCurrency();
    const [currentLoyalty, setCurrentLoyalty] = useState<any | undefined>(
        undefined,
    );

    const getActiveFidelity = async () => {
        try {
            const res = await FidelityService.getActiveFidelity();
            if (res) setCurrentLoyalty(res?.fidelity);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getActiveFidelity();
    }, []);

    const selected_card = useMemo(() => {
        return userCards2[0]?.fidelity;
    }, [selectedCard, userCards2]);

    useEffect(() => {
        setCurrentLoyalty(selected_card);
    }, [selected_card]);

    return (
        baseCurrency &&
        currentLoyalty && (
            <SubscribeCardVirtualContent
                props={currentLoyalty}
                current_fidelity={currentLoyalty}
                base_currency_system={baseCurrency}
                cardType={cardType}
                hideTitle={hideTitle}
            />
        )
    );
};

export default FormCardVirtual;
