import React, { useState } from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";

const bg = "/dist/image/bg-top.webp";

const PrivacyPolicy = () => {
    const { translate } = translator();

    return (
        <section className=" text-base text-[#333] font-medium break-words  ">
            <HelmetComponent
                title={translate("SEO", "Title_Privacy")}
                description={translate("SEO", "Desc_Privacy")}
                link="/privacy-policy"
                keywords={translate("SEO", "Keys_Privacy")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={true}
            ></HelmetComponent>
            <div
                className=" h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat  flex justify-center items-center text-white"
                style={{ backgroundImage: `url(${bg})` }}
            >
                <h1 className="pt-10 md:!text-3xl text-2xl font-bold tracking-wide text-center">
                    {translate("PRIVACY_PAGE", "MAIN_TITLE")}
                </h1>
            </div>
            <div className=" bg-white py-[100px] ">
                <div>
                    <div className=" w-[80%] max-w-[1080px] m-auto sm:text-base py-6 text-base   ">
                        <div className=" pb-10 mb-[36px]    ">
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("PRIVACY_PAGE", "PRIVACY_TITLE_1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_1-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_1-2")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_1-3")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("PRIVACY_PAGE", "PRIVACY_TITLE_2")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_2-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_2-2")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_2-3")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_2-4")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("PRIVACY_PAGE", "PRIVACY_TITLE_3")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_3-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_3-2")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_3-3")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_3-4")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_3-5")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_3-6")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("PRIVACY_PAGE", "PRIVACY_TITLE_4")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_4-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_4-2")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_4-3")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_4-4")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_4-5")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("PRIVACY_PAGE", "PRIVACY_TITLE_5")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_5-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_5-2")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("PRIVACY_PAGE", "PRIVACY_TITLE_6")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_6-1")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_6-2")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("PRIVACY_PAGE", "PRIVACY_TITLE_7")}
                            </p>
                            <p className="text-base text-left font-normal pb-[15px]">
                                <strong>
                                    {translate("PRIVACY_PAGE", "SUBTITLE_7-1")}
                                </strong>
                                {translate("PRIVACY_PAGE", "PARAGRAPH_7-1")}
                            </p>
                            <p className="text-base text-left font-normal  pb-[15px]">
                                <strong>
                                    {translate("PRIVACY_PAGE", "SUBTITLE_7-4")}
                                </strong>
                                {translate("PRIVACY_PAGE", "PARAGRAPH_7-5")}
                            </p>
                            <p className="text-base text-left font-normal pb-[15px]">
                                <strong>
                                    {translate("PRIVACY_PAGE", "SUBTITLE_7-2")}
                                </strong>
                                {translate("PRIVACY_PAGE", "PARAGRAPH_7-2")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("PRIVACY_PAGE", "PRIVACY_TITLE_8")}
                            </p>
                            <p className=" text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_8-1")}
                                <span
                                    className=" cursor-pointer underline text-[#0c71c3]"
                                    onClick={() => {
                                        localStorage.setItem(
                                            "cookie_consent_hidden",
                                            "false",
                                        );

                                        location.reload();
                                    }}
                                >
                                    {translate("PRIVACY_PAGE", "PARAGRAPH_8-2")}
                                </span>
                                {translate("PRIVACY_PAGE", "PARAGRAPH_8-3")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_8-4")}
                            </p>
                            <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                                {translate("PRIVACY_PAGE", "PRIVACY_TITLE_9")}
                            </p>
                            <p className="text-base text-left font-normal pb-[10px]">
                                {translate("PRIVACY_PAGE", "PARAGRAPH_9-1")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;
