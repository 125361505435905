import React from "react";
import "react-phone-input-2/lib/bootstrap.css";

import translator from "../../Extra/Translation/Translate";
import { ModalContext } from "../../Extra/Modal/ModalContext";
import CreateCardVirtual from "./createCardVirtual";

const BtnAddCardVirtualModal = () => {
    const { handleModal } = React.useContext(ModalContext);
    const { translate } = translator();
    return (
        <button
            type="button"
            className="btn btn-perfect md-w-13 !sm-w-auto rounded-lg"
            onClick={() => handleModal(<CreateCardVirtual type="MODAL" />)}
        >
            <span>{translate("WALLET", "ADD_CARD")}</span>
        </button>
    );
};

export default BtnAddCardVirtualModal;
