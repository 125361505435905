import { useMemo } from "react";
import { UserCryptoWallet } from "../Models/UserWallet";
import UtilsService from "../Services/UtilsService";
import { Currency } from "./currency-swicher";

export const roundTo = (value: number, decimals: number) => {
    return Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

// Fonction pour déterminer le nombre de décimales selon la valeur
export const getDecimalPlaces = (value: number) => {
    if (value >= 100) return 2;
    if (value >= 10) return 3;
    if (value >= 1) return 4;
    if (value >= 0.1) return 6;
    return 8;
};

// Fonction pour gérer les décimales des devises fiat
const getFiatDecimalPlaces = (currencyCode: string): number => {
    const twoDecimalCurrencies = [
        "EUR",
        "USD",
        "GBP",
        "CHF",
        "AUD",
        "NZD",
        "CAD",
        "AED",
    ];
    const fourDecimalCurrencies = [
        "XOF",
        "NGN",
        "ZAR",
        "TRY",
        "JPY",
        "CNY",
        "BRL",
    ];

    if (twoDecimalCurrencies.includes(currencyCode)) {
        return 2; // Deux décimales
    }
    if (fourDecimalCurrencies.includes(currencyCode)) {
        return 4; // Quatre décimales
    }
    return 2; // Par défaut à deux décimales
};

export const useWalletValue = (
    wallet: UserCryptoWallet,
    currency: Currency,
) => {
    const formatWithCurrency = ({
        value,
        currency,
        minimumPrecision = 2,
        maximumPrecision = 8,
    }: {
        value: number;
        currency: string;
        minimumPrecision?: number;
        maximumPrecision?: number;
    }) => {
        const formattedValue = value
            .toLocaleString(undefined, {
                style: "currency",
                currency: currency,
                minimumFractionDigits: minimumPrecision,
                maximumFractionDigits: maximumPrecision,
            })
            .replace(/(\.|,)0+$/, ""); // Supprime les zéros inutiles en fin de nombre

        return formattedValue.replace("US", ""); // Enlève le préfixe "US" si présent
    };

    const conversionRate = (fromUsd: number, toUsd: number) => {
        return fromUsd / toUsd;
    };

    const convertedUnitPrice = (function () {
        if (
            wallet.currency === currency.currency ||
            wallet.current_price === currency.unit
        ) {
            return 1;
        }
        return conversionRate(wallet.current_price, currency.unit);
    })();

    const soldeConverted = roundTo(
        convertedUnitPrice * wallet.wallet.solde,
        wallet.type === "fiat"
            ? getFiatDecimalPlaces(currency.currency)
            : getDecimalPlaces(convertedUnitPrice), // Utilisation des décimales pour fiat ou crypto
    );

    const earningConverted = roundTo(
        convertedUnitPrice * wallet.wallet.staking_solde,
        wallet.type === "fiat"
            ? getFiatDecimalPlaces(currency.currency)
            : getDecimalPlaces(convertedUnitPrice), // Utilisation des décimales pour fiat ou crypto
    );

    return {
        solde: {
            default: UtilsService.formatAmount(
                wallet.wallet.solde,
                wallet.type === "fiat" ? 2 : 8,
            ),
            value: soldeConverted,
            formated: formatWithCurrency({
                value: soldeConverted,
                currency: currency.currency,
                minimumPrecision: 2, // Assure toujours un minimum de 2 décimales
                maximumPrecision:
                    wallet.type === "fiat"
                        ? getFiatDecimalPlaces(currency.currency) // Utilise les règles de décimales pour les fiat
                        : getDecimalPlaces(soldeConverted), // Utilise les règles de décimales pour les cryptos
            }),
        },
        unit: {
            value: convertedUnitPrice,
            formated: formatWithCurrency({
                value: convertedUnitPrice,
                currency: currency.currency,
                minimumPrecision: 2,
                maximumPrecision:
                    wallet.type === "fiat"
                        ? getFiatDecimalPlaces(currency.currency) // Utilise les règles de décimales pour les fiat
                        : getDecimalPlaces(convertedUnitPrice), // Utilise les règles de décimales pour les cryptos
            }),
        },
        earning: {
            value: earningConverted,
            formated: formatWithCurrency({
                value: earningConverted,
                currency: currency.currency,
                minimumPrecision: 2,
                maximumPrecision:
                    wallet.type === "fiat"
                        ? getFiatDecimalPlaces(currency.currency) // Utilise les règles de décimales pour les fiat
                        : getDecimalPlaces(earningConverted), // Utilise les règles de décimales pour les cryptos
            }),
        },
    };
};
