import React from "react";
import alert_message from "../../../Services/Alert/AlertMessage";
import AppAuthentication from "../../../Services/Authentication/AppAuthentication";
import { ModalContext } from "../Modal/ModalContext";
import translator from "../Translation/Translate";
import MessageSvg from "../Svg/MessageSvg";
import { JackpotPaymentStatus, OJackpotPayment } from "../../../Models/Crypto";
import JackpotPaymentService from "../../../Services/Wallets/JackpotPayment/JackpotPaymentService";

const PaymentContent = ({
    jackpotPayment,
    refresh,
}: {
    jackpotPayment: OJackpotPayment;
    refresh: () => void;
}) => {
    const { translate } = translator();
    const { getInformationAsync } = AppAuthentication();
    const { handleModal } = React.useContext(ModalContext);

    const changeStatus = (status: JackpotPaymentStatus) => {
        if (status === JackpotPaymentStatus.COMPLETED) {
            const execute = JackpotPaymentService.acceptPayment(
                jackpotPayment.id,
            );
            alert_message.promise("...", execute).then(() => {
                handleModal();
                getInformationAsync(true);
                refresh();
            });
        } else if (status === JackpotPaymentStatus.CANCELLED) {
            const execute = JackpotPaymentService.declinePayment(
                jackpotPayment.id,
            );
            alert_message.promise("...", execute).then(() => {
                handleModal();
                getInformationAsync(true);
                refresh();
            });
        }
    };
    const style = {
        fontFamily: " arial, helvetica, sans-serif",
        fontSize: "12pt",
    };

    return (
        <>
            <div className="flex justify-center items-center p-2 text-lg font-bold">
                {translate("JACKPOT", "PAYMENT_CONFIRMATION")}
            </div>

            <div className="p-5 block mt-4 mb-2.5">
                <div className="text-center">
                    <p>
                        <span style={style}>
                            {translate("JACKPOT", "TITLE_Desc")}{" "}
                            {`${jackpotPayment.amount} ${jackpotPayment.wallet.abbreviation}`}{" "}
                            {translate("JACKPOT", "ForJackpot")}{" "}
                            {`${jackpotPayment.target_wallet.name}`}
                        </span>
                    </p>
                </div>
            </div>
            <div className="mt-5 flex gap-2 justify-center w-full">
                <button
                    className="btn btn-perfect"
                    onClick={() => changeStatus(JackpotPaymentStatus.COMPLETED)}
                >
                    {translate("INVITATION_REQUEST", "ACCEPT")}
                </button>
                <button
                    className="btn bg-red-500 hover:bg-red-400 text-white"
                    onClick={() => changeStatus(JackpotPaymentStatus.CANCELLED)}
                >
                    {translate("INVITATION_REQUEST", "DECLINE")}
                </button>
            </div>
        </>
    );
};

const JackpotPayment = ({
    jackpotPayment,
    refresh,
}: {
    jackpotPayment: OJackpotPayment;
    refresh: () => void;
}) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    return (
        <div
            className={`${"bg-not-view p-2 rounded-b-lg rounded-r-lg cursor-pointer mb-2"}`}
            role="button"
            onClick={async () => {
                handleModal(
                    <PaymentContent
                        jackpotPayment={jackpotPayment}
                        refresh={refresh}
                    />,
                );
            }}
            title={translate("Notification", "See")}
        >
            <div className="flex justify-between">
                <div>
                    <div className="inline-flex text-xs font-medium items-center">
                        <MessageSvg /> {translate("JACKPOT", "TITLE_NOTIF")}
                    </div>
                    <div className={"block truncate mt-2 text-xs text-left"}>
                        {translate("JACKPOT", "TITLE_Desc")}{" "}
                        {`${jackpotPayment.amount} ${jackpotPayment.wallet.abbreviation}`}{" "}
                        {translate("JACKPOT", "ForJackpot")}{" "}
                        {`${jackpotPayment.target_wallet.name}`}
                    </div>
                </div>
                <div className="text-xs self-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-eye"
                    >
                        <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                        <circle cx="12" cy="12" r="3" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default JackpotPayment;
