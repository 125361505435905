import { useEffect, useState } from "react";
import translator from "../../Translation/Translate";
import { DataTableColums } from "../DataTable";
import DataTablePageLength from "../pagination/DataTablePageLength";
import { ModalProvider } from "../../Modal/ModalContext";
import AffiliationModal from "../../../../Admin/Components/User/List/AffiliationModal/AffiliationModal";

const DataTableFilter = ({
    currentData,
    data,
    setTempData,
    columns,
    tableButton,
    perPage,
    setPerPage,
    addParrain,
}: {
    data: any[];
    currentData: any[];
    setTempData: (data: any[]) => void;
    columns: DataTableColums[];
    tableButton?: React.ReactNode;
    perPage: number;
    setPerPage: (page: number) => void;
    addParrain?: boolean;
}) => {
    const [filter, setFilter] = useState<string>();
    const { translate } = translator();

    const currentUrl = window.location.href;

    useEffect(() => {
        if (filter !== null) {
            const search = filter;
            const result = data.reduce((acc, item) => {
                for (let i = 0; i < columns.length; i++) {
                    const col = columns[i];
                    if (!!col.filter) {
                        const item_value = item[col.index];
                        if (
                            item_value &&
                            item_value.toString().includes(search)
                        ) {
                            acc.push(item);
                            break;
                        }
                    }
                }

                return acc;
            }, []);
            setTempData(result);
        }
    }, [filter]);

    return (
        // <div className="intro-y col-span-12 mt-2 flex flex-wrap justify-between items-center sm:flex-nowrap justify-center-mobile">
        <div className="flex intro-y col-span-12 py-1 mb-4">
            {tableButton}
            <div className="md:!ml-2.5">
                <DataTablePageLength
                    perPage={perPage}
                    setPerPage={setPerPage}
                />
            </div>
            <div className="mx-auto text-slate-500 md:block">
                <div className="hidden">
                    {translate("DataTable", "Affichage")}
                    {currentData.length}
                    {translate("DataTable", "Element")}
                    {currentData.length}
                    {translate("DataTable", "Entree")}
                </div>
            </div>
            {!!addParrain && currentUrl.includes("admin") && (
                <div className="mr-2">
                    <ModalProvider>
                        <AffiliationModal />
                    </ModalProvider>
                </div>
            )}
            <div className="mt-3 w-full sm:mt-0 sm:ml-auto sm:w-auto md:ml-0">
                <div className="relative w-56 text-slate-500 input-search-mobile pl-2 pr-1">
                    <input
                        type="text"
                        value={filter ? filter : ""}
                        onChange={(e) => setFilter(e.target.value)}
                        className="form-control box w-full pr-10"
                        placeholder={translate("DataTable", "Search")}
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="absolute lucide lucide-search right-2 top-2"
                    >
                        <circle cx="11" cy="11" r="8" />
                        <path d="m21 21-4.3-4.3" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default DataTableFilter;
