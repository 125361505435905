import React, { createContext, useContext, useEffect, useState } from "react";
import alert_message from "../../Services/Alert/AlertMessage";
import { useAppProvider } from "./AppProvider";
import SettingService from "../../Services/Setting/SettingService";

interface ContextProps {
    countriesBlacklist: string[];
}

const PhoneContext = createContext<ContextProps>({
    countriesBlacklist: [],
});

export default function PhoneProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [countriesBlacklist, setCountriesBlacklist] = useState<string[]>([]);
    const { setProcessing, finalizeProcessing } = useAppProvider();

    const getCountriesBlacklist = async () => {
        setProcessing(true);

        try {
            const response =
                await SettingService.getCountriesBlacklistSetting();

            if (response) {
                setCountriesBlacklist(
                    response.countries.map((option) => option.toLowerCase()),
                );
            }
        } catch (err: any) {
            alert_message.error(err);
        }
        finalizeProcessing();
    };

    useEffect(() => {
        getCountriesBlacklist();
    }, []);

    return (
        <PhoneContext.Provider
            value={{
                countriesBlacklist,
            }}
        >
            {children}
        </PhoneContext.Provider>
    );
}

export const usePhone = () => useContext(PhoneContext);
