import React from "react";
import { Helmet } from "react-helmet";
import useLanguage from "../../../Services/Hooks/useLanguage";
import { HelmetProps } from "../../../Models/HelmetProps";

const DOMAIN = "https://infinexia.com";
const POSTFIX_TITLE = "Infinexia | ";
const DEFAULT_DESCRIPTION = "Simplified management of your finances";
const MAIN_KEYWORDS = "finance, banking, money transfer";

const DEFAULT_IMAGE_CARD =
    "https://infinexia.com/dist/image/Infinexia-cards-1024x1024.png";
const FAVICON_SOURCE =
    "https://infinexia.com/dist/image/Fav-Infinexia-512x512.webp";

const HelmetComponent = (helmetProps: HelmetProps) => {
    const {
        title,
        description,
        link,
        keywords,
        imageCard,
        largeTwitterCard,
        addPostfixTitle,
        noIndex,
    } = helmetProps;

    const { language } = useLanguage();
    let metaTitle: string;

    if (addPostfixTitle) {
        metaTitle = POSTFIX_TITLE + title;
    } else {
        metaTitle = title;
    }

    const metaDesc = description ?? DEFAULT_DESCRIPTION;
    const metaLink = DOMAIN + "/" + language + link + "/";

    const metaKeywords = keywords.length
        ? MAIN_KEYWORDS + ", " + keywords
        : MAIN_KEYWORDS;

    let metaImageCard: string;

    if (imageCard) {
        if (imageCard.startsWith("https")) {
            metaImageCard = imageCard;
        } else {
            metaImageCard = DOMAIN + imageCard;
        }
    } else {
        metaImageCard = DEFAULT_IMAGE_CARD;
    }

    const metaRobots = noIndex ? "noindex, nofollow" : "index, follow";

    const twitterCardType = largeTwitterCard
        ? "summary_large_image"
        : "summary";

    return (
        <Helmet>
            <html lang={language.toLocaleLowerCase()} />

            <title>{metaTitle}</title>

            <meta name="description" content={metaDesc} />
            <meta name="keywords" content={metaKeywords} />
            <meta name="robots" content={metaRobots} />

            <link rel="canonical" href={metaLink} />
            <link rel="icon" href={FAVICON_SOURCE} />

            {/* OG Tags */}
            <meta property="og:url" content={metaLink} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDesc} />
            <meta property="og:type" content={DOMAIN} />
            <meta property="og:image" content={metaImageCard} />

            {/* Twitter Tags */}
            <meta name="twitter:site" content="Infinexiaoff" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDesc} />
            <meta name="twitter:creator" content="Infinexiaoff" />
            <meta name="twitter:card" content={twitterCardType} />
            <meta name="twitter:image" content={metaImageCard} />

            <meta name="referrer" content="origin-when-crossorigin" />
        </Helmet>
    );
};

export default HelmetComponent;
