import React, { useState } from "react";

const Toggle = () => {
    const [toggle, setToggle] = useState<boolean>(false);
    return (
        <div className="my-10 w-full p-5 block">
            <div className="flex justify-center items-center">
                <button
                    type="button"
                    className="btn btn-perfect w-1/4"
                    onClick={() => setToggle(!toggle)}
                >
                    Toggle
                </button>
            </div>
            <div
                className={
                    toggle
                        ? "w-full mt-10 transition-all duration-700 slide-down"
                        : "slide-up"
                }
            >
                {toggle && (
                    <div className="flex space-x-2">
                        <button className="btn bg-white text-black-400 border-[1px] border-[#303B8E] !px-5 !py-1 relative">
                            flexible
                            <div className="corner-ribbon">
                                <span className="cr-inner">
                                    <span className="cr-text">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            fill="currentColor"
                                            className="bi bi-check text-white"
                                            viewBox="0 0 16 16"
                                        >
                                            {" "}
                                            <path
                                                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                                                fill="white"
                                            ></path>{" "}
                                        </svg>
                                    </span>
                                </span>
                            </div>
                        </button>
                        <button className="block btn bg-white text-black-400 border-[1px] !px-5 !py-1">
                            <div className="text-medium text-sm">90</div>
                            <div className="text-slate-400/60 text-xs">
                                days
                            </div>
                        </button>
                        <button className="block btn bg-white text-black-400 border-[1px] !px-5 !py-1">
                            <div className="text-medium text-sm">180</div>
                            <div className="text-slate-400/60 text-xs">
                                days
                            </div>
                        </button>
                        <button className="block btn bg-white text-black-400 border-[1px] !px-5 !py-1">
                            <div className="text-medium text-sm">360</div>
                            <div className="text-slate-400/60 text-xs">
                                days
                            </div>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Toggle;
