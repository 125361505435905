import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";

const bg = "/dist/image/bg-top.webp";

const TermAndConditions = () => {
    const { translate } = translator();
    return (
        <section className=" text-base text-[#333] font-medium break-words  ">
            <HelmetComponent
                title={translate("SEO", "Title_Terms")}
                description={translate("SEO", "Desc_Terms")}
                link="/terms-and-conditions"
                keywords={translate("SEO", "Keys_Terms")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={true}
            ></HelmetComponent>
            <div
                className="  h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat  flex justify-center items-center text-white"
                style={{ backgroundImage: `url(${bg})` }}
            >
                <h1 className=" pt-10 md:!text-3xl text-2xl font-bold tracking-wide text-center ">
                    {translate("TERMS_PAGE", "MAIN_TITLE")}
                </h1>
            </div>
            <div className=" bg-white py-[100px] ">
                <div className=" w-[80%] max-w-[1080px] m-auto sm:text-base py-6 text-base   ">
                    <div className=" pb-10 mb-[36px]    ">
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "INTRO_TEXT_1-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "INTRO_TEXT_1-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "INTRO_TEXT_1-3")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "INTRO_TEXT_1-4")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "INTRO_TEXT_1-5")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_1-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_1-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_1-3")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_2-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_2-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_2-3")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_2-4")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_3")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_3-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_3-2")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_4")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_4-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_4-2")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_5")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_5-1")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_6")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_6-1")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_7")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_7-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_7-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_7-3")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_8")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_8-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_8-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_8-3")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_8-4")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_9")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_9-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_9-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_9-3")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_10")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_10-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_10-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_10-3")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_10-4")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_10-5")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_11")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_11-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_11-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_11-3")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_11-4")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_12")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_12-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_12-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_12-3")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_13")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_13-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_13-2")}
                            <a href="https://infinexia.com/privacy-policy/">
                                {translate("TERMS_PAGE", "PARAGRAPH_13-3")}
                            </a>
                            .
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_14")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_14-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_14-2")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_15")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_15-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_15-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_15-3")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_16")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-3")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-4")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-5")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-6")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-7")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-8")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-9")}
                        </p>
                        <p className="text-base text-left font-normal pb-[40px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-10")}
                        </p>
                        <p className="text-base text-left font-bold pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-11")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-12")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-13")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_16-14")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_17")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_17-1")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_18")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_18-1")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_19")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_19-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_19-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_19-3")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_20")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_20-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_20-2")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_20-3")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_21")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_21-1")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_21-2")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_22")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_22-1")}
                        </p>
                        <p className="text-base text-left font-bold mt-[30px] pb-[5px]">
                            {translate("TERMS_PAGE", "TERMS_TITLE_23")}
                        </p>
                        <p className="text-base text-left font-normal pb-[10px]">
                            {translate("TERMS_PAGE", "PARAGRAPH_23-1")}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermAndConditions;
