import React, { useState } from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import QuestionSvg from "../../../Components/Extra/Svg/QuestionSvg";

const CookieImage = "/dist/image/CookieBanner2.webp";

interface SwitchProps {
    checked: boolean;
    disabled?: boolean;
    changeState?: (state: boolean) => void;
}

const SwitchTex = ({ checked, changeState, disabled }: SwitchProps) => {
    // const [value, setValue] = useState<boolean>(false);
    return (
        <div
            className={`checkbox flex items-center cursor-pointer justify-between w-[1.6rem] h-[0.8rem] rounded-[10px] flex-row-reverse ${
                disabled &&
                "!cursor-not-allowed !bg-gray-100 !text-gray-300 !border !border-gray-300"
            }  ${!checked && "checkbox--on"}`}
            role="button"
            onClick={() => {
                if (changeState) {
                    changeState(!checked);
                }
            }}
        >
            <div className="w-[0.7rem] h-full rounded-full transition-transform bg-gray-200"></div>
        </div>
    );
};
const CookieBanner = () => {
    let cookie_consent = "false";
    let cookie_consent_hidden = "false";
    let preference = {
        essentials: true,
        functional: true,
        statistics: true,
    };

    if (typeof localStorage != "undefined") {
        cookie_consent = localStorage.getItem("cookie_consent") ?? "false";
        cookie_consent_hidden =
            localStorage.getItem("cookie_consent_hidden") ?? "false";

        try {
            const d = localStorage.getItem("cookie_preference");
            if (d) preference = JSON.parse(d);
        } catch {}
    }

    const { translate } = translator();
    const [consent, setConsent] = useState(cookie_consent === "true");
    const [hidden, setHidden] = useState(cookie_consent_hidden === "true");
    const [showPopup, setShowPopup] = useState(false);
    const [cookiePreferences, setCookiePreferences] = useState({
        essentials: String(preference.essentials) == "true",
        functional: String(preference.functional) == "true",
        statistics: String(preference.statistics) == "true",
    });

    const acceptCookies = () => {
        localStorage.setItem("cookie_consent_hidden", "true");
        localStorage.setItem("cookie_consent", "true");
        setConsent(true);
        setHidden(true);
    };

    const rejectCookies = () => {
        localStorage.setItem("cookie_consent_hidden", "false");
        localStorage.setItem("cookie_consent", "false");
        setConsent(false);
        setHidden(true);
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleCookiePreferenceChange = (type: string) => {
        if (type === "essentials") {
            return; // Ne rien faire si l'utilisateur essaie de changer les préférences des cookies essentiels
        }
        setCookiePreferences({
            ...cookiePreferences,
            [type]: !(cookiePreferences as any)[type],
        });
    };

    const handleToggleAll = () => {
        const allChecked = Object.keys(cookiePreferences).every(
            (key) => (cookiePreferences as any)[key],
        );
        const updatedPreferences = {};
        for (const key in cookiePreferences) {
            // Ignorer le changement de statut du switch "Essentiels"
            if (key !== "essentials") {
                (updatedPreferences as any)[key] = !allChecked;
            }
        }
        setCookiePreferences(updatedPreferences as any);
    };

    const hideBanner = () => {
        setHidden(true);
    };

    const handleSavePreferences = () => {
        // Enregistre les préférences de cookies
        // Implémentez votre logique d'enregistrement ici
        // Par exemple, vous pouvez envoyer les préférences au serveur

        localStorage.setItem(
            "cookie_preference",
            JSON.stringify(cookiePreferences),
        );

        localStorage.setItem("cookie_consent_hidden", "true");

        togglePopup();

        togglePopup(); // Ferme la fenêtre pop-up après l'enregistrement
        hideBanner(); // Cache la bannière principale
    };

    return (
        <div
            className={`cookie-banner ${
                hidden ? "hidden" : ""
            } fixed bottom-0 left-0 w-full bg-white p-4 border-t border-gray-300 text-center text-base flex flex-col md:flex-row items-center justify-between`}
        >
            <div className="cookie-text-container  block md:flex items-center  mb-5 md:!mb-0">
                <p className="cookie-text text-xs md:!text-sm m-0 text-left flex items-center mr-2 ">
                    <img
                        src={CookieImage}
                        alt="Paramètres"
                        className="w-8 md:!w-10 h-8 md:!h-10 mr-2"
                    />
                    <span>
                        {translate("Cookies", "Cookie_text")}{" "}
                        <a
                            href="/privacy-policy"
                            className="text-xs md:!text-sm text-left underline "
                        >
                            {translate("Cookies", "Cookie_text_more")}
                        </a>
                    </span>
                </p>
            </div>
            <div className="cookie-buttons flex items-center">
                <button
                    className="text-xs md:!text-sm font-medium bg-green-600 text-white p-2 py-2 px-4 rounded-lg mr-3"
                    onClick={acceptCookies}
                >
                    {translate("Cookies", "Cookie_btn1")}
                </button>
                <button
                    className="text-xs md:!text-sm font-medium bg-gray-400 text-white p-2 py-2 px-4 rounded-lg mr-3"
                    onClick={togglePopup}
                >
                    {translate("Cookies", "Cookie_btn2")}
                </button>
                <button
                    className="text-xs md:!text-sm font-medium bg-red-600 text-white p-2 py-2 px-4 rounded-lg"
                    onClick={rejectCookies}
                >
                    {translate("Cookies", "Cookie_btn3")}
                </button>
            </div>
            {showPopup && (
                <div className="cookie-popup fixed z-[100] mt-[55px] md:!mt-[0px] pt-7 pb-1 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded shadow-lg border border-gray-300 w-full md:!w-auto h-[97.5%] md:!h-auto">
                    <h3 className="font-bold text-lg mb-4">
                        {translate("Cookies", "Cookie_title_popup")}
                    </h3>
                    <h4 className="text-xs md:!text-sm text-left font-light">
                        {translate("Cookies", "Cookie_sub1_popup")}
                    </h4>
                    <h4 className="text-xs md:!text-sm mb-5  text-left flex items-center font-light">
                        {translate("Cookies", "Cookie_sub2_popup")}
                    </h4>
                    <div className="toggle-all flex items-center mt-4 mb-2 gap-x-3 ml-2 text-xs md:!text-sm">
                        <span className="font-bold ml-2 mr-2">
                            {translate("Cookies", "Cookie_select_all")}
                        </span>
                        <SwitchTex
                            checked={Object.keys(cookiePreferences).every(
                                (key) => (cookiePreferences as any)[key],
                            )}
                            changeState={handleToggleAll}
                        />
                    </div>
                    <div className="preferences-list rounded-lg border border-gray-400 py-4 px-4 mb-2 text-xs md:!text-sm">
                        <div className="preference-item mb-5 ">
                            <label className="flex justify-between">
                                <div>
                                    <span className="flex justify-left font-bold mr-3">
                                        {translate(
                                            "Cookies",
                                            "Cookie_Essential",
                                        )}
                                        <a
                                            href="/privacy-policy"
                                            className="ml-1"
                                        >
                                            <QuestionSvg />
                                        </a>
                                    </span>
                                    <span className="flex justify-left text-left mr-5 font-light">
                                        {translate(
                                            "Cookies",
                                            "Cookie_Essential_text-1",
                                        )}
                                    </span>
                                    <span className="flex justify-left text-left mr-5 font-light">
                                        {translate(
                                            "Cookies",
                                            "Cookie_Essential_text-2",
                                        )}
                                    </span>
                                </div>
                                <div className="flex items-end mb-[25px] md:!mb-[20px] justify-center">
                                    <SwitchTex
                                        checked={true}
                                        changeState={() =>
                                            handleCookiePreferenceChange(
                                                "essentials",
                                            )
                                        }
                                    />
                                </div>
                            </label>
                        </div>
                        <div className="preference-item mb-5">
                            <label className="flex justify-between">
                                <div>
                                    <span className="flex justify-left font-bold mr-3">
                                        {translate(
                                            "Cookies",
                                            "Cookie_Functional",
                                        )}
                                        <a
                                            href="/privacy-policy"
                                            className="ml-1"
                                        >
                                            <QuestionSvg />
                                        </a>
                                    </span>
                                    <span className="flex justify-left text-left mr-5 font-light">
                                        {translate(
                                            "Cookies",
                                            "Cookie_Functional_text-1",
                                        )}
                                    </span>
                                    <span className="flex justify-left text-left mr-5 font-light">
                                        {translate(
                                            "Cookies",
                                            "Cookie_Functional_text-2",
                                        )}
                                    </span>
                                </div>
                                <div className="flex items-end mb-[25px] md:!mb-[20px] justify-center">
                                    <SwitchTex
                                        checked={cookiePreferences.functional}
                                        changeState={() =>
                                            handleCookiePreferenceChange(
                                                "functional",
                                            )
                                        }
                                    />
                                </div>
                            </label>
                        </div>
                        <div className="preference-item mb-0">
                            <label className="flex justify-between">
                                <div>
                                    <span className="flex justify-left font-bold mr-3">
                                        {translate(
                                            "Cookies",
                                            "Cookie_Statistics",
                                        )}
                                        <a
                                            href="/privacy-policy"
                                            className="ml-1"
                                        >
                                            <QuestionSvg />
                                        </a>
                                    </span>
                                    <span className="flex justify-left text-left mr-5 font-light">
                                        {translate(
                                            "Cookies",
                                            "Cookie_Statistics_text-1",
                                        )}
                                    </span>
                                    <span className="flex justify-left text-left mr-5 font-light">
                                        {translate(
                                            "Cookies",
                                            "Cookie_Statistics_text-2",
                                        )}
                                    </span>
                                </div>
                                <div className="flex items-end mb-[25px] md:!mb-[20px] justify-center">
                                    <SwitchTex
                                        checked={cookiePreferences.statistics}
                                        changeState={() =>
                                            handleCookiePreferenceChange(
                                                "statistics",
                                            )
                                        }
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <button
                        className="bg-gray-400 text-center text-xs md:!text-sm rounded-lg p-2 py-2 px-4 sm:w-2/2 w-2/2 w-auto text-white font-medium my-6 mr-3"
                        onClick={() => {
                            togglePopup();
                            setCookiePreferences({
                                essentials:
                                    String(preference.essentials) == "true",
                                functional:
                                    String(preference.functional) == "true",
                                statistics:
                                    String(preference.statistics) == "true",
                            });
                            localStorage.setItem(
                                "cookie_consent_hidden",
                                "true",
                            );
                        }}
                    >
                        {translate("Cookies", "Cookie_btn1_pop")}
                    </button>
                    <button
                        className="bg-green-600 text-center text-xs md:!text-sm rounded-lg p-2 py-2 px-4 sm:w-2/2 w-2/2 w-auto text-white font-medium my-6"
                        onClick={handleSavePreferences}
                    >
                        {translate("Cookies", "Cookie_btn2_pop")}
                    </button>
                </div>
            )}
        </div>
    );
};

export default CookieBanner;
