import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ChevronDownSvg from "../../../Components/Extra/Svg/ChevronDownSvg";
import translator from "../../../Components/Extra/Translation/Translate";
import User, { UserType } from "../../../Models/User";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import AppAuthentication from "../../../Services/Authentication/AppAuthentication";
import alert_message from "../../../Services/Alert/AlertMessage";
import { OTransactionCount } from "../../../Models/Transaction";

export type ItemListProps = {
    name: string;
    icon: any;
    path: string;
    childs: ItemListProps[];
    user?: User;
    transactionCount?: OTransactionCount;
};

const ItemListComponent = ({ name, icon, childs, path }: ItemListProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { translate } = translator();
    const { user } = useAuthentication();
    const { switchToJoinAccount, switchToOriginAccount } = AppAuthentication();

    const toggleExanded = (e: any) => {
        if (childs && childs.length > 0) {
            setExpanded((expanded) => !expanded);
            e.preventDefault();
            return false;
        }
    };

    if (path === "/child-accounts" && !user?.is_parent) return null;
    if (path === "/switch-join-account" && !user?.join_account) return null;
    if (path === "/switch-origin-account" && user?.type !== UserType.JOIN)
        return null;

    return (
        <li>
            {["/switch-join-account"].includes(path) && (
                <NavLink
                    to={`javascript:void(0)`}
                    className={(navData) =>
                        navData.isActive
                            ? expanded
                                ? "side-menu side-menu--open"
                                : "side-menu side-menu--active"
                            : "side-menu"
                    }
                    onClick={async (e) => {
                        e.preventDefault();
                        const promise = switchToJoinAccount(translate);
                        alert_message.promise(
                            `${translate("APP", "switch_to_join_account_msg")}`,
                            promise,
                        );
                    }}
                >
                    <div className="side-menu__icon">{icon}</div>
                    <div className="side-menu__title">
                        {translate(".", name)}
                        {childs && childs.length > 0 && (
                            <div
                                className={
                                    !expanded
                                        ? "side-menu__sub-icon"
                                        : "side-menu__sub-icon rotate-180"
                                }
                            >
                                <ChevronDownSvg />
                            </div>
                        )}
                    </div>
                </NavLink>
            )}
            {["/switch-origin-account"].includes(path) && (
                <NavLink
                    to={`javascript:void(0)`}
                    className={(navData) =>
                        navData.isActive
                            ? expanded
                                ? "side-menu side-menu--open"
                                : "side-menu side-menu--active"
                            : "side-menu"
                    }
                    onClick={async (e) => {
                        e.preventDefault();
                        const promise = switchToOriginAccount(translate);
                        alert_message.promise(
                            `${translate("APP", "switch_to_real_account_msg")}`,
                            promise,
                        );
                    }}
                >
                    <div className="side-menu__icon">{icon}</div>
                    <div className="side-menu__title">
                        {translate(".", name)}
                        {childs && childs.length > 0 && (
                            <div
                                className={
                                    !expanded
                                        ? "side-menu__sub-icon"
                                        : "side-menu__sub-icon rotate-180"
                                }
                            >
                                <ChevronDownSvg />
                            </div>
                        )}
                    </div>
                </NavLink>
            )}
            {!["/switch-join-account"].includes(path) &&
                !["/switch-origin-account"].includes(path) && (
                    <>
                        <NavLink
                            to={
                                childs && childs.length > 0
                                    ? `javascript:void(0)`
                                    : path
                            }
                            className={(navData) =>
                                navData.isActive
                                    ? expanded
                                        ? "side-menu side-menu--open"
                                        : "side-menu side-menu--active"
                                    : "side-menu"
                            }
                            onClick={(e) => {
                                toggleExanded(e);
                            }}
                        >
                            <div className="side-menu__icon">{icon}</div>
                            <div className="side-menu__title">
                                {translate(".", name)}
                                {childs && childs.length > 0 && (
                                    <div
                                        className={
                                            !expanded
                                                ? "side-menu__sub-icon"
                                                : "side-menu__sub-icon rotate-180"
                                        }
                                    >
                                        <ChevronDownSvg />
                                    </div>
                                )}
                            </div>
                        </NavLink>
                        {childs && childs.length > 0 && (
                            <ul
                                className={
                                    expanded
                                        ? "side-menu__sub-open block pl-3"
                                        : ""
                                }
                            >
                                {childs.map((child, i) => (
                                    <ItemListComponent
                                        key={i}
                                        name={child.name}
                                        icon={child.icon}
                                        path={child.path}
                                        childs={child.childs}
                                    ></ItemListComponent>
                                ))}
                            </ul>
                        )}
                    </>
                )}
        </li>
    );
};

export default ItemListComponent;
