import React, { createContext, useContext, useEffect, useState } from "react";
import Transaction from "../../Models/Transaction";
import alert_message from "../../Services/Alert/AlertMessage";
import TransactionService from "../../Services/Transaction/TransactionService";
import { useAppProvider } from "./AppProvider";

interface TransactionDetailContextProps {
    transaction: Transaction | null;
    setTransaction: (transaction: Transaction | null) => void;
    details?: any;
}

const TransactionDetailContext = createContext<
    TransactionDetailContextProps | undefined
>(undefined);

export const TransactionDetailProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [transaction, setTransaction] = useState<Transaction | null>(null);
    const [details, setDetails] = useState<any | undefined>(undefined);
    const { setProcessing, finalizeProcessing } = useAppProvider();

    const getDetails = async () => {
        if (!transaction) {
            setDetails(undefined);
            return;
        }
        setProcessing(true);
        try {
            const data = await TransactionService.getDetail(
                Number(transaction.id),
            );
            setDetails(data);
        } catch (err: any) {
            alert_message.error(err);
        }
        finalizeProcessing();
    };

    useEffect(() => {
        getDetails();
    }, [transaction?.id]);

    return (
        <TransactionDetailContext.Provider
            value={{ transaction, setTransaction, details }}
        >
            {children}
        </TransactionDetailContext.Provider>
    );
};

export const useTransactionDetail = () => {
    const context = useContext(TransactionDetailContext);
    if (!context) {
        throw new Error(
            "useTransaction must be used within a TransactionProvider",
        );
    }
    return context;
};
