import React, { useRef } from "react";
import translator from "../Extra/Translation/Translate";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import CheckPriceSvg from "../Extra/Svg/CheckPriceSvg";
import LockPriceSvg from "../Extra/Svg/LockPriceSvg";
import CheckNonePriceSvg from "../Extra/Svg/CheckNonePriceSvg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import "../../App/swiper.css";
import CircleCheckSvg from "../Extra/Svg/CircleCheckSvg";
import CreditCardSvg from "../Extra/Svg/CreditCardSvg";
import CircleMinusSvg from "../Extra/Svg/CircleMinusSvg";
import ChevronsDownPricesSvg from "../Extra/Svg/ChevronsDownPricesSvg";

const Card_Blue = "/dist/image/Blue.png";
const Card_Gold = "/dist/image/Gold.png";
const Card_Black = "/dist/image/Black.png";

interface IndividualPriceCardsProps {
    active: "blue" | "black" | "gold";
    handleButtonClick: (color: "blue" | "gold" | "black") => void;
    handleSlideChange: (swiper: SwiperType) => void;
    disableDarkMode?: boolean; // Propriété pour désactiver le dark mode
}

const IndividualPriceCards: React.FC<IndividualPriceCardsProps> = ({
    active,
    handleButtonClick,
    handleSlideChange,
    disableDarkMode,
}) => {
    const { translate } = translator();
    const { lang } = useParams();

    return (
        <div className="sm:!bg-[#F1F5F9] md:!bg-white  pt-[5px] md:!pt-[75px] pb-[5px] md:!pb-[0px]">
            <div className=" grid  grid-cols-1 lg:!grid-cols-3 w-[96%] max-w-[1280px] mx-auto  py-6 md:text-lg  sm:text-base  grid-flow-row gap-6 ">
                <div
                    className={` pb-[5px] rounded-[10px]  mt-[30px] border !border-[#bebebe] bg-white ${
                        active == "blue" ? "" : "hidden md:!block"
                    } `}
                >
                    <div className=" bg-[#2956BE] text-center flex justify-center w-full py-[10px] px-[15px] rounded-t-[10px]">
                        <h3 className="text-xl md:!text-2xl text-white font-medium">
                            Blue
                        </h3>
                    </div>
                    <div className="block text-center">
                        <div className="flex justify-center items-center pt-5">
                            <div className="h-auto w-[80%] md:!w-[75%] md:!h-auto p-3">
                                <img
                                    src={Card_Blue}
                                    alt=""
                                    className="object-contain w-full h-full rounded-lg dark:!border dark:!border-slate-700"
                                />
                            </div>
                        </div>
                        <div className=" pt-[5px] text-lg font-medium">
                            <h3 className="text-lg md:!text-xl font-bold text-[#66666] ">
                                {translate("PRICES_PAGE", "Free")}
                            </h3>
                            <p className="hidden md:block text-lg font-normal pt-[0px] pb-[0px]">
                                {translate(
                                    "PRICES_PAGE",
                                    "A_Free_Multi_Currency_Account",
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="hidden pl-text-left text-lg font-bold px-[15px] ">
                        <p className=" px-[20px] text-left text-base font-normal pb-[5px]">
                            {translate("PRICES_PAGE", "Free_Internal")}
                        </p>
                    </div>
                    <div className="sm:mt-1.5 md:mt-[10px] px-[10px] md:!px-[30px]  block text-center ">
                        <p className="sm-block text-sm font-normal pt-[0px]">
                            <span className="font-normal">
                                {translate("PRICES_PAGE", "More_Opt")}
                            </span>
                        </p>
                        <p className="sm-block text-black text-sm font-normal">
                            <span className="text-black text-sm font-normal">
                                {translate("PRICES_PAGE", "Option")}
                            </span>{" "}
                            <span className="text-sm md:!text-2xl font-bold text-[#2956BE]">
                                Blue +
                            </span>
                        </p>
                        <div className="hidden md:block bg-[#F1F5F9] py-[12.5px] text-lg font-medium rounded-lg">
                            <p className=" text-xl md:!text-2xl font-bold pb-[0px]">
                                Blue +
                            </p>
                            <p className="text-base md:!text-lg font-normal pb-[0px]">
                                {translate("PRICES_PAGE", "More_Optional")}
                            </p>
                            <p className="text-base md:!text-lg font-bold pb-[5px]">
                                <CreditCardSvg />
                                {translate(
                                    "PRICES_PAGE",
                                    "Blue_Bank_Card_And_IBAN",
                                )}
                            </p>
                            <p className=" text-lg text-slate-500">
                                <span className="text-lg md:!text-xl font-bold text-slate-800">
                                    4,90 €
                                </span>
                                /{translate("PRICES_PAGE", "Month")}
                            </p>
                            <p className="text-sm font-normal pt-[5px]">
                                <span className="font-bold text-green-600">
                                    {translate("PRICES_PAGE", "2_Months_Free")}
                                </span>{" "}
                                {translate("PRICES_PAGE", "By_Opting")}
                            </p>
                            <p className="text-sm font-normal pb-[5px]">
                                {translate(
                                    "PRICES_PAGE",
                                    "For_Annual_Subscription",
                                )}
                            </p>
                        </div>
                        <div className="sm:mt-[4px] md:mt-[8px] mb-[0px] tracking-wide text-center">
                            <NavLink
                                to={`${lang ? `/${lang}` : ""}/register`}
                                className="btn btn-perfect text-center text-sm md:!text-base px-10 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6"
                            >
                                {translate("PRICES_PAGE", "Try_Infinexia_Blue")}
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div
                    className={`${
                        active == "gold" ? "" : "hidden md:!block"
                    }  pb-[5px] rounded-[10px] mt-[30px] lg:!mt-[7px] border !border-[#bebebe] bg-white`}
                >
                    <div className="bg-[#2956BE] text-center flex flex-col items-center py-[10px] px-[15px] pb-[10px] rounded-t-[10px]">
                        <h3 className="text-xl md:!text-2xl text-white font-medium">
                            Gold
                        </h3>
                        <p className=" text-base md:!text-base text-white font-normal">
                            {translate("PRICES_PAGE", "The_Most_Popular")}
                        </p>
                    </div>
                    <div className="block text-center">
                        <div className="flex justify-center items-center pt-5">
                            <div className="h-auto w-[80%] md:!w-[75%] md:!h-auto p-3">
                                <img
                                    src={Card_Gold}
                                    alt=""
                                    className="object-contain w-full h-full rounded-lg dark:!border dark:!border-slate-700"
                                />
                            </div>
                        </div>
                        <div className=" pt-[5px] text-lg font-medium">
                            <p className="text-lg text-slate-500">
                                <span className="text-lg md:!text-xl font-bold text-slate-800">
                                    9,90 €
                                </span>
                                / {translate("PRICES_PAGE", "Month")}
                            </p>
                            <p className=" text-sm font-normal pt-[5px]">
                                <span className="font-bold text-green-600">
                                    {translate("PRICES_PAGE", "2_Months_Free")}
                                </span>{" "}
                                {translate("PRICES_PAGE", "By_Opting")}
                            </p>
                            <p className="text-sm font-normal pb-[5px]">
                                {translate(
                                    "PRICES_PAGE",
                                    "For_Annual_Subscription",
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="hidden md:block pt-[10px] pb-[0px] text-left text-base md:!text-lg font-bold md:!px-[50px] px-[40px]">
                        <div className="mb-3">
                            <CreditCardSvg />
                            {translate(
                                "PRICES_PAGE",
                                "Gold_Bank_Card_And_IBAN",
                            )}
                        </div>
                        <p className="text-left text-base font-normal pb-[5px]">
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "CB_Limits")}
                            </div>
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Reductions_On")}
                            </div>
                            {/*<div className="mb-3">
                                <CircleCheckSvg />
                                {translate(
                                    "PRICES_PAGE",
                                    "Discounts_On_Purchases",
                                )}
                            </div>*/}
                            <div className="mb-3">
                                <CircleMinusSvg />
                                {translate("PRICES_PAGE", "Cashback_On")}
                            </div>
                            <div className="mb-1">
                                <CircleMinusSvg />
                                {translate("PRICES_PAGE", "Cashback_On_CB")}
                            </div>
                        </p>
                    </div>
                    <div className="mt-[-5px] mb-[0px] tracking-wide text-center">
                        <NavLink
                            to={`${lang ? `/${lang}` : ""}/register`}
                            className="btn btn-perfect text-center text-sm md:!text-base px-10 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6"
                        >
                            {translate("PRICES_PAGE", "Try_Infinexia_Gold")}
                        </NavLink>
                    </div>
                </div>
                <div
                    className={`  pb-[5px] rounded-[10px]  mt-[30px] border !border-[#bebebe] bg-white ${
                        active == "black" ? "" : "hidden md:!block"
                    } `}
                >
                    <div className=" bg-[#2956BE] text-center flex justify-center w-full py-[10px] px-[15px] rounded-t-[10px]">
                        <h3 className="text-xl md:!text-2xl text-white font-medium">
                            Black
                        </h3>
                    </div>
                    <div className="block text-center">
                        <div className="flex justify-center items-center pt-5">
                            <div className="h-auto w-[80%] md:!w-[75%] md:!h-auto p-3">
                                <img
                                    src={Card_Black}
                                    alt=""
                                    className="object-contain w-full h-full rounded-lg dark:!border dark:!border-slate-700"
                                />
                            </div>
                        </div>
                        <div className=" pt-[5px] text-lg font-medium">
                            <p className=" text-lg text-slate-500">
                                <span className="text-lg md:!text-xl font-bold text-slate-800">
                                    14,90 €
                                </span>
                                / {translate("PRICES_PAGE", "Month")}
                            </p>
                            <p className="text-sm font-normal pt-[5px]">
                                <span className="font-bold text-green-600">
                                    {translate("PRICES_PAGE", "2_Months_Free")}
                                </span>{" "}
                                {translate("PRICES_PAGE", "By_Opting")}
                            </p>
                            <p className="text-sm font-normal pb-[5px]">
                                {translate(
                                    "PRICES_PAGE",
                                    "For_Annual_Subscription",
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="hidden md:block pt-[10px] pb-[0px] text-left text-base md:!text-lg font-bold md:!px-[50px] px-[40px] ">
                        <div className="mb-3">
                            <CreditCardSvg />
                            {translate(
                                "PRICES_PAGE",
                                "Black_Bank_Card_And_IBAN",
                            )}
                        </div>
                        <p className="text-left text-base font-normal">
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate(
                                    "PRICES_PAGE",
                                    "CB_Limits_Than_Gold",
                                )}
                            </div>
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Reductions_On")}
                            </div>
                            {/*<div className="mb-3">
                                <CircleCheckSvg />
                                {translate(
                                    "PRICES_PAGE",
                                    "Discounts_On_Purchases",
                                )}
                            </div>*/}
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Cashback_On")}
                            </div>
                            <div className="mb-1">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Cashback_On_CB")}
                            </div>
                        </p>
                    </div>
                    <div className="sm:mt-0 md:!mt-[5px] mb-[0px] tracking-wide text-center">
                        <NavLink
                            to={`${lang ? `/${lang}` : ""}/register`}
                            className="btn btn-perfect text-center text-sm md:!text-base px-10 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6"
                        >
                            {translate("PRICES_PAGE", "Try_Infinexia_Black")}
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndividualPriceCards;
