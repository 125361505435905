import React, { useContext, useEffect, useState } from "react";
import SettingService from "../../Services/Setting/SettingService";

type BaseCurrencyTypeProps = {
    base: {
        symbol: string;
        unit: number;
        currency: string;
    };
    refreshBaseCurrency: () => void;
};

const BaseCurrencyContext = React.createContext<BaseCurrencyTypeProps>({
    base: {
        symbol: "€",
        unit: 0,
        currency: "EUR",
    },
    refreshBaseCurrency: () => {
        console.log("Refreshing");
    },
});

export default function BaseCurrencyProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [base, setBase] = useState({
        symbol: "€",
        unit: 0,
        currency: "EUR",
    });

    const refreshBaseCurrency = async () => {
        SettingService.getBaseCurrency().then((data) => {
            setBase({
                symbol: data.symbol,
                unit: data.unit,
                currency: data.currency,
            });
        });
    };

    useEffect(() => {
        refreshBaseCurrency();
    }, []);

    return (
        <BaseCurrencyContext.Provider
            value={{
                base,
                refreshBaseCurrency,
            }}
        >
            {children}
        </BaseCurrencyContext.Provider>
    );
}

export const useBaseCurrency = () => useContext(BaseCurrencyContext);
