import UserCard, {
    CARD_TYPE,
    CardTransactionPayload,
    ICardRequestPayload,
} from "../../Models/UserCard";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const map = (item: any): UserCard => {
    const card = new UserCard(
        item.card_type,
        item.status,
        item.last_4_digits,
        item.last_balance,
        item.last_name,
        item.first_name,
        item.logo,
    );

    return card;
};

const getUserCard = async (): Promise<Array<UserCard>> => {
    return axios
        .get(`/user-card`)
        .then(({ data }) => {
            const user_cards: UserCard[] = data.map((item: any) => map(item));
            return user_cards;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserCardActive = async (): Promise<Array<UserCard>> => {
    return axios
        .get(`/user-card/active`)
        .then(({ data }) => {
            const user_cards: UserCard[] = data.map((item: any) => map(item));
            return user_cards;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const userCardList = async (): Promise<Array<any>> => {
    return axios
        .get(`/user-card/user-card-list`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const activateCard = async (payload: ICardRequestPayload): Promise<string> => {
    return axios
        .post(`/user-card/activate`, {
            ...payload,
        })
        .then(({ data }) => {
            if (data) {
                return Promise.resolve("Activation avec succès");
            }
            return Promise.resolve("Activation echoué");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const blockCard = async (payload: ICardRequestPayload): Promise<string> => {
    return axios
        .post(`/user-card/block`, {
            ...payload,
        })
        .then(({ data }) => {
            if (data) {
                return Promise.resolve("Bloquage avec succès");
            }
            return Promise.resolve("Bloquage echoué");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const disableCard = async (payload: ICardRequestPayload): Promise<string> => {
    return axios
        .post(`/user-card/disable`, {
            ...payload,
        })
        .then(({ data }) => {
            if (data) {
                return Promise.resolve("Désactivation avec succès");
            }
            return Promise.resolve("Désactivation echoué");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deposit = async (payload: CardTransactionPayload): Promise<string> => {
    return axios
        .post(`/card-request/deposit`, {
            ...payload,
        })
        .then(({ data }) => {
            if (data) {
                return Promise.resolve(
                    "Transfert vers compte carte fait avec succes",
                );
            }
            return Promise.resolve("Dépôt echoué");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const debit = async (payload: CardTransactionPayload): Promise<string> => {
    return axios
        .post(`/card-request/debit`, {
            ...payload,
        })
        .then(({ data }) => {
            if (data) {
                return Promise.resolve(
                    "Transfert vers compte Fiat fait avec succes",
                );
            }
            return Promise.resolve("Retrait echoué");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const findCardType = (type: number) => {
    switch (type) {
        case 1:
            return CARD_TYPE.BLUE;
            break;

        case 2:
            return CARD_TYPE.GOLD;
            break;

        case 3:
            return CARD_TYPE.BLACK;
            break;
        default:
            return CARD_TYPE.BLUE;
    }
};

const UserCardService = {
    getUserCard,
    activateCard,
    blockCard,
    disableCard,
    findCardType,
    deposit,
    debit,
    getUserCardActive,
    userCardList,
};

export default UserCardService;
