import React, { useEffect, useState } from "react";
import { ModalContext } from "../../Extra/Modal/ModalContext";
import translator from "../../Extra/Translation/Translate";
import PrintHistorique from "./TransanctionHistorique";
import { DatatableFilter } from "../../../Models/DatatableFilter";
import TransactionService from "../../../Services/Wallets/TransactionService";
import Transaction from "../../../Models/Transaction";
import { CsvCont } from "../TransactionsTable";
interface PropsDates {
    dates: any;
}
const Content = ({ dates }: PropsDates) => {
    const [transanctions, setTransanctions] = useState<Array<Transaction>>([]);
    const { translate } = translator();
    const [isFlex, setIsFlex] = useState<boolean>(true);
    const filter: DatatableFilter = {
        page: 0,
        size: 0,
        search: "search",
    };

    if (dates.startDate) {
        filter.start_date = dates.startDate;
    }
    if (dates.endDate) {
        filter.end_date = dates.endDate;
    }
    const getTransactions = async () => {
        try {
            const res =
                await TransactionService.getUserTransactionsWithoutPaginate(
                    filter,
                );
            setTransanctions(res);
        } catch (err: any) {}
    };
    useEffect(() => {
        getTransactions();
    }, [dates.startDate, dates.endDate]);
    return (
        transanctions && (
            <div className="block justify-center items-center ">
                <div className="text-lg text-center font-bold mb-5">
                    {" "}
                    {translate("POPUP_DOWNL", "Download_Docs")}
                </div>
                <div className="p-5 block mt-4 mb-2.5 w-full">
                    <div className="inline-block">
                        <PrintHistorique
                            transactions={transanctions}
                            dates={dates}
                            setIsFlex={setIsFlex}
                        />
                    </div>
                    <div className="flex mt-10">
                        <span className="">
                            {translate("POPUP_DOWNL", "Download_XLSX")}
                        </span>
                        <span className="-mt-[20px]">
                            <CsvCont res_csv={transanctions} />
                        </span>
                    </div>
                </div>
                {/* h-screen */}
            </div>
        )
    );
};
const BtnExportExelPdf = ({ dates }: PropsDates) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    return (
        <button
            type="button"
            className="btn btn-perfect my-1 md-w-full"
            onClick={() => handleModal(<Content dates={dates} />)}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-file-down"
            >
                <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                <polyline points="14 2 14 8 20 8" />
                <path d="M12 18v-6" />
                <path d="m9 15 3 3 3-3" />
            </svg>
        </button>
    );
};

export default BtnExportExelPdf;
