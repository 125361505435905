import React, { useState } from "react";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";
import translator from "../../../Components/Extra/Translation/Translate";
import { useParams } from "react-router-dom";

const BusinessFees = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const [active, setActive] = useState<"blue" | "black" | "gold">("blue");

    const handleButtonClick = (color: "blue" | "gold" | "black") => {
        setActive(color);
    };

    return (
        <div className=" text-lg text-[#333] font-medium break-words  ">
            <HelmetComponent
                title={translate("FEES", "Fees_Limits")}
                description={translate("FEES", "Fees_Limits")}
                link="/business/fees"
                keywords={translate("SEO", "Keys_Fees")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={true}
            ></HelmetComponent>
            <div className=" h-[400px] min-h-[400px] max-h-[400px] bg-black bg-cover bg-no-repeat flex flex-col justify-center items-center text-white">
                <h1 className="pt-10  md:!text-3xl text-2xl font-bold tracking-wide text-center ">
                    {translate("FEES", "Business_Fees_Limits_Annex")}
                </h1>
            </div>
            <div className="pt-5 bg-[#F1F5F9] sticky block  md:!hidden text-sm top-[50px] ... px-2 z-10">
                <div className="w-full bg-[#F1F5F9] rounded-xl grid grid-cols-3 gap-1 p-1">
                    <button
                        className={`px-[16px] py-[8px] ${
                            active === "blue"
                                ? "ifx-bouton-banner rounded-lg text-white"
                                : "text-black"
                        } `}
                        onClick={() => handleButtonClick("blue")}
                    >
                        Blue
                    </button>
                    <button
                        className={`px-[16px] py-[8px] ${
                            active === "gold"
                                ? "ifx-bouton-banner rounded-lg text-white"
                                : "text-black"
                        } `}
                        onClick={() => handleButtonClick("gold")}
                    >
                        Gold
                    </button>
                    <button
                        className={`px-[16px] py-[8px] ${
                            active === "black"
                                ? "ifx-bouton-banner rounded-lg text-white"
                                : "text-black"
                        } `}
                        onClick={() => handleButtonClick("black")}
                    >
                        Black
                    </button>
                </div>
            </div>
            <div id="compare" className="bg-[#191c1e] pt-5">
                <div className="text-lg md:!text-xl text-black -z-40 pb-36 w-full  px-2 md:!px-0 md:!w-[90%] max-w-7xl mx-auto">
                    <div className="text-center pt-[25px] md:!pt-[75px] pb-5 md:!pb-0">
                        <h3 className="md:!text-3xl text-2xl font-bold text-white">
                            {translate("FEES", "Fees_Limits")}
                        </h3>
                    </div>
                    <div className="mt-[25px] px-0 lg:px-24 hidden md:!block">
                        <table className="w-full border-collapse ">
                            <thead>
                                <tr className="text-xl font-bold mb-1">
                                    <th className="w-[70%] py-2 px-2"></th>
                                    <th className="w-[10%] py-2 px-4">Blue</th>
                                    <th className="w-[10%] py-2 px-4">Gold</th>
                                    <th className="w-[10%] py-2 px-4">Black</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div
                        className="bg-white px-0 lg:px-24 pt-2 pb-5 rounded-lg w-full"
                        id="id-comparatif-container"
                    >
                        <table
                            className={`w-full border-collapse ${active}`}
                            id="id-comparatif"
                        >
                            {/* Frais sur les opérations */}
                            <thead>
                                <tr className="bg-white text-left text-lg md:!text-xl font-normal w-full">
                                    <th className="w-auto py-2 pl-4 px-0">
                                        {translate("FEES", "Fees_Opé")}
                                    </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white text-base font-normal ">
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Trans_Intern")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            {translate("PRICES_PAGE", "Free")}
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            {translate("PRICES_PAGE", "Free")}
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            {translate("PRICES_PAGE", "Free")}
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Swap_Fiat")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1%
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            0,75%
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            0,5%
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Swap_Crypto")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            2,5%
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            2%
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1,25%
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Vrt_Inclued")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            5
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            10
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            15
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0 pb-3">
                                        {translate("FEES", "Vrt_Sup")}
                                    </td>
                                    <td className="text-center py-2 px-4 pb-3">
                                        <div className="flex items-center justify-center">
                                            0,35€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4 pb-3">
                                        <div className="flex items-center justify-center">
                                            0,35€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4 pb-3">
                                        <div className="flex items-center justify-center">
                                            0,35€
                                        </div>
                                    </td>
                                </tr>
                                {/* Frais et limites des cartes */}
                                <tr className="bg-white hover:!bg-white border-t-4 border-[#F1F5F9] text-left text-lg md:!text-xl font-normal">
                                    <th className="w-auto py-2 pl-4 px-0 pt-5">
                                        {translate("FEES", "Fees_Limits_Card")}
                                    </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                </tr>
                                <tr className="bg-white mt-[-15px]">
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Monthly_Payment_Limits",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            2 500€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            10 000€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            25 000€
                                        </div>
                                    </td>
                                </tr>
                                <tr className="!hidden bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Monthly_Withdrawal_Limits",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1 000€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            2 500€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            10 000€
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Fees_Change")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            2,5%
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            2,5%
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            2,5%
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Change_Pin")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1€
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Forgot_Pin")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1€
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Virtual_Card_Inc")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Virtual_Card")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            0,30€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            0,30€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            0,30€
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Virtual_Card2")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            0,10€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            0,10€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            0,10€
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* Frais applicables à tous */}
            <div id="compare" className="bg-black pt-5">
                <div className="text-xl text-black -z-40 pb-36 w-full  px-2 md:!px-0 md:!w-[90%] max-w-7xl mx-auto">
                    <div className="text-center pt-[25px] md:!pt-[75px] pb-5 md:!pb-0">
                        <h3 className="md:!text-3xl text-2xl font-bold text-white">
                            {translate("FEES", "Fees_For_All")}
                        </h3>
                    </div>
                    <div
                        className="bg-white mt-5 md:!mt-7 px-0 lg:px-24 pt-2 pb-5 rounded-lg w-full"
                        id="id-comparatif-container"
                    >
                        <table
                            className={`w-full border-collapse`}
                            id="id-comparatif"
                        >
                            {/* Frais passerelles de paiements */}
                            <thead>
                                <tr className="bg-white text-left text-lg md:!text-xl font-normal w-full">
                                    <th className="py-2 pl-4 px-0 w-[80%]">
                                        {translate("FEES", "Fees_Gateway")}
                                    </th>
                                    <th className="w-[20%] py-2 px-4"> </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white text-base font-normal ">
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Fees_CB")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            2,5%
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0 pb-3">
                                        {translate("FEES", "Fees_Payeer")}
                                    </td>
                                    <td className="text-center py-2 px-4 pb-3">
                                        <div className="flex items-center justify-center">
                                            2%
                                        </div>
                                    </td>
                                </tr>
                                {/* Frais d'incidents */}
                                <tr className="bg-white hover:!bg-white border-t-4 border-[#F1F5F9] text-left text-lg md:!text-xl font-normal ">
                                    <th className="py-2 pl-4 px-0 pt-5 w-[80%]">
                                        {translate("FEES", "Fees_Incident")}
                                    </th>
                                    <th className="w-[20%] py-2 px-4"> </th>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Fees_Reject")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            5€
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Fees_Reject20")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            15€
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0">
                                        {translate("FEES", "Fees_Litig_CB")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            30€
                                        </div>
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="py-2 pl-4 px-0 mx-0">
                                        {translate("FEES", "Fees_ATD")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            10%
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="text-white text-base font-normal px-1 md:!px-4 mt-5">
                        <p className="pb-[0px]">
                            {translate("FEES", "Fees_ATD_text")}
                        </p>
                        <p className="pb-[18px]">
                            {translate("FEES", "Fees_ATD_text2")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessFees;
