import React from "react";
import { ItemListProps } from "./Nav/ItemList";

const AccountMenu: ItemListProps[] = [
    {
        name: "Dashboard.Dashboard",
        path: "/dashboard",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="monitor"
                data-lucide="monitor"
                className="lucide lucide-monitor"
            >
                <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                <line x1="8" y1="21" x2="16" y2="21"></line>
                <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
        ),
        childs: [],
    },
    {
        name: "Wallets.My_Wallets",
        path: "/accounts",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-wallet"
            >
                {" "}
                <path d="M21 12V7H5a2 2 0 0 1 0-4h14v4" />{" "}
                <path d="M3 5v14a2 2 0 0 0 2 2h16v-5" />{" "}
                <path d="M18 12a2 2 0 0 0 0 4h4v-4Z" />{" "}
            </svg>
        ),
        childs: [],
    },
    {
        name: "Wallets.My_Transactions",
        path: "/transactions",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-card-list"
                viewBox="0 0 16 16"
            >
                {" "}
                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />{" "}
                <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />{" "}
            </svg>
        ),
        childs: [],
    },
    {
        name: "Switch_join_account.Switch_join_account",
        path: "/switch-join-account",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.65"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="users-cptjoint"
                shape-rendering="geometricPrecision"
                text-rendering="geometricPrecision"
            >
                <path
                    d="M14,19c0-3.313708-2.686292-6-6-6s-6,2.686292-6,6"
                    transform="translate(-1.277267 0)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.65"
                />
                <circle
                    r="4"
                    transform="translate(6.722733 9)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.65"
                />
                <circle
                    r="4"
                    transform="translate(17.362118 9)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.65"
                />
                <path
                    d="M14,19c0-3.313708-2.881739-6.03602-6-6s-5.277267,1.890939-5.277267,3.460676"
                    transform="translate(9.277267 0.000346)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.65"
                />
            </svg>
        ),
        childs: [],
    },
    {
        name: "Account18.Account18",
        path: "/child-accounts",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="users-child"
                className="user-child mr-[-4px]"
            >
                <path
                    d="M14,19c0-3.313708-2.686292-6-6-6s-6,2.686292-6,6"
                    transform="translate(0 0.042425)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <circle
                    r="3.99805"
                    transform="matrix(.92708 0 0 0.9333 7.99805 8.99805)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14,18.241827c0-2.894472-2.856078-5.226202-6-5.226202s-6,2.33173-6,5.226202"
                    transform="matrix(.522982 0 0 0.755807 13.5316 5.40971)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.90"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <circle
                    r="4"
                    transform="matrix(.620043 0 0 0.603823 17.715456 12.72943)"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.90"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
        childs: [],
    },
    {
        name: "Switch_join_account.Switch_origin_account",
        path: "/switch-origin-account",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2.50"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-user-2 mx-auto"
            >
                <circle cx="12" cy="8" r="5" />
                <path d="M20 21a8 8 0 1 0-16 0" />
            </svg>
        ),
        childs: [],
    },

    // {
    //     name: "Earning",
    //     path: "/earning",
    //     icon: <RankSvg />,
    //     childs: [],
    // },
    // {
    //     name: "Orders.Orders",
    //     path: "/orders",
    //     icon: (
    //         <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             stroke="currentColor"
    //             strokeWidth="2"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             icon-name="history"
    //             data-lucide="history"
    //             className="lucide lucide-history"
    //         >
    //             <path d="M3 3v5h5"></path>
    //             <path d="M3.05 13A9 9 0 1 0 6 5.3L3 8"></path>
    //             <path d="M12 7v5l4 2"></path>
    //         </svg>
    //     ),
    //     childs: [],
    // },
    // {
    //     name: "Admin_Affiliation.Loyalty",
    //     path: "/loyalty",
    //     icon: <PachBoosterSvg />,
    //     childs: [],
    // },
    // {
    // name: "Profil.Setting_Security",
    // path: "/settings/details-setting",
    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="24"
    //         height="24"
    //         viewBox="0 0 24 24"
    //         fill="none"
    //         stroke="currentColor"
    //         strokeWidth="2"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         icon-name="settings"
    //         data-lucide="settings"
    //         className="lucide lucide-settings"
    //     >
    //         <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
    //         <circle cx="12" cy="12" r="3"></circle>
    //     </svg>
    // ),
    // childs: [],
    // {
    //     name: "Settings.Profil",
    //     path: "/settings/profile/detail",
    //     icon: (
    //         <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             stroke="currentColor"
    //             strokeWidth="2"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             icon-name="user"
    //             data-lucide="user"
    //             className="lucide lucide-user"
    //         >
    //             <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path>
    //             <circle cx="12" cy="7" r="4"></circle>
    //         </svg>
    //     ),
    //     childs: [],
    // },
    // {
    //     name: "Settings.Edit_Profil",
    //     path: "/settings/profile/edit",
    //     icon: <Edit3Svg />,
    //     childs: [],
    // },
    // {
    //     name: "Settings.Security",
    //     path: "/settings/profile/security",
    //     icon: <LockSmSvg />,
    //     childs: [],
    // },
    // {
    //     name: "Settings.Affiliation",
    //     path: "/settings/profile/affiliate",
    //     icon: <UsersSvg />,
    //     childs: [],
    // },
    // {
    //     name: "Écosystème",
    //     path: "/ecosystem",
    //     icon: (
    //         <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             stroke="currentColor"
    //             strokeWidth="2"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             icon-name="globe"
    //             data-lucide="globe"
    //             className="lucide lucide-globe-2-ccw"
    //         >
    //             <path d="M21.54 15H17a2 2 0 0 0-2 2v4.54"></path>
    //             <path d="M7 3.34V5a3 3 0 0 0 3 3v0a2 2 0 0 1 2 2v0c0 1.1.9 2 2 2v0a2 2 0 0 0 2-2v0c0-1.1.9-2 2-2h3.17"></path>
    //             <path d="M11 21.95V18a2 2 0 0 0-2-2v0a2 2 0 0 1-2-2v-1a2 2 0 0 0-2-2H2.05"></path>
    //             <circle cx="12" cy="12" r="10"></circle>
    //         </svg>
    //     ),
    //     childs: [],
    // },
    // {
    //     name: "Settings.Security",
    //     path: "/settings/details-security",
    //     icon: (
    //         <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             stroke="currentColor"
    //             strokeWidth="2"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             icon-name="settings"
    //             data-lucide="settings"
    //             className="lucide lucide-settings"
    //         >
    //             <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
    //             <circle cx="12" cy="12" r="3"></circle>
    //         </svg>
    //     ),
    //     childs: [],
    // },
    // {
    //     name: "Settings.Settings",
    //     path: "/settings/details-setting",
    //     icon: (
    //         <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="20"
    //             height="20"
    //             fill="currentColor"
    //             className="bi bi-lock"
    //             viewBox="0 0 16 16"
    //         >
    //             {" "}
    //             <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />{" "}
    //         </svg>
    //     ),
    //     childs: [],
    // },
    // ],
    // },
];
export default AccountMenu;
