export default class PnlModel {
    available: number;
    blocked: number;
    solde_fiat: number;
    solde_crypto: number;
    withdrawn: number;
    solde_principal: number;
    solde_coffre: number;
    solde_cagnotte: number;
    data?: any[];
    pnls?: any;

    constructor(
        available: number,
        blocked: number,
        solde_fiat: number,
        solde_crypto: number,
        withdrawn: number,
        solde_principal: number,
        solde_coffre: number,
        solde_cagnotte: number,
        data: any[],
        pnls: any,
    ) {
        this.available = available;
        this.blocked = blocked;
        this.solde_fiat = solde_fiat;
        this.solde_crypto = solde_crypto;
        this.withdrawn = withdrawn;
        this.solde_principal = solde_principal;
        this.solde_coffre = solde_coffre;
        this.solde_cagnotte = solde_cagnotte;
        this.data = data;
        this.pnls = pnls;
    }
}

export interface TermModel {
    daily: Array<any>;
    weekly: Array<any>;
    yearly: Array<any>;
    monthly: Array<any>;
}

export interface PnlAnalysisModel {
    logo: string;
    abbreviation: string;
    solde: number;
    withdraw_swap: number;
    invested: number;
    user_wallet_id: number;
}

export interface ActifRepartition {
    earning: number;
    crypto: number;
    coffre: number;
    cagnotte: number;
    fiat: number;
}
