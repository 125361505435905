import { IAmbassador } from "../../Models/User";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const create = async (payload: IAmbassador): Promise<string> => {
    return axios
        .post("/auth/ambassador-request", {
            ...payload,
        })
        .then(() => {
            return Promise.resolve("Demande envoyé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const AmbassadorService = {
    create,
};

export default AmbassadorService;
