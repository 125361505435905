import React from "react";
import { Outlet } from "react-router";
import Head from "./Head";
import Side from "./Side";
import SideMobile from "./SideMobile";
import LoadingBar from "react-top-loading-bar";
import ExportPdfProvider from "../../App/ExportPdfProvider3";
import { Account18Provider } from "../../Components/Account18/Account18Provider";
import PhoneProvider from "../../App/Provider/PhoneProvider";
import JoinAccountProvider from "../../App/Provider/JoinAccountProvider";
import { FiatProvider } from "../../App/Provider/FiatProvider";

import "../../App/container-dash.css";
import { FlyoutProvider } from "../../App/Provider/FlyOutProvider";
import AssetsProvider from "../../App/Provider/AssetsProvider";
import { useLocation } from "react-router-dom";
import CardProvider from "../../App/Provider/CardProvider";

const Base = () => {
    const location = useLocation();

    const { pathname } = location;

    return (
        <AssetsProvider>
            <Account18Provider>
                <PhoneProvider>
                    <JoinAccountProvider>
                        <ExportPdfProvider>
                            <FiatProvider>
                                <FlyoutProvider>
                                    <div className="h-[100dvh] overflow-hidden">
                                        <LoadingBar
                                            color="radial-gradient(circle at center,#3695D2 0%,#303B8E 100%)"
                                            progress={0}
                                        />
                                        <SideMobile />
                                        <Head />
                                        <div className="mdg-section flex md:mt-0">
                                            <Side />
                                            <div
                                                className={`content mdg-content md:h-screen sm:h-[calc(100dvh-125px)] overflow-y-scroll overflow-x-hidden w-content  !rounded-none ${
                                                    pathname == "/my-cards"
                                                        ? "!p-0"
                                                        : ""
                                                }`}
                                                id="app-content"
                                            >
                                                <CardProvider>
                                                    <Outlet />
                                                </CardProvider>
                                            </div>
                                        </div>
                                    </div>
                                </FlyoutProvider>
                            </FiatProvider>
                        </ExportPdfProvider>
                    </JoinAccountProvider>
                </PhoneProvider>
            </Account18Provider>
        </AssetsProvider>
    );
};

export default Base;
