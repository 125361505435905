import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

import { useAppSelector } from "../../../globalState/hook";
import { CustomMessage } from "../../../Models/Constants";
import alert_message from "../../../Services/Alert/AlertMessage";
import AppAuthentication from "../../../Services/Authentication/AppAuthentication";

import LockSvg from "../../Extra/Svg/LockSvg";
import translator from "../../Extra/Translation/Translate";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const fixFocus = () => {
    document.getElementById("password-input")?.focus();
    document.getElementById("username-input")?.focus();
    const usernameInput = document.getElementById(
        "username-input",
    ) as HTMLInputElement | null;
    const passwordInput = document.getElementById(
        "password-input",
    ) as HTMLInputElement | null;
    if (usernameInput && usernameInput.value.length === 0) {
        usernameInput.blur();
    }
    if (passwordInput && passwordInput.value.length === 0) {
        passwordInput.blur();
    }
};

const AuthComponent = () => {
    const navigate = useNavigate();
    const { authLoading } = useAppSelector((store) => store.auth);
    const { siginAsync } = AppAuthentication();
    const [userName, setUserName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<number>(0);
    const [message, setMessage] = useState<CustomMessage | null>();
    const [token, setToken] = useState<string | null>(null);
    const { translate } = translator();

    const onVerify = useCallback((token: any) => {
        setToken(token);
    }, []);

    const [rememberMe, setRememberMe] = useState(true);

    const handleConnexion = async (e: any) => {
        e.preventDefault();
        if (token || true) {
            try {
                const promise = siginAsync(
                    userName,
                    password,
                    token || "",
                    translate,
                    rememberMe,
                );
                alert_message.promise(
                    `${translate("Authentication", "Sign_in")}`,
                    promise,
                );
            } catch (error: any) {}
        } else {
            const recaptcha_msg: CustomMessage = {
                type: "error",
                message: "Please verify that you are not a robot.",
            };

            setMessage(recaptcha_msg);
        }
        setToken(null);
        setRefreshReCaptcha(refreshReCaptcha + 1);
    };

    useEffect(() => {
        setTimeout(function () {
            setRefreshReCaptcha(refreshReCaptcha + 1);
        }, 500);
    }, []);

    useEffect(() => {
        if (token) setMessage(null);
    }, [token]);

    useEffect(() => {
        setTimeout(() => {
            fixFocus();
        }, 1000);
    }, []);

    return (
        <>
            <div className="m-auto vl-mob w-full rounded-md sm:block md:flex lg:flex xl:flex 2xl:flex justify-center bg-white-log px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:bg-transparent xl:p-0 xl:shadow-none mdg-signup-box">
                <form onSubmit={(e) => handleConnexion(e)}>
                    <div className="relative">
                        <div className="intro-x flex justify-center">
                            {translate("SignIn", "Please_make_official_site")}
                        </div>
                        <div className="intro-x w-full mb-4 text-sm flex justify-center">
                            <span className="flex items-center justify-center to-bg-slate-200 rounded sm:w-[100%] lg:w-[55%] xl:w-[55%] 2xl:w-[55%] font-medium">
                                <LockSvg />
                                <span className="mt-1">
                                    <span className="text-[#00AA4D]">
                                        https://
                                    </span>
                                    infinexia.com
                                </span>
                            </span>
                        </div>
                        <h2 className="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl mt-8">
                            {translate("SignIn", "Sign_in")}
                        </h2>
                        <div className="intro-x mt-8">
                            <div className="relative">
                                <input
                                    type="email"
                                    id="username-input"
                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0 no-arrows"
                                    placeholder=""
                                    required
                                    value={userName}
                                    onChange={(e) =>
                                        setUserName(e.target.value)
                                    }
                                />
                                <label
                                    htmlFor="username-input"
                                    className="!peer-focus:text-[#0f0f0f] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    {translate("SignIn", "Email")}
                                </label>
                            </div>
                            <div className="relative mt-4">
                                <input
                                    type="password"
                                    id="password-input"
                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0 no-arrows"
                                    placeholder=""
                                    required
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <label
                                    htmlFor="password-input"
                                    className="!peer-focus:text-[#0f0f0f] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    {translate("SignIn", "Password")}
                                </label>
                            </div>
                        </div>
                        <div className="intro-x mt-4 flex text-xs sm:text-sm">
                            <div className="mr-auto flex items-center">
                                <input
                                    id="remember-me"
                                    type="checkbox"
                                    className="form-check-input mr-2 border"
                                    checked={rememberMe}
                                    onChange={(e) =>
                                        setRememberMe(e.target.checked)
                                    }
                                />
                                <label
                                    htmlFor="remember-me"
                                    className="cursor-pointer select-none"
                                >
                                    {translate("SignIn", "Remember_me")}
                                </label>
                            </div>
                            <NavLink
                                to="/forgot-password"
                                className="md-ml-135"
                            >
                                {translate("SignIn", "Forgot_Password")}
                            </NavLink>
                        </div>
                        <div className="intro-x flex justify-center text-center xl:text-left">
                            <GoogleReCaptcha
                                onVerify={onVerify}
                                refreshReCaptcha={refreshReCaptcha}
                            />
                        </div>
                        <div className="intro-x h-5 flex justify-center text-center xl:text-left">
                            <div
                                className={
                                    token ? "text-green-600" : "text-slate-500"
                                }
                            >
                                {token ? (
                                    <></>
                                ) : (
                                    <>
                                        {translate("RECAPTCHA", "VERIFICATION")}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="intro-x flex justify-center text-center xl:text-left">
                            {message && !token && (
                                <div
                                    className={
                                        message.type === "success"
                                            ? "text-green-600"
                                            : "text-red-500"
                                    }
                                >
                                    {message.message}
                                </div>
                            )}
                        </div>
                        <div className="intro-x mt-2 xl:flex xl:justify-center gap-3 lg:flex lg:justify-center text-center xl:text-left block h-[auto]">
                            <button
                                className="btn btn-perfect mt-3 w-56 border-[3px] border-[3px] border-[#3695D2] h-[40px]"
                                type="submit"
                                disabled={authLoading}
                            >
                                {translate("SignIn", "Sign_in")}
                            </button>
                            <button
                                className="btn bg-transparent mt-3 w-56 text-black border-2 hover:!border-[3px] border-[#3695D2]  h-[40px] dark:!text-white dark:!border-2 dark:!border-slate-600 dark:!rounded-lg"
                                onClick={() => navigate("/register")}
                            >
                                {authLoading
                                    ? translate("SignIn", "Connecting")
                                    : translate("SignIn", "Register")}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {/* <GoogleAuthButton /> */}
        </>
    );
};
export default AuthComponent;
