import User from "./User";
export default class UserAssetCoOwner {
    id?: number;
    user?: User;
    user_asset?: any;
    credit?: boolean;
    debit?: boolean;
}

export interface CoOwnerRequest {
    id: number;
    asset: {
        type: "jackpot" | "piggy_bank";
        description: string;
        name: string;
    };
    owner: {
        uid: string;
        firstName: string;
        lastName: string;
    };
}
