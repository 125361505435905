import React from "react";
import DocHeader from "./DocHeader";
import Illustration from "../assets/images/hero-illustration.svg";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import HelmetComponent from "../../Admin/Layout/HelmetComponent/HelmetComponent";
import translator from "../../Components/Extra/Translation/Translate";

const DocBase = () => {
    const { translate } = translator();
    return (
        <div className="font-aspekta antialiased text-slate-800 font-[350] bg-white dark:bg-slate-900 dark:text-slate-200">
            <div className="flex flex-col min-h-screen overflow-hidden">
                <DocHeader />
                <HelmetComponent
                    title={translate("SEO", "Title_DocAPI")}
                    description={translate("SEO", "Desc_DocAPI")}
                    link="/documentation/introduction"
                    keywords={translate("SEO", "Keys_DocAPI")}
                    imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                    largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                    addPostfixTitle={true}
                    noIndex={true}
                ></HelmetComponent>
                {/*  Page content */}
                <main className="grow">
                    <section className="relative">
                        <div className="absolute top-0 left-1/2 -translate-x-1/2 pointer-events-none -z-10">
                            <img
                                className="max-w-none"
                                src={Illustration}
                                alt="Page illustration"
                                aria-hidden="true"
                            />
                        </div>

                        <div className="max-w-7xl mx-auto px-4 sm:px-6 relative">
                            {/* Main content */}
                            <div>
                                {/* Sidebar */}
                                <Sidebar />

                                {/* Page container */}
                                <div className="md:grow md:pl-64 lg:pr-6 xl:pr-0">
                                    <div className="pt-24 md:pt-28 pb-8 md:pl-6 lg:pl-12">
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
};

export default DocBase;
