import Governance, { IGovernance } from "../../Models/Governance";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const map = (item: any): Governance => {
    const notice = new Governance(
        item.title,
        item.subtitle,
        item.description,
        item.status,
        item.default_no,
        item.default_yes,
        item.lang,
    );

    notice.title_en = item.title_en;
    notice.subtitle_en = item.subtitle_en;
    notice.description_en = item.description_en;
    notice.title_es = item.title_es;
    notice.subtitle_es = item.subtitle_es;
    notice.description_es = item.description_es;
    notice.description = item.description;
    notice.status = item.status;
    notice.created_at = item.created_at;
    notice.updated_at = item.updated_at;
    notice.start_date = item.start_date;
    notice.end_date = item.end_date;
    notice.vote = item.vote;
    notice.rank = item.rank;
    notice.photo = UtilsService.getPhotoUrl(item.photo);
    notice.id = item.id;
    return notice;
};

const createFormData = (payload: IGovernance): FormData => {
    const formData = new FormData();
    formData.append("title", payload.title);
    formData.append("subtitle", payload.subtitle);
    formData.append("description", payload.description);
    formData.append("default_no", "" + payload.default_no);
    formData.append("default_yes", "" + payload.default_yes);
    formData.append("lang", "" + payload.lang);
    if (payload.start_date) formData.append("start_date", payload.start_date);
    if (payload.end_date) formData.append("end_date", payload.end_date);

    if (payload.title_en) formData.append("title_en", payload.title_en);
    if (payload.subtitle_en)
        formData.append("subtitle_en", payload.subtitle_en);
    if (payload.description_en)
        formData.append("description_en", payload.description_en);

    if (payload.title_es) formData.append("title_es", payload.title_es);
    if (payload.subtitle_es)
        formData.append("subtitle_es", payload.subtitle_es);
    if (payload.description_es)
        formData.append("description_es", payload.description_es);

    if (payload.status) {
        formData.append("status", "1");
    }
    if (payload.photo) {
        formData.append("photo", payload.photo);
    }

    return formData;
};

const getAll = async (): Promise<Array<Governance>> => {
    return axios
        .get(`/governance`)
        .then(({ data }) => {
            const assets: Governance[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const orderList = async (payload: string) => {
    try {
        const result = await axios.put(`/governance/order-list`, {
            payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const getAllActive = async (): Promise<Array<Governance>> => {
    return axios
        .get(`/governance/active`)
        .then(({ data }) => {
            const assets: Governance[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getById = async (id: number): Promise<Governance> => {
    return axios
        .get(`/governance/${id}`)
        .then(({ data }) => {
            const asset: Governance = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteById = async (support_id?: number) => {
    return axios
        .delete(`/governance/${support_id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const create = async (payload: IGovernance): Promise<Governance | string> => {
    const formData = createFormData(payload);

    return axios
        .post("/governance", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Information créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const update = async (id: number, payload: IGovernance): Promise<string> => {
    const formData = createFormData(payload);

    return axios
        .put(`/governance/${id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const GovernanceService = {
    getAll,
    deleteById,
    getById,
    create,
    update,
    getAllActive,
    orderList,
};

export default GovernanceService;
