import React, { createContext, useContext, useEffect, useState } from "react";
import alert_message from "../../Services/Alert/AlertMessage";
import { useAppProvider } from "./AppProvider";
import PartenarUser from "../../Models/UserPartenar";
import UserService from "../../Services/User/UserService";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import { UserType } from "../../Models/User";

interface ContextProps {
    joinParents: PartenarUser[];
}

const JoinAccountContext = createContext<ContextProps>({
    joinParents: [],
});

export default function JoinAccountProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [joinParents, setJoinParents] = useState<Array<PartenarUser>>([]);
    const { setProcessing, finalizeProcessing } = useAppProvider();
    const { user } = useAuthentication();

    const getJoinParents = async () => {
        setProcessing(true);

        try {
            const res = await UserService.getJoinParents();
            setJoinParents(res);

            if (res) {
                setJoinParents(res);
            }
        } catch (err: any) {
            alert_message.error(err);
        }
        finalizeProcessing();
    };

    useEffect(() => {
        if (user?.type === UserType.JOIN) getJoinParents();
    }, [user]);

    return (
        <JoinAccountContext.Provider
            value={{
                joinParents,
            }}
        >
            {children}
        </JoinAccountContext.Provider>
    );
}

export const useJoinAccount = () => useContext(JoinAccountContext);
