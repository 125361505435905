import React, { useState } from "react";
import FilterAZsvg from "../Svg/FilterAZsvg";
import FilterZAsvg from "../Svg/FilterZAsvg";

interface SwitchProps {
    value: boolean;
    changeValue?: (state: boolean) => void;
}

const Filter = ({ value, changeValue }: SwitchProps) => {
    return (
        <div className="flex">
            <button
                type="button"
                className="border-none bg-transparent hover:border-none hover:bg-transparent"
                onClick={() => {
                    if (changeValue) {
                        changeValue(!value);
                    }
                }}
            >
                {!value ? <FilterAZsvg /> : <FilterZAsvg />}
            </button>
        </div>
    );
};

export default Filter;

export const FilterColumn = ({ value, changeValue }: SwitchProps) => {
    const [direction, setDirection] = useState(value);
    return (
        <div className="flex">
            <button
                type="button"
                className="border-none bg-transparent hover:border-none hover:bg-transparent"
                onClick={() => {
                    if (changeValue) {
                        changeValue(!direction);
                        setDirection(!direction);
                    }
                }}
            >
                {!direction ? <FilterAZsvg /> : <FilterZAsvg />}
            </button>
        </div>
    );
};
