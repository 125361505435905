import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";

const PnDateRangePicker = ({
    setDates,
    dates,
}: {
    setDates: (values: any) => void;
    dates: any;
}) => {
    const [value, setValue] = useState({
        startDate: null,
        endDate: null,
    });

    const handleValueChange = (newValue: any) => {
        setValue(newValue);
        setDates({
            endDate: newValue.endDate,
            startDate: newValue.startDate,
        });
    };

    return (
        <>
            <div className="test flex items-center sm:w-[90%] md:w-56 lg:w-56 xl:w-56 2xl:w-56">
                <Datepicker value={value} onChange={handleValueChange} />
            </div>
        </>
    );
};

export default PnDateRangePicker;
