import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ChevronDownSvg from "../../../Components/Extra/Svg/ChevronDownSvg";
import translator from "../../../Components/Extra/Translation/Translate";
import User, { UserType } from "../../../Models/User";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import AppAuthentication from "../../../Services/Authentication/AppAuthentication";
import alert_message from "../../../Services/Alert/AlertMessage";

export type ItemListProps = {
    name: string;
    icon: any;
    path: string;
    childs: ItemListProps[];
    user?: User;
};

const ItemListMobile = ({ name, icon, path, childs }: ItemListProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { translate } = translator();
    const { user } = useAuthentication();
    const { switchToJoinAccount, switchToOriginAccount } = AppAuthentication();

    const toggleExanded = (e: any) => {
        if (childs && childs.length > 0) {
            // setExpanded((expanded) => !expanded);
            e.preventDefault();
            return false;
        }
    };

    if (path === "/child-accounts" && !user?.is_parent) return null;
    if (path === "/switch-join-account" && !user?.join_account) return null;
    if (path === "/switch-origin-account" && user?.type !== UserType.JOIN)
        return null;

    return (
        <>
            <li>
                {["/switch-join-account"].includes(path) && (
                    <NavLink
                        to={`javascript:void(0)`}
                        className={(navData) =>
                            navData.isActive
                                ? expanded
                                    ? `menu menu--active cursor-default ${
                                          !childs || childs.length === 0
                                              ? "single-menu"
                                              : ""
                                      }`
                                    : `menu menu--active cursor-default ${
                                          !childs || childs.length === 0
                                              ? "single-menu"
                                              : ""
                                      }`
                                : `menu cursor-default ${
                                      !childs || childs.length === 0
                                          ? "single-menu"
                                          : ""
                                  }`
                        }
                        onClick={async (e) => {
                            e.preventDefault();
                            const promise = switchToJoinAccount(translate);
                            alert_message.promise(
                                `${translate(
                                    "APP",
                                    "switch_to_join_account_msg",
                                )}`,
                                promise,
                            );
                        }}
                    >
                        <div className="menu__icon text-white">{icon}</div>
                        <div className="menu__title text-white">
                            {translate(".", name)}
                            {childs && childs.length > 0 && <ChevronDownSvg />}
                        </div>
                    </NavLink>
                )}
                {["/switch-origin-account"].includes(path) && (
                    <NavLink
                        to={`javascript:void(0)`}
                        className={(navData) =>
                            navData.isActive
                                ? expanded
                                    ? `menu menu--active cursor-default ${
                                          !childs || childs.length === 0
                                              ? "single-menu"
                                              : ""
                                      }`
                                    : `menu menu--active cursor-default ${
                                          !childs || childs.length === 0
                                              ? "single-menu"
                                              : ""
                                      }`
                                : `menu cursor-default ${
                                      !childs || childs.length === 0
                                          ? "single-menu"
                                          : ""
                                  }`
                        }
                        onClick={async (e) => {
                            e.preventDefault();
                            const promise = switchToOriginAccount(translate);
                            alert_message.promise(
                                `${translate(
                                    "APP",
                                    "switch_to_real_account_msg",
                                )}`,
                                promise,
                            );
                        }}
                    >
                        <div className="menu__icon text-white">{icon}</div>
                        <div className="menu__title text-white">
                            {translate(".", name)}
                            {childs && childs.length > 0 && <ChevronDownSvg />}
                        </div>
                    </NavLink>
                )}
                {!["/switch-join-account"].includes(path) &&
                    !["/switch-origin-account"].includes(path) && (
                        <>
                            <NavLink
                                to={
                                    childs && childs.length > 0
                                        ? `javascript:void(0)`
                                        : path
                                }
                                className={(navData) =>
                                    navData.isActive
                                        ? expanded
                                            ? `menu menu--active cursor-default ${
                                                  !childs || childs.length === 0
                                                      ? "single-menu"
                                                      : ""
                                              }`
                                            : `menu menu--active cursor-default ${
                                                  !childs || childs.length === 0
                                                      ? "single-menu"
                                                      : ""
                                              }`
                                        : `menu cursor-default ${
                                              !childs || childs.length === 0
                                                  ? "single-menu"
                                                  : ""
                                          }`
                                }
                                onClick={(e) => {
                                    toggleExanded(e);
                                }}
                            >
                                <div className="menu__icon text-white">
                                    {icon}
                                </div>
                                <div className="menu__title text-white">
                                    {translate(".", name)}
                                    {childs && childs.length > 0 && (
                                        <ChevronDownSvg />
                                    )}
                                </div>
                            </NavLink>
                            {childs && childs.length > 0 && (
                                <ul
                                    className={expanded ? "menu__sub-open" : ""}
                                >
                                    {childs.map((child, i) => (
                                        <ItemListMobile
                                            key={i}
                                            name={child.name}
                                            icon={child.icon}
                                            path={child.path}
                                            childs={child.childs}
                                        ></ItemListMobile>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
            </li>
        </>
    );
};

export default ItemListMobile;
