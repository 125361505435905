import React, { createContext, useContext, useEffect, useState } from "react";

interface AddAccountsContextProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    // type: "bank" | "jackpot" | "joint" | "infinexia_18";
    // setType: (open: boolean) => void;
}

const AddAccountsContext = createContext<AddAccountsContextProps | undefined>(
    undefined,
);

export const AddAccountsProvider = ({
    children,
    initialState = false,
}: {
    children: React.ReactNode;
    initialState?: boolean;
}) => {
    const [open, setOpen] = useState<boolean>(initialState);

    useEffect(() => {
        const doc = document.getElementById("mobile-header");
        if (doc) {
            if (open) {
                doc.classList.add("scrolling3");
                if (doc && !doc.classList.contains("scrolling2")) {
                    doc.classList.add("scrolling2");
                    doc.classList.add("via-flyout");
                }
            } else {
                if (doc && doc.classList.contains("via-flyout")) {
                    doc.classList.remove("scrolling2");
                    doc.classList.remove("via-flyout");
                }
                doc.classList.remove("scrolling3");
            }
        }
    }, [open]);

    return (
        <AddAccountsContext.Provider value={{ open, setOpen }}>
            {children}
        </AddAccountsContext.Provider>
    );
};

export const useAddAccountsContext = () => {
    const context = useContext(AddAccountsContext);
    if (!context) {
        throw new Error("useFlyout must be used within a FlyoutProvider");
    }
    return context;
};
