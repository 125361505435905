import React from "react";

const AffiliationSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 256 256"
            stroke-width="13"
            stroke-linecap="round"
            strokeLinejoin="round"
            icon-name="referral"
            className="referral"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
        >
            <rect width="256" height="256" rx="0" ry="0" fill="none"></rect>
            <g transform="matrix(1.245876 0 0 1.229136-31.472128-33.070446)"></g>
            <circle
                r="40"
                transform="translate(128 140)"
                fill="none"
                stroke="currentColor"
                stroke-width="13"
                stroke-linecap="round"
                strokeLinejoin="round"
            ></circle>
            <path
                d="M196,116c18.892886-.031607,36.689554,8.866728,48,24"
                transform="translate(-22.955626 20.656283)"
                fill="none"
                stroke="currentColor"
                stroke-width="13"
                stroke-linecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M12,140c11.310446-15.133272,29.107114-24.031607,48-24"
                transform="translate(22.955625 20.656283)"
                fill="none"
                stroke="currentColor"
                stroke-width="13"
                stroke-linecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M70.4,216c10.748603-22.01266,33.103272-35.974389,57.6-35.974389s46.851397,13.961729,57.6,35.974389"
                fill="none"
                stroke="currentColor"
                stroke-width="13"
                stroke-linecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M60,116c-16.896593.017142-30.894838-13.1053-31.967871-29.967796s11.148961-31.653444,27.911439-33.778174s32.28831,9.149038,35.456432,25.74597"
                transform="translate(22.955625 20.656283)"
                fill="none"
                stroke="currentColor"
                stroke-width="13"
                stroke-linecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M164.6,78c3.168122-16.596932,18.693953-27.870701,35.456432-25.74597s28.984473,16.915679,27.911439,33.778174-15.071278,29.984938-31.967871,29.967796"
                transform="translate(-22.955626 20.656283)"
                fill="none"
                stroke="currentColor"
                stroke-width="13"
                stroke-linecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
};

export default AffiliationSvg;
