import React from "react";
import "aos/dist/aos.css";
import { useState } from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";
import { NavLink, useParams } from "react-router-dom";

const phone = "/dist/image/App-Phone-1.webp";
const phone2 = "/dist/image/App-Phone-2.webp";
const b33 = "/dist/image/Laptop2.webp";
const img2 = "/dist/image/Business1.webp";
const imgB = "/dist/image/Business2.webp";

const Business = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const [active, setActive] = useState<"blue" | "black" | "gold">("blue");
    return (
        <>
            <section className="lg:pt-[125px] sm:pt-[80px] pb-[125px] bg-[#0c0c0c] top-0 text-white bg-center md:!bg-top bg-cover lg:bg-no-repeat lg:text-lg">
                <HelmetComponent
                    title={translate("SEO", "Title_Business")}
                    description={translate("SEO", "Desc_Business")}
                    link="/business"
                    keywords={translate("SEO", "Keys_Business")}
                    imageCard="/dist/image/Infinexia-business-cards-1024x1024.png"
                    largeTwitterCard="/dist/image/Infinexia-business-cards-1024x1024.png"
                    addPostfixTitle={true}
                    noIndex={false}
                ></HelmetComponent>
                <div className=" lg:flex justify-between my-[75px] w-[90%] max-w-7xl mx-auto">
                    <div
                        className=" lg:w-[46%] my-16  "
                        data-aos="fade-right"
                        data-aos-offset="100"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="800"
                    >
                        <div className="text-white">
                            <h1 className="pb-4 md:!text-5xl text-4xl font-bold pt-5 md:!pt-0">
                                {translate("BUSINESS_PAGE", "Simplif_pro")}
                            </h1>
                            <p className="text-lg pb-2 font-normal">
                                {translate("BUSINESS_PAGE", "Simplif2_pro")}
                            </p>
                            <NavLink
                                to={`${lang ? `/${lang}` : ""}/register`}
                                className="btn btn-perfect text-center text-base px-4 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6 dark:border-white"
                            >
                                {translate("HOME_PAGE", "Create_An_Account")}
                            </NavLink>
                            <div className="flex items-center justify-left">
                                <div className="lg:flex justify-between">
                                    <div className="3xl:ml-4 text-base flex items-center justify-left">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="26"
                                            height="26"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="lucide lucide-rocket mr-2"
                                        >
                                            <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
                                            <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
                                            <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
                                            <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
                                        </svg>{" "}
                                        {translate(
                                            "BUSINESS_PAGE",
                                            "Conditions_pro",
                                        )}
                                    </div>
                                    <div className="lg:flex justify-between ">
                                        <div className="sm:!mt-4 lg:!mt-0 lg:ml-4 text-base flex items-center justify-left">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="26"
                                                height="26"
                                                fill="currentColor"
                                                className="bi bi-credit-card  mr-2"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"></path>
                                                <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"></path>
                                            </svg>{" "}
                                            {translate(
                                                "BUSINESS_PAGE",
                                                "A_partir_de_pro",
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="  "
                        data-aos="fade-left"
                        data-aos-offset=""
                        data-aos-easing=""
                        data-aos-duration="800"
                    >
                        <div className="pt-1">
                            <img
                                className="  sm:h-[250px] md:h-[375px] md:min-h-[375px] max-h-[450px] max-w-full align-baseline mx-auto"
                                src={b33}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* Third section */}
            <section className="bg-[#191c1e] py-28  ">
                <div className="text-white w-[90%] border rounded-lg max-w-7xl mx-auto my-5 grid grid-cols-1 lg:!grid-cols-4 dark:border-white">
                    <div className="bg-[#0c0c0c] text-center grid grid-cols-1  gap-y-2 p-5 m-3 rounded-lg">
                        <div className="min-h-[175px]">
                            <div className=" text-sm flex items-center justify-center pt-3 pb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    fill="currentColor"
                                    className="bi bi-credit-card"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"></path>
                                    <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"></path>
                                </svg>{" "}
                            </div>
                            <h2 className="text-lg  md:!text-xl font-bold">
                                {translate("BUSINESS_PAGE", "Business1")}
                            </h2>
                            <p className=" pt-2 pb-2 text-base md:!text-lg font-normal">
                                {translate("BUSINESS_PAGE", "Business2")}
                            </p>
                        </div>
                    </div>

                    <div className="bg-[#0c0c0c] text-center grid grid-cols-1  gap-y-2 p-5 m-3 rounded-lg">
                        <div className="min-h-[175px]">
                            <div className=" text-sm flex items-center justify-center  pt-3 pb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-rocket"
                                >
                                    <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
                                    <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
                                    <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
                                    <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
                                </svg>{" "}
                            </div>
                            <h2 className="text-lg  md:!text-xl font-bold">
                                {translate("BUSINESS_PAGE", "Instant")}
                            </h2>
                            <p className=" pt-2 pb-2 text-base md:!text-lg font-normal">
                                {translate("BUSINESS_PAGE", "Instant2")}
                            </p>
                        </div>
                    </div>

                    <div className="bg-[#0c0c0c] text-center grid grid-cols-1  gap-y-2 p-5 m-3 rounded-lg">
                        <div className="min-h-[175px]">
                            <div className=" text-sm flex items-center justify-center  pt-3 pb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-webhook"
                                >
                                    <path d="M18 16.98h-5.99c-1.1 0-1.95.94-2.48 1.9A4 4 0 0 1 2 17c.01-.7.2-1.4.57-2" />
                                    <path d="m6 17 3.13-5.78c.53-.97.1-2.18-.5-3.1a4 4 0 1 1 6.89-4.06" />
                                    <path d="m12 6 3.13 5.73C15.66 12.7 16.9 13 18 13a4 4 0 0 1 0 8" />
                                </svg>
                            </div>
                            <h2 className="text-lg  md:!text-xl font-bold">
                                {translate("BUSINESS_PAGE", "API")}
                            </h2>
                            <p className=" pt-2 pb-2 text-base md:!text-lg font-normal">
                                {translate("BUSINESS_PAGE", "API2")}
                            </p>
                        </div>
                    </div>

                    <div className="bg-[#0c0c0c] text-center grid grid-cols-1  gap-y-2 p-5 m-3 rounded-lg">
                        <div className="min-h-[175px]">
                            <div className=" text-sm flex items-center justify-center  pt-3 pb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-shield-check"
                                >
                                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
                                    <path d="m9 12 2 2 4-4" />
                                </svg>{" "}
                            </div>
                            <h2 className="text-lg  md:!text-xl font-bold">
                                {translate("BUSINESS_PAGE", "Secure")}
                            </h2>
                            <p className=" pt-2 pb-2 text-base md:!text-lg font-normal">
                                {translate("BUSINESS_PAGE", "Secure2")}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Second section */}
            <section id="business" className="bg-[#191c1e]">
                <div className=" text-xl text-white -z-40 pt-30 pb-36 w-[90%] max-w-7xl mx-auto  ">
                    <div className="md:flex justify-between grid ">
                        <div
                            className=" md:w-[46%]  "
                            data-aos="fade-right"
                            data-aos-offset=""
                            data-aos-easing=""
                            data-aos-duration="800"
                        >
                            <div className="md:!py-0 py-5">
                                <img
                                    className="  rounded-xl "
                                    src={img2}
                                    alt=""
                                />
                            </div>
                        </div>

                        <div
                            className=" md:w-[46%] my-auto text-lg"
                            data-aos="fade-left"
                            data-aos-offset="100"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="800"
                        >
                            <div className="my-8">
                                <h3 className=" md:!text-3xl text-2xl font-bold">
                                    {translate("BUSINESS_PAGE", "SUBTITLE_2")}
                                </h3>
                                <p className=" my-3 font-normal">
                                    {translate(
                                        "BUSINESS_PAGE",
                                        "PARAGRAPH_2-1",
                                    )}
                                </p>
                                <p className=" my-3 font-normal">
                                    {translate(
                                        "BUSINESS_PAGE",
                                        "PARAGRAPH_2-2",
                                    )}
                                </p>
                                <p className="font-normal">
                                    {translate(
                                        "BUSINESS_PAGE",
                                        "PARAGRAPH_2-3",
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Second section */}
            <section id="business" className="bg-[#0c0c0c]">
                <div className=" text-xl text-white -z-40 pt-36 pb-36 w-[90%] max-w-7xl mx-auto  ">
                    <div className="md:flex justify-between grid ">
                        <div
                            className=" lg:w-[46%] my-auto"
                            data-aos="fade-right"
                            data-aos-offset="200"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="800"
                        >
                            <div className="text-lg my-8">
                                <h3 className=" md:!text-3xl text-2xl font-bold">
                                    {translate("BUSINESS_PAGE", "App")}
                                </h3>
                                <p className=" my-3 font-normal">
                                    {translate("BUSINESS_PAGE", "App2")}
                                </p>
                                <p className=" my-3 font-normal">
                                    {translate("BUSINESS_PAGE", "App3")}
                                </p>
                                <p className="font-normal">
                                    {translate("BUSINESS_PAGE", "App4")}
                                </p>
                            </div>
                        </div>
                        <div
                            className=" lg:w-[40%] "
                            data-aos="fade-left"
                            data-aos-offset=""
                            data-aos-easing=""
                            data-aos-duration="800"
                        >
                            <div className="md:!py-0 py-5 ">
                                <img
                                    className=" h-[400px] min-h-[500px] max-h-[500px] max-w-full align-baseline mx-auto "
                                    src={phone}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section Four */}
            <section id="api" className="bg-[#191c1e]">
                <div className=" text-xl text-white -z-40 pt-36 pb-36 w-[90%] max-w-7xl mx-auto  ">
                    <div className="md:flex justify-between grid ">
                        <div
                            className=" md:w-[46%]  "
                            data-aos="fade-right"
                            data-aos-offset=""
                            data-aos-easing=""
                            data-aos-duration="800"
                        >
                            <div className="md:!py-0 py-5">
                                <img
                                    className="  rounded-xl "
                                    src={imgB}
                                    alt=""
                                />
                            </div>
                        </div>

                        <div
                            className=" md:w-[46%] my-auto text-lg"
                            data-aos="fade-left"
                            data-aos-offset="100"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="800"
                        >
                            <div className="my-8">
                                <h3 className=" md:!text-3xl text-2xl font-bold">
                                    {translate("BUSINESS_PAGE", "SUBTITLE_6")}
                                </h3>
                                <p className=" my-3 font-normal">
                                    {translate(
                                        "BUSINESS_PAGE",
                                        "PARAGRAPH_6-1",
                                    )}
                                </p>
                                <p className=" my-3 font-normal">
                                    {translate(
                                        "BUSINESS_PAGE",
                                        "PARAGRAPH_6-2",
                                    )}
                                </p>
                                <p className="font-normal">
                                    {translate(
                                        "BUSINESS_PAGE",
                                        "PARAGRAPH_6-3",
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Second section */}
            <section id="business" className="bg-[#0c0c0c]">
                <div className=" text-xl text-white -z-40 pt-36 pb-36 w-[90%] max-w-7xl mx-auto ">
                    <div className="md:flex justify-between grid ">
                        <div
                            className=" md:w-[46%]  "
                            data-aos="fade-right"
                            data-aos-offset=""
                            data-aos-easing=""
                            data-aos-duration="800"
                        >
                            <div className="md:!py-0 py-5">
                                <img
                                    className=" h-[400px] min-h-[500px] max-h-[500px] max-w-full align-baseline mx-auto "
                                    src={phone2}
                                    alt=""
                                />
                            </div>
                        </div>

                        <div
                            className=" md:w-[46%] my-auto text-lg"
                            data-aos="fade-left"
                            data-aos-offset="100"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="800"
                        >
                            <div className="my-8">
                                <h3 className=" md:!text-3xl text-2xl font-bold pb-5">
                                    {translate("BUSINESS_PAGE", "Open_account")}
                                </h3>
                                <div
                                    className="pt-3 lg:text-lg sm:text-base flex items-center pb-5
                                "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-file-pen mr-2 border-2 border-white rounded-lg p-2 dark:border-white"
                                    >
                                        <path d="M12 22h6a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v10" />
                                        <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                                        <path d="M10.4 12.6a2 2 0 1 1 3 3L8 21l-4 1 1-4Z" />
                                    </svg>{" "}
                                    <p className="ml-5 lg:text-lg sm:text-base font-bold">
                                        {translate("BUSINESS_PAGE", "Sign")}

                                        <br />
                                        <p className="lg:text-lg sm:text-base font-normal">
                                            {translate(
                                                "BUSINESS_PAGE",
                                                "Sign2",
                                            )}
                                        </p>
                                    </p>
                                </div>

                                <div
                                    className="pt-3 lg:text-lg sm:text-base flex items-center pb-5
                                "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-fingerprint mr-2 border-2 border-white rounded-lg p-2 dark:border-white"
                                    >
                                        <path d="M2 12C2 6.5 6.5 2 12 2a10 10 0 0 1 8 4" />
                                        <path d="M5 19.5C5.5 18 6 15 6 12c0-.7.12-1.37.34-2" />
                                        <path d="M17.29 21.02c.12-.6.43-2.3.5-3.02" />
                                        <path d="M12 10a2 2 0 0 0-2 2c0 1.02-.1 2.51-.26 4" />
                                        <path d="M8.65 22c.21-.66.45-1.32.57-2" />
                                        <path d="M14 13.12c0 2.38 0 6.38-1 8.88" />
                                        <path d="M2 16h.01" />
                                        <path d="M21.8 16c.2-2 .131-5.354 0-6" />
                                        <path d="M9 6.8a6 6 0 0 1 9 5.2c0 .47 0 1.17-.02 2" />
                                    </svg>{" "}
                                    <p className="ml-5 lg:text-lg sm:text-base font-bold">
                                        {translate("BUSINESS_PAGE", "Kyb")}

                                        <br />
                                        <p className="lg:text-lg sm:text-base font-normal">
                                            {translate("BUSINESS_PAGE", "Kyb2")}
                                        </p>
                                    </p>
                                </div>

                                <div
                                    className="pt-3 lg:text-lg sm:text-base flex items-center pb-5
                                "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="1.80"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-user-check-2 mr-2 border-2 border-white rounded-lg p-2 dark:border-white"
                                    >
                                        <path d="M14 19a6 6 0 0 0-12 0" />
                                        <circle cx="8" cy="9" r="4" />
                                        <polyline points="16 11 18 13 22 9" />
                                    </svg>{" "}
                                    <p className="ml-5 lg:text-lg sm:text-base font-bold">
                                        {translate("BUSINESS_PAGE", "Vérif")}

                                        <br />
                                        <p className="lg:text-lg sm:text-base font-normal">
                                            {translate(
                                                "BUSINESS_PAGE",
                                                "Vérif2",
                                            )}
                                        </p>
                                    </p>
                                </div>

                                <div
                                    className="pt-3 lg:text-lg sm:text-base flex items-center pb-5
                                "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-rocket mr-2 border-2 border-white rounded-lg p-2 dark:border-white"
                                    >
                                        <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
                                        <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
                                        <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
                                        <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
                                    </svg>{" "}
                                    <p className="ml-5 lg:text-lg sm:text-base font-bold">
                                        {translate("BUSINESS_PAGE", "Welcome")}

                                        <br />
                                        <p className="lg:text-lg sm:text-base font-normal">
                                            {translate(
                                                "BUSINESS_PAGE",
                                                "Welcome2",
                                            )}
                                        </p>
                                    </p>
                                </div>
                                <NavLink
                                    to={`${lang ? `/${lang}` : ""}/register`}
                                    className="btn btn-perfect text-center text-base px-4 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6 dark:border-white"
                                >
                                    {translate(
                                        "HOME_PAGE",
                                        "Create_An_Account",
                                    )}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Business;
