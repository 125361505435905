import { useNavigate } from "react-router-dom";
import { IUserSignUp } from "../../Models/User";
import { useAppDispatch } from "../../globalState/hook";
import {
    AuthenticationFailure,
    AuthenticationStarted,
    ChangeTwoFANeeded,
    EndAuthChecking,
    loggedWithSuccess,
    Logout,
    StartAuthChecking,
    UpdateUserInformation,
} from "../../globalState/reducers/Auth.reducer";
import { setInstanceTokens } from "../AxiosInstance";
import KycService from "../KYCService/KycService";
import {
    getInformation,
    logout,
    signIn,
    signUp,
    switchToJoin,
    switchToReal,
    verify2FA,
} from "./Authentication";
import { Cookies } from "react-cookie";
import {
    ChangeAppLanguage,
    Language,
} from "../../globalState/reducers/Language.reducer";
export default function AppAuthentication() {
    const dispatch = useAppDispatch();

    const siginAsync = async (
        username: string,
        password: string,
        recaptcha_token: string | null,
        translate: any,
        rememberMe: boolean,
    ) => {
        dispatch(AuthenticationStarted());
        const result = await signIn(
            username,
            password,
            recaptcha_token,
            rememberMe,
        );

        if (result.error) {
            const error = result.response
                ? result.response.data.message
                : result.message ?? "An error was occured";

            const error2 = translate(
                "LOGIN_PAGE",
                result.response.data.code,
                typeof result.response.data.stack === "object"
                    ? result.response.data.stack
                    : {},
            );

            dispatch(
                AuthenticationFailure({
                    error,
                }),
            );

            return Promise.reject(error2);
        }

        const { user, token, two_factor_needed } = result.response.data;

        setInstanceTokens(token);

        dispatch(
            ChangeTwoFANeeded({
                two_fa_needed: two_factor_needed,
            }),
        );

        if (!two_factor_needed) {
            dispatch(
                loggedWithSuccess({
                    user,
                }),
            );

            /* ************** set user language ************** */
            dispatch(
                ChangeAppLanguage({
                    language: user.language as Language,
                }),
            );
            /* ************** set user language ************** */

            KycService.refresh();

            return Promise.resolve(
                `${translate("Authentication", "Authentication_with_success")}`,
            );
        }

        return Promise.resolve(
            `${translate("Authentication", "Need_2FA_Authentication")}`,
        );
    };

    const switchToJoinAccount = async (translate: any) => {
        dispatch(AuthenticationStarted());
        const result = await switchToJoin();

        if (result.error) {
            const error = result.response
                ? result.response.data.message
                : result.message ?? "An error was occured";

            dispatch(
                AuthenticationFailure({
                    error,
                }),
            );

            return Promise.reject(error);
        }

        const { user, token } = result.response.data;
        dispatch(
            loggedWithSuccess({
                user,
            }),
        );

        setInstanceTokens(token);
        // location.reload();

        window.location.href = "/dashboard";

        if (user?.language) {
            dispatch(
                ChangeAppLanguage({
                    language: user.language as Language,
                }),
            );
        }

        return Promise.resolve(
            `${translate("APP", "switch_to_join_account_msg")}`,
        );
    };

    const switchToOriginAccount = async (translate: any) => {
        dispatch(AuthenticationStarted());
        const result = await switchToReal();

        if (result.error) {
            const error = result.response
                ? result.response.data.message
                : result.message ?? "An error was occured";

            dispatch(
                AuthenticationFailure({
                    error,
                }),
            );

            return Promise.reject(error);
        }

        const { user, token } = result.response.data;
        dispatch(
            loggedWithSuccess({
                user,
            }),
        );

        setInstanceTokens(token);

        window.location.href = "/dashboard";

        if (user?.language) {
            dispatch(
                ChangeAppLanguage({
                    language: user.language as Language,
                }),
            );
        }

        return Promise.resolve(
            `${translate("APP", "switch_to_real_account_msg")}`,
        );
    };

    const verify2faToken = async (token_2fa: string) => {
        dispatch(AuthenticationStarted());
        const result = await verify2FA(token_2fa);

        if (result.error) {
            const error = result.response
                ? result.response.data.message
                : result.message ?? "An error was occured";

            dispatch(
                AuthenticationFailure({
                    error,
                }),
            );

            return Promise.reject(error);
        }

        const { user, token } = result.response.data;

        dispatch(
            ChangeTwoFANeeded({
                two_fa_needed: false,
            }),
        );

        /* ************** set user language ************** */
        dispatch(
            ChangeAppLanguage({
                language: user.language as Language,
            }),
        );
        /* ************** set user language ************** */

        setInstanceTokens(token);

        dispatch(
            loggedWithSuccess({
                user,
            }),
        );

        KycService.refresh();

        return Promise.resolve("Authentication with success");
    };

    const logoutAsync = async () => {
        await logout();

        setInstanceTokens();

        dispatch(Logout());
    };

    const getInformationAsync = async (silence = false) => {
        if (!silence) dispatch(StartAuthChecking());
        const result = await getInformation();

        if (result.error && result.response) {
            //check 2FA
            if (result.response.data.code === "2FA_NEEDED") {
                dispatch(ChangeTwoFANeeded({ two_fa_needed: true }));
            }
            if (!silence) dispatch(EndAuthChecking());
            return;
        }

        const user = result.response.data;

        dispatch(
            UpdateUserInformation({
                user,
            }),
        );
        if (!silence) dispatch(EndAuthChecking());
    };

    const rememberMe = async (
        username: string,
        password: string,
        authToken: string,
        rememberMe: boolean,
    ) => {
        const cookies = new Cookies();
        if (rememberMe) {
            cookies.set("username", username);
            cookies.set("password", password);
            cookies.set("authToken", authToken);
        } else {
            cookies.remove("authToken");
        }
        return;
    };

    const signUpAsync = async (
        payload: IUserSignUp,
        repeatPassword: string,
        translate: any,
    ) => {
        dispatch(AuthenticationStarted());
        const result = await signUp(payload, repeatPassword);

        if (result.error) {
            const error = result.response
                ? result.response.data.message
                : result.message ?? "An error was occured";

            dispatch(
                AuthenticationFailure({
                    error,
                }),
            );

            return Promise.reject(error);
        }

        const { user, token, two_factor_needed } = result.response.data;

        setInstanceTokens(token);

        dispatch(
            ChangeTwoFANeeded({
                two_fa_needed: two_factor_needed,
            }),
        );

        if (!two_factor_needed) {
            dispatch(
                loggedWithSuccess({
                    user,
                }),
            );

            KycService.refresh();

            return Promise.resolve(
                `${translate("Authentication", "Authentication_with_success")}`,
            );
        }

        return Promise.resolve(
            `${translate("Authentication", "Need_2FA_Authentication")}`,
        );
    };

    return {
        siginAsync,
        logoutAsync,
        getInformationAsync,
        verify2faToken,
        signUpAsync,
        switchToOriginAccount,
        switchToJoinAccount,
    };
}
