import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Transition } from "@headlessui/react";
import {
    FlyOutComponent,
    useFlyoutContext,
} from "../../../App/Provider/FlyOutProvider";
import ActiveOrderContent from "./activeOrder";
import UserCard from "../../../Models/UserCard";
import { useCardContext } from "../../../App/Provider/CardProvider";
export default function FlyOutActiveCard() {
    const { selectedCard, userCards } = useCardContext();
    const { activeComponent, setActiveComponent, title } = useFlyoutContext();
    const panelContent = useRef<HTMLDivElement>(null);
    const closeBtn = useRef<HTMLButtonElement>(null);

    const selectedCard2 = useMemo<UserCard | null>(() => {
        const card_active = userCards.filter(
            (card) => card.order?.id === selectedCard?.order?.id,
        );
        return card_active[0];
    }, [userCards]);
    return (
        <>
            {" "}
            <Transition
                show={activeComponent == FlyOutComponent.ACTIVE_DESACTIVE_CARD}
                unmount={false}
                as="div"
                id="edit-password"
                ref={panelContent}
                className="fixed inset-0 sm:left-auto z-50 shadow-xl overflow-hidden w-full md:!w-auto"
                enter="transition-transform duration-200 ease-in-out"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition-transform duration-200 ease-in-out"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
            >
                <div className="relative pb-[100px] top-0 z-[9999] bg-slate-50 dark:bg-black overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-l border-slate-200 dark:border-slate-700 w-full md:!w-[390px] h-[calc(100dvh-64px)]">
                    <div className="w-full fixed bg-white dark:bg-black z-[100]">
                        <div className="max-w-sm mx-auto lg:max-w-none mt-3">
                            <button
                                ref={closeBtn}
                                onClick={() => setActiveComponent(undefined)}
                                className="absolute top-[10px] right-0 group p-2 z-[100] mr-4"
                            >
                                <svg
                                    className="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                                </svg>
                            </button>
                            <div className="text-lg  text-slate-800 dark:text-slate-100 font-normal text-center mb-1">
                                <div className="flex justify-center mb-2">
                                    <div className="text-center font-bold">
                                        {title}
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className=" col-span-12 p-5 mt-10"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                        }}
                    >
                        {selectedCard2 && (
                            <ActiveOrderContent
                                selectedCard={selectedCard2}
                                hideTitle
                            />
                        )}
                    </div>
                </div>
            </Transition>
        </>
    );
}
