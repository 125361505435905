import React from "react";
import LanguageSwitcher from "../../Layouts/Account/Language/Language";
import ThemeSwitcher from "../../Layouts/Account/ThemeSwticher/ThemeSwitcher";
import useTheme from "../../Services/Hooks/useTheme";
import UtilsService from "../../Services/UtilsService";
import { AppMode } from "../../globalState/reducers/Theme.reducer";
import ThemeToggle from "./ThemeToggle";
import { useParams } from "react-router-dom";

export default function DocHeader() {
    const { lang } = useParams();
    const { mode } = useTheme();
    return (
        <header className="fixed w-full z-30">
            <div
                className="absolute inset-0 bg-white bg-opacity-70 border-b border-slate-200 backdrop-blur -z-10 dark:bg-slate-900 dark:border-slate-800"
                aria-hidden="true"
            />
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
                <div className="flex items-center justify-between h-16 md:h-20">
                    {/* Site branding */}
                    <div className="grow">
                        <div className="flex items-center">
                            <a href={lang ? `/${lang}` : "/"}>
                                <img
                                    src={UtilsService.getPulicImage(
                                        "/dist/image/Logo-Infinexia.webp",
                                    )}
                                    className={`h-[50px] w-auto ${
                                        mode === AppMode.LIGHT &&
                                        "filter-invert filter-50"
                                    }`}
                                    style={{
                                        ...(mode === AppMode.LIGHT && {
                                            filter: "invert(1)",
                                        }),
                                    }}
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>

                    {/* Desktop navigation */}
                    <nav className="flex">
                        {/* Right side elements links */}
                        <ul className="flex grow justify-end flex-wrap items-center">
                            <li>
                                <ThemeToggle />
                            </li>
                            <li>
                                <div className="intro-x w-4 h-4 ml-3 sm:mr-6 language">
                                    <LanguageSwitcher doc />
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}
