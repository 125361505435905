import React from "react";
import { useNavigate } from "react-router";
import UtilsService from "../../Services/UtilsService";
import translator from "../../Components/Extra/Translation/Translate";
import Footer404 from "../Accueil/Base/footer-404";
import Header from "../Accueil/Base/header";

const Error = () => {
    const navigate = useNavigate();
    const { translate } = translator();
    return (
        <>
            {/* First section */}
            <Header />
            <section className="bg-black py-28 mx-auto ">
                <div className="text-white  w-[90%] rounded-lg max-w-7xl mx-auto my-5 grid grid-cols-1 lg:!grid-cols-1">
                    <div className="mt-10 text-white text-center lg:mt-0 mx-auto">
                        <div className="mt-[100px] intro-x md:!text-4xl text-3xl font-medium">
                            {translate("REF_DIVERS", "Oups")}
                        </div>
                        <div className="intro-x mt-3 md:!text-2xl text-2xl">
                            {translate("REF_DIVERS", "Oups2")}
                        </div>
                        <div className="intro-x mt-3 text-lg">
                            {translate("REF_DIVERS", "Moved")}
                        </div>
                        <div className="text-center mt-5 mb-10 pb-4">
                            <button
                                onClick={() => navigate("/dashboard")}
                                className="btn btn-perfect text-center text-base px-4 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6"
                            >
                                {translate("App", "Back_to_account")}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer404 />
        </>
    );
};

export default Error;
