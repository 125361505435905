import React from "react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import Footer from "./footer";
import Header from "./header";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Outlet, useLocation } from "react-router-dom";
import FooterBusiness from "./footer-business";
import HeaderBusiness from "./header-business";

import CookieBanner from "./CookieBanner";

const LandingBase = () => {
    const [scroll_btn_up, setScrollBtnUp] = useState(false);
    const { pathname } = useLocation();

    //Control the button Up
    const handleScrollBtnUp = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 800) setScrollBtnUp(true);
        else setScrollBtnUp(false);
    };

    //Render the animation effect
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    useEffect(() => {
        handleScrollBtnUp();
        window.addEventListener("scroll", handleScrollBtnUp);
        return () => {
            window.removeEventListener("scroll", handleScrollBtnUp);
        };
    }, []);

    const hasBusinessUrl = pathname.includes("/business");

    return (
        <div className=" overflow-x-clip text-xl bg-white text-black">
            {hasBusinessUrl ? <HeaderBusiness /> : <Header />}

            <div className="min-h-[600px]">
                <Outlet />
            </div>
            {hasBusinessUrl ? (
                <FooterBusiness></FooterBusiness>
            ) : (
                <Footer></Footer>
            )}

            <ChevronUpIcon
                onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={` ${
                    scroll_btn_up ? "block" : " hidden "
                } btn btn-perfect border-none !rounded-none !rounded-l-lg ifx-btt-bouton float-right right-0 w-10 p-[8px] h-[40px] stroke-[3px] fixed  bottom-32 text-white `}
            />
            <CookieBanner />
        </div>
    );
};

export default LandingBase;
