import React from "react";

import alert_message from "../../../Services/Alert/AlertMessage";
import CopySvg from "../Svg/CopySvg";
import translator from "../Translation/Translate";

const KpCopyClick = ({ text, message }: { text: string; message: string }) => {
    const { translate } = translator();
    return (
        <button
            className=" bg-transparent"
            type="button"
            onClick={() => {
                navigator.clipboard.writeText(text);
                alert_message.success(message != "" ? message : "Copié");
            }}
        >
            <CopySvg />
        </button>
    );
};

export default KpCopyClick;
