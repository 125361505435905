import React from "react";
import { useParams } from "react-router-dom";
import RegisterComponent from "./RegisterComponent";
import translator from "../../Extra/Translation/Translate";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";
const Register = () => {
    const { translate } = translator();
    const { parrain } = useParams();
    return (
        <div className="login h-screen !p-0">
            <HelmetComponent
                title={translate("SEO", "Title_Register")}
                description={translate("SEO", "Desc_Register")}
                link="/register"
                keywords={translate("SEO", "Keys_Register")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>
            <div className="flex items-center">
                <div className="-intro-x sm:hidden md:hidden lg:flex xl:flex 2xl:flex lg:items-center xl:items-center 2xl:items-center lg:justify-center xl:justify-center 2xl:justify-center lg:w-1/2 xl:w-1/2 2xl:w-1/2 bg-img-perfect">
                    <div className="-intro-x lg:text-[40px] xl:text-[40px] 2xl:text-[42px] font-medium leading-tight text-white">
                        {translate("SignIn", "New_Text")}
                        <br />
                        {translate("SignIn", "New_Text_2")}
                    </div>
                </div>
                <div className="flex items-center justify-center sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                    <div className="my-10 sm:w-full flex !sm:h-auto sm:pt-[50px] justify-center !h-screen xl:my-0 xl:h-auto overflow-hegith py-[70px] sm:pb-[70px] md:py-[70px] lg:py-[70px] xl:py-[70px] 2xl:py-8 mdg-register-ctn ">
                        <RegisterComponent parrain={parrain} />
                    </div>
                </div>
            </div>
            {/* <div className="container mob-contain">
                <div className="block grid-cols-2 gap-4 xl:grid">
                    <div className="hidden min-h-screen flex-col xl:flex">
                        <div className="my-auto mr-[100px]">
                            <div className="-intro-x mt-10 text-4xl font-medium leading-tight text-white">
                                PERFECTNODES <br />
                                {translate("Register", "BEST_BOOSTER_STAKING")}
                                <br />
                                {translate("Register", "Sign_your_account")}
                            </div>
                        </div>
                    </div>
                    <div className="my-10 flex justify-center items-center !h-screen xl:my-0 xl:h-auto overflow-hegith py-[70px] sm:py-[70px] md:py-[70px] lg:py-[70px] xl:py-[70px] 2xl:py-8 mdg-register-ctn ">
                        <RegisterComponent parrain={parrain} />
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default Register;
