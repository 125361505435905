import React from "react";
import Head from "./Head";
import SideMobile from "./SideMobile";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const Landing = ({ children }: { children: React.ReactNode }) => {
    if (typeof document != "undefined") {
        document.body.style.padding = "0";
    }
    const key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    return (
        <div>
            <SideMobile />
            <Head />
            <GoogleReCaptchaProvider reCaptchaKey={key || ""}>
                {children}
            </GoogleReCaptchaProvider>
        </div>
    );
};

export default Landing;
