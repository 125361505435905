import { DatatableFilter } from "../../Models/DatatableFilter";
import OrderCard, {
    IDelivered,
    IOrderCardRequestPayload,
    OrderCardRequestStatus,
    OrderData,
    PayloadSaveCard,
} from "../../Models/OrderCard";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

export const mapOrderCard = (item: any): OrderCard => {
    const order = new OrderCard(
        item.uid,
        item.user_name,
        item.last_name,
        item.first_name,
        item.email,
        item.type,
        item.status,
        item.shipping,
        item.created_at,
    );

    order.id = item.id;
    order.is_active = item.is_active;
    order.agent_user_name = item.agent_user_name;
    order.agent_last_name = item.agent_last_name;
    order.agent_first_name = item.agent_first_name;
    order.agent_uid = item.agent_uid;
    order.agent_email = item.agent_email;
    order.is_express = item.is_express;
    order.home_address = item.home_address;
    if (item.home_address) {
        try {
            const home_address = JSON.parse(item.home_address);
            order.address = home_address.deliveryAdress;
            order.postal_code = home_address.deliveryPostalCode;
            order.city = home_address.deliveryCity;
            order.country = home_address.deliveryCountry;
        } catch (err) {
            try {
                const home_address = item.home_address.split("#");
                order.address = home_address[0];
                order.postal_code = home_address[1];
                order.city = home_address[2];
                order.country = home_address[3];
            } catch {}
        }
    }

    order.fidelity = item?.fidelity;
    order.user = item?.user;

    return order;
};

export interface CodeValidate {
    code: string;
}
const orderCard = async (
    payload: IOrderCardRequestPayload,
): Promise<string> => {
    return axios
        .post("/order-card-request/", payload)
        .then(() => {
            return Promise.resolve("Commande créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const decline = async (order_id: number): Promise<string> => {
    return axios
        .put(`/order-card-request/decline/${order_id}`)
        .then(() => {
            return Promise.resolve("Commande décliné avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const saved = async (
    order_id: number,
    data: PayloadSaveCard,
): Promise<string> => {
    return axios
        .put(`/order-card-request/saved/${order_id}`, data)
        .then(() => {
            return Promise.resolve("Commande sauvegardé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deliveredToAgent = async (order_id: number): Promise<string> => {
    return axios
        .put(`/order-card-request/delivered-to-agent/${order_id}`)
        .then(() => {
            return Promise.resolve("Mise à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const delivered = async (order_id: number, url?: string): Promise<string> => {
    const data: IDelivered = {
        url: url,
    };
    return axios
        .put(`/order-card-request/delivered/${order_id}`, data)
        .then(() => {
            return Promise.resolve("Commande délivré avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllOrderCardFilter = async (
    filter: DatatableFilter,
    status?: OrderCardRequestStatus,
): Promise<any> => {
    let end_point = "";
    switch (status) {
        case OrderCardRequestStatus.PENDING:
        case OrderCardRequestStatus.SAVED:
            end_point = `/order-card-request/pending-saved`;
            break;
        case OrderCardRequestStatus.DELIVERED_TO_AGENT:
            end_point = `/order-card-request/delivered-to-agent`;
            break;
        default:
            end_point = `/order-card-request/get-all`;
            break;
    }
    return axios
        .get(end_point, { params: filter })
        .then(({ data }) => {
            const assets: OrderCard[] = data.data.map((item: any) =>
                mapOrderCard(item),
            );

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getInfo = async (order_id: number): Promise<OrderData> => {
    return axios
        .get(`/order-card-request/get-info/${order_id}`)
        .then(({ data }) => {
            return data as OrderData;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllOrderCardFilterByStatus = async (
    filter: DatatableFilter,
    status?: OrderCardRequestStatus,
): Promise<any> => {
    const end_point = `/order-card-request/get-all-by-status/${status}`;
    return axios
        .get(end_point, { params: filter })
        .then(({ data }) => {
            const assets: OrderCard[] = data.data.map((item: any) =>
                mapOrderCard(item),
            );

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const activeCardRequest = async (
    order_id: number,
    code: CodeValidate,
): Promise<any> => {
    return axios
        .put(`/order-card-request/update-is-active/${order_id}`, { ...code })
        .then((data) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const OrderCardService = {
    orderCard,
    decline,
    saved,
    deliveredToAgent,
    delivered,
    getAllOrderCardFilter,
    getInfo,
    getAllOrderCardFilterByStatus,
    activeCardRequest,
};

export default OrderCardService;
