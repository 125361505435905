import React from "react";
import { Outlet } from "react-router-dom";
import TabNav, { TabItem } from "../../../../Components/Extra/Tab/TabNav";
import translator from "../../../../Components/Extra/Translation/Translate";
import BreadCrumbV2, {
    Ariane,
} from "../../../../Layouts/Account/BreadCrumb/BreadCrumbV2";

const GatewayConfig: React.FC = () => {
    const { translate } = translator();

    const Arianes: Ariane[] = [
        {
            path: "#",
            name: "Admin",
        },
        {
            path: "/admin/setting",
            name: "Setting",
        },
        {
            path: "",
            name: "Payment",
        },
    ];

    const navigation: TabItem[] = [
        {
            path: "visa",
            name: "Visa",
        },
        {
            path: "crypto",
            name: "Crypto",
        },
        {
            path: "virement",
            name: "Virement",
        },
        {
            path: "payeer",
            name: "Payeer",
        },
        {
            path: "mobile-money",
            name: "Mobile money",
        },
        {
            path: "western-union",
            name: "Western Union",
        },
        {
            path: "moneygram",
            name: "MoneyGram",
        },
    ];

    return (
        <>
            <h2 className="intro-y mt-5 bg-white px-3 p-2 rounded text-lg font-medium">
                {translate("Setting", "Payment")}
            </h2>
            <div className="mt-5 pt-5">
                <TabNav items={navigation} />
                <div className="intro-y col-span-12 box">
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default GatewayConfig;
