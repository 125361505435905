import React, { useEffect } from "react";
import { formatAmount } from "../../../Services/UtilsService";

interface NumberInputProps {
    decimal: number;
    val: number;
    onChange: (value: number) => void;
    name?: string;
    className?: string;
    required?: boolean;
    disabled?: boolean;
    min?: number;
    max?: number;
}

const NumberInput = ({
    val,
    decimal,
    onChange,
    name,
    className,
    required,
    disabled,
    min,
    max,
}: NumberInputProps) => {
    const valueRef = React.useRef<HTMLInputElement>(null);

    const [value, dispatchValue] = React.useReducer(
        (state: string, newValue: string) => {
            newValue = newValue.replace(".", ",");

            const [formattedWholeValue, decimalValue = "0"] =
                newValue.split(",");

            const significantValue = formattedWholeValue.replace(/\s+/g, "");
            const floatValue = parseFloat(
                significantValue + "." + decimalValue.slice(0, decimal),
            );

            if (isNaN(floatValue) === false) {
                const n = formatAmount(floatValue, decimal);
                onChange(floatValue);
                if (newValue.includes(",") && !n.includes(",")) {
                    return n + "," + decimalValue;
                }
                return n;
            }
            return "0";
        },
        formatAmount(val),
    );

    useEffect(() => {
        dispatchValue(formatAmount(val));
    }, [val]);

    return (
        <>
            <div onClick={() => valueRef.current?.focus()}>
                <input
                    required={!!required}
                    type="text"
                    min={min}
                    max={max}
                    // pattern="d+(,d{6})?"
                    value={value}
                    className={`${className} text-right`}
                    name={name}
                    onChange={(e) => dispatchValue(e.target.value)}
                    ref={valueRef}
                    disabled={disabled}
                />
            </div>
        </>
    );
};

export default NumberInput;
