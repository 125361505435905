import React, { createContext, useContext, useEffect, useState } from "react";
import alert_message from "../../Services/Alert/AlertMessage";
import { useAppProvider } from "./AppProvider";
import CryptoService from "../../Services/Wallets/CryptoService";
import Crypto from "../../Models/Crypto";

interface FiatContextProps {
    fiatList: Array<Crypto> | [];
    refresh: boolean;
    setRefresh: (value: boolean) => void;
}

const FiatContext = createContext<FiatContextProps | undefined>(undefined);

export const FiatProvider = ({ children }: { children: React.ReactNode }) => {
    const [fiatList, setFiatList] = useState<Array<Crypto>>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const { setProcessing, finalizeProcessing } = useAppProvider();

    const getFiat = async () => {
        setProcessing(true);
        try {
            const data = await CryptoService.getActiveFiatWallets();
            if (data) setFiatList(data);
        } catch (err: any) {
            alert_message.error(err);
        }
        finalizeProcessing();
    };

    useEffect(() => {
        getFiat();
    }, [refresh]);

    return (
        <FiatContext.Provider value={{ fiatList, refresh, setRefresh }}>
            {children}
        </FiatContext.Provider>
    );
};

export const useFiat = () => {
    const context = useContext(FiatContext);
    if (!context) {
        throw new Error("useFiat must be used within a FiatProvider");
    }
    return context;
};
