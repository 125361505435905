import React from "react";
import "animate.css";
import { ModalProvider } from "../../Extra/Modal/ModalContext";
import BtnAddCardVirtualModal from "./btnAddCardVirtualModal";

const AddCardVirtualModal = () => {
    return (
        <>
            <ModalProvider>{<BtnAddCardVirtualModal />}</ModalProvider>
        </>
    );
};

export default AddCardVirtualModal;
