import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import UtilsService, { formatAmount } from "../../../Services/UtilsService";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import { usePdfExport } from "../../../App/ExportPdfProvider3";
import translator from "../../Extra/Translation/Translate";

const PrintHistorique = ({ transactions, dates, setIsFlex }: any) => {
    const { user } = useAuthentication();
    const { translate } = translator();
    const { print, setTransaction, setDates } = usePdfExport();

    useEffect(() => {
        setTransaction(transactions);
        setDates(dates);
    }, [transactions, dates]);

    return (
        <div>
            <div className="flex mt-8">
                <span className="">
                    {translate("POPUP_DOWNL", "Download_PDF")}
                </span>
                <span>
                    <button
                        className="btn bg-transparent !border-none  md:w-auto lg:w-auto xl:w-auto h-[42px] -mt-[20px] !p-3"
                        onClick={print}
                    >
                        <img
                            src={UtilsService.getPulicImage(
                                "/dist/image/icon_pdf.webp",
                            )}
                            className="h-[40px] w-[40px] object-fill mt-3"
                            alt=""
                        />
                    </button>
                </span>
            </div>
        </div>
    );
};

export default PrintHistorique;
