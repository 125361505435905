import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";
import PartenarUser from "../../../Models/UserPartenar";
import useTheme from "../../../Services/Hooks/useTheme";
import { Option as DOption } from "react-tailwindcss-select/dist/components/type";
import UserService from "../../../Services/User/UserService";
import { UserType } from "../../../Models/User";

interface SelectInputUserProps {
    id?: string;
    components?: any;
    onChange?: (value: any) => void;
    required?: boolean;
    no_parrain?: boolean;
    is_role_user?: boolean;
    no_join?: boolean;
    no_perso?: boolean;
    no_mail?: boolean;
}

const SelectInputUser = ({
    id,
    components,
    onChange,
    required,
    no_parrain,
    is_role_user,
    no_join,
    no_perso,
    no_mail,
}: SelectInputUserProps) => {
    const { mode } = useTheme();

    const [isLoading, setIsLoading] = useState(false);

    const mapOptions = (user: PartenarUser): DOption => {
        const name =
            user.type === UserType.ENTERPRISE && user.entreprise
                ? user.entreprise
                : `${user.last_name} ${user.first_name}`;

        const label_no_mail = `${name} (${user.uid})`;
        const label_with_mail = `${name} (${user.uid}${
            user.email != "" ? ` - ${user.email}` : ""
        })`;

        const label = !!no_mail ? label_no_mail : label_with_mail;
        return {
            label: label,
            value: `${user.uid}`,
        };
    };

    const getPartenar = async (search: string) => {
        try {
            if (!!no_parrain) {
                const res = await UserService.getNoParrain();
                return res.map((elt: any) => mapOptions(elt));
            } else if (!!is_role_user) {
                const res = await UserService.searchRoleUser(search);
                return res.map((elt: any) => mapOptions(elt));
            } else if (!!no_join) {
                const res = await UserService.getNoJoin();
                return res.map((elt: any) => mapOptions(elt));
            } else if (!!no_perso) {
                const res = await UserService.getNoPerso(search);
                return res.map((elt: any) => mapOptions(elt));
            } else {
                const res = await UserService.getPartenar(search);
                return res.map((elt: any) => mapOptions(elt));
            }
        } catch (err: any) {
            return [];
        }
    };

    const loadOptions = (
        inputValue: string,
        callback: (options: any[]) => void,
    ) => {
        setIsLoading(true);
        setTimeout(async () => {
            if (inputValue.length >= 3) {
                callback(await getPartenar(inputValue));
            }

            setIsLoading(false);
        }, 1000);
    };

    return (
        <AsyncSelect
            id={id}
            loadOptions={loadOptions}
            className="bg-select-view-other"
            components={components}
            onChange={onChange}
            styles={{
                control: (base) =>
                    mode === AppMode.LIGHT
                        ? {
                              ...base,
                              minHeight: 46,
                              borderRadius: 3,
                              borderColor: "#E2E8F0",
                              background: "transparent",
                              color: "rgb(30 41 59)",
                          }
                        : {
                              ...base,
                              minHeight: 46,
                              borderRadius: 3,
                              borderColor: "#E2E8F0",
                              background: "transparent",
                              color: "#FFF",
                          },
                option: (base) =>
                    mode === AppMode.LIGHT
                        ? {
                              ...base,
                              minHeight: 46,
                              background: "#fff",
                              color: "#000",
                              "&:hover": {
                                  color: "#fff",
                                  background:
                                      "radial-gradient(circle at center,#3695D2 0%,#303B8E 100%)",
                              },
                          }
                        : {
                              ...base,
                              minHeight: 46,
                              background: "#0f0f0f",
                              color: "#FFF",
                              "&:hover": {
                                  color: "#fff",
                                  background:
                                      "radial-gradient(circle at center,#3695D2 0%,#303B8E 100%)",
                              },
                          },
            }}
            required={!!required}
        />
    );
};

export default SelectInputUser;
