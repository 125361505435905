import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAppProvider } from "../../App/Provider/AppProvider";

interface SidebarLinkProps {
    children: React.ReactNode;
    href: string;
}

export default function SidebarLink({ children, href }: SidebarLinkProps) {
    const { pathname } = useLocation();
    const { setSidebarOpen } = useAppProvider();

    return (
        <NavLink
            className={`flex items-center space-x-3 font-medium ${
                pathname === href
                    ? "text-blue-600"
                    : "text-slate-800 dark:text-slate-200"
            }`}
            to={href}
            onClick={() => setSidebarOpen(false)}
        >
            {children}
        </NavLink>
    );
}
