import React, {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
    useMemo,
} from "react";
import WalletService from "../../Services/Wallets/WalletService";
import { UserCryptoWallet } from "../../Models/UserWallet";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import PnlService from "../../Services/Pnl/PnlService";
import { useCurrencySwitcher } from "../../Hooks/currency-swicher";
import { useWalletValue } from "../../Hooks/useWalletValue";
import UtilsService from "../../Services/UtilsService";

interface ContextProps {
    assets: UserCryptoWallet[];
    currentAsset: UserCryptoWallet | undefined;
    refreshAssets: () => any;
    setCurrentAsset: (asset: UserCryptoWallet | undefined) => any;
    walletSynchronisation: boolean;
    refreshed: number;
    getPrincipalAccount: () => undefined | UserCryptoWallet;
    getGlobalBalance: () => number;
    getFiatBalance: () => number;
    getFiatEarningBalance: () => number;
    getFiatDailyEarn: () => number;
    getCardBalance: () => number;
    getCryptoBalance: () => number;
    getCryptoEarningBalance: () => number;
    getGlobalEarningBalance: () => number;
    reload: boolean;
    setReload: Dispatch<SetStateAction<boolean>>;
    filter: string;
    setFilter: Dispatch<SetStateAction<string>>;
    totalGainsCrypto: number;
    totalGainsFiat: number;
    analytics: {
        crypto: {
            earning: number;
            solde: number;
        };
        fiat: {
            earning: number;
            solde: number;
        };
        principalBalance: number;
    };
}

const AssetsContext = createContext<ContextProps>({
    assets: [],
    currentAsset: undefined,
    refreshAssets: () => true,
    setCurrentAsset: (val: UserCryptoWallet | undefined) => {
        return;
    },
    walletSynchronisation: false,
    refreshed: 0,
    getPrincipalAccount: () => undefined,
    getGlobalBalance: () => 0,
    getFiatBalance: () => 0,
    getFiatEarningBalance: () => 0,
    getFiatDailyEarn: () => 0,
    getCardBalance: () => 0,
    getCryptoBalance: () => 0,
    getCryptoEarningBalance: () => 0,
    getGlobalEarningBalance: () => 0,
    reload: false,
    setReload: (): boolean => false,
    filter: "",
    setFilter: (): string => "",
    totalGainsCrypto: 0,
    totalGainsFiat: 0,
    analytics: {
        crypto: {
            earning: 0,
            solde: 0,
        },
        fiat: {
            earning: 0,
            solde: 0,
        },
        principalBalance: 0,
    },
});

export default function AssetsProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const { user } = useAuthentication();
    const { userCurrency } = useCurrencySwitcher();
    const [walletSynchronisation, setWalletSynchronisation] =
        useState<boolean>(false);
    const [nbCall, setNbCall] = useState<number>(0);
    const [wallets, setAssets] = useState<UserCryptoWallet[]>([]);
    const [asset, setAsset] = useState<UserCryptoWallet | undefined>(undefined);
    const [filter, setFilter] = useState<string>("fiat");
    const [reload, setReload] = useState<boolean>(false);
    const [totalGains, setTotalGains] = useState<{
        crypto: number;
        fiat: number;
    }>({
        crypto: 0,
        fiat: 0,
    });

    const refreshAssets = async () => {
        setNbCall(nbCall + 1);
        setWalletSynchronisation(true);

        const data = await WalletService.getUserWallet();
        setWalletSynchronisation(false);
        setAssets(data);
    };

    const getPrincipalAccount = () => {
        const currency = user?.base_currency?.currency || "USD";
        const principal = wallets.find(
            (asset) => asset.abbreviation === currency,
        );

        return principal;
    };

    const getFiatBalance = (): number => {
        const total = wallets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "fiat"
                        ? asset.wallet.solde * asset.current_price
                        : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };

    const getFiatEarningBalance = (): number => {
        const total = wallets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "fiat"
                        ? asset.wallet.staking_solde * asset.current_price
                        : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };

    const getFiatDailyEarn = (): number => {
        const total = wallets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "fiat"
                        ? asset.wallet.daily_earn * asset.current_price
                        : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };

    const getCryptoBalance = (): number => {
        const total = wallets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "crypto"
                        ? asset.wallet.solde * asset.current_price
                        : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };

    const getCryptoEarningBalance = (): number => {
        const total = wallets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "crypto"
                        ? asset.wallet.staking_solde * asset.current_price
                        : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };

    const getGains = async () => {
        try {
            const data = await PnlService.getGains();
            setTotalGains(data);
        } catch (err) {}
    };

    const getGlobalEarningBalance = (): number => {
        const total = wallets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "fiat" || asset.type === "crypto"
                        ? asset.wallet.staking_solde * asset.current_price
                        : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };

    const getGlobalBalance = (): number => {
        const total = wallets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "fiat" || asset.type === "crypto"
                        ? asset.wallet.solde
                        : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };

    const getCardBalance = (): number => {
        const total = wallets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current = 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };

    const totalGainsFiat = totalGains.fiat;
    const totalGainsCrypto = totalGains.crypto;

    const principalAccount = getPrincipalAccount();

    const principalBalance = useMemo(() => {
        return principalAccount ? principalAccount.wallet.solde : 0;
    }, [principalAccount]);

    const totalFiat = useMemo(() => {
        return wallets.reduce(
            (
                prevTotal: { earning: number; solde: number },
                asset: UserCryptoWallet,
            ) => {
                if (asset.type !== "fiat") return prevTotal;

                const values = useWalletValue(asset, userCurrency);

                return {
                    earning: prevTotal.earning + values.earning.value,
                    solde:
                        prevTotal.solde +
                        UtilsService.formatDecimal(values.solde.value, 2),
                };
            },
            {
                earning: 0,
                solde: 0,
            },
        );
    }, [wallets, userCurrency]);

    const totalCrypto = useMemo(() => {
        return wallets.reduce(
            (
                prevTotal: { earning: number; solde: number },
                asset: UserCryptoWallet,
            ) => {
                if (asset.type !== "crypto") return prevTotal;

                const values = useWalletValue(asset, userCurrency);

                return {
                    earning: prevTotal.earning + values.earning.value,
                    solde: prevTotal.solde + values.solde.value,
                };
            },
            {
                earning: 0,
                solde: 0,
            },
        );
    }, [wallets, userCurrency]);

    useEffect(() => {
        if (nbCall === 0) {
            refreshAssets();
            getGains();
        }
    }, [nbCall]);

    const assets = useMemo(() => {
        return wallets.filter((elt) => {
            if (elt.type === "crypto") return true;
            if (
                elt.currency === userCurrency.currency ||
                elt.abbreviation === userCurrency.currency
            )
                return true;
            if (
                (user!.fiats || []).includes(elt.currency) ||
                (user!.fiats || []).includes(elt.abbreviation)
            )
                return true;

            return false;
        });
    }, [wallets, userCurrency, user?.fiats]);

    return (
        <AssetsContext.Provider
            value={{
                walletSynchronisation,
                assets,
                refreshAssets,
                getGlobalBalance,
                getFiatBalance,
                getCardBalance,
                getCryptoBalance,
                refreshed: nbCall,
                currentAsset: asset,
                setCurrentAsset: setAsset,
                filter,
                setFilter,
                reload,
                setReload,
                getPrincipalAccount,
                getCryptoEarningBalance,
                getFiatEarningBalance,
                getGlobalEarningBalance,
                getFiatDailyEarn,

                totalGainsFiat,
                totalGainsCrypto,
                analytics: {
                    crypto: {
                        earning: totalCrypto.earning,
                        solde: totalCrypto.solde,
                    },
                    fiat: {
                        earning: totalFiat.earning,
                        solde: totalFiat.solde,
                    },
                    principalBalance,
                },
            }}
        >
            {children}
        </AssetsContext.Provider>
    );
}

export const useAssets = () => useContext(AssetsContext);
