import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AppProvider from "./App/Provider/AppProvider";
import * as serviceWorker from "./serviceWorker";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.REACT_APP_BASE_NAME || "/"}>
            <AppProvider>
                <App />
            </AppProvider>
        </BrowserRouter>
    </React.StrictMode>,
);
serviceWorker.register();

// ReactDOM.hydrate(
//     <BrowserRouter basename={process.env.REACT_APP_BASE_NAME || "/"}>
//         <App />
//     </BrowserRouter>,
//     document.getElementById("root"),
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
