import React from "react";

const SubTitle = ({ id, title }: { id: string; title: string }) => {
    return (
        <h2
            id={id}
            data-scrollspy-target=""
            className="text-lg font-[650] text-slate-800 dark:text-slate-200  scroll-mt-24 "
        >
            {title}
        </h2>
    );
};

export const SubTitle2 = ({ title }: { title: string }) => {
    return (
        <h3 className="text-sm font-[400] text-slate-800 dark:text-slate-200  scroll-mt-24 ">
            {title}
        </h3>
    );
};

export const SubTitle3 = ({ title }: { title: string }) => {
    return (
        <h4 className="text-sm font-[650] text-slate-800 dark:text-slate-200  scroll-mt-24 ">
            {title}
        </h4>
    );
};

export default SubTitle;
