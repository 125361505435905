"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import en from "../../translation/translate.en.json";
import fr from "../../translation/translate.fr.json";
import es from "../../translation/translate.es.json";
import de from "../../translation/translate.de.json";
import pt from "../../translation/translate.pt.json";
import it from "../../translation/translate.it.json";
import pl from "../../translation/translate.pl.json";
import nl from "../../translation/translate.nl.json";
import ro from "../../translation/translate.ro.json";
import cs from "../../translation/translate.cs.json";
import hu from "../../translation/translate.hu.json";
import sv from "../../translation/translate.sv.json";
import el from "../../translation/translate.el.json";
import bg from "../../translation/translate.bg.json";
import da from "../../translation/translate.da.json";
import fi from "../../translation/translate.fi.json";
import sk from "../../translation/translate.sk.json";
import hr from "../../translation/translate.hr.json";
import lt from "../../translation/translate.lt.json";
import sl from "../../translation/translate.sl.json";
import lv from "../../translation/translate.lv.json";
import ee from "../../translation/translate.ee.json";
export enum Language {
    UND = "undefined",
    FR = "fr",
    EN = "en",
    ES = "es",
    DE = "de",
    PT = "pt",
    IT = "it",
    PL = "pl",
    NL = "nl",
    RO = "ro",
    CS = "cs",
    HU = "hu",
    SV = "sv",
    EL = "el",
    BG = "bg",
    DA = "da",
    FI = "fi",
    SK = "sk",
    HR = "hr",
    LT = "lt",
    SL = "sl",
    LV = "lv",
    EE = "ee",
}

export interface AppLanguage {
    language: Language;
    translations: any;
    defaultTranslations: any;
}

let localLanguage = Language.UND;
if (typeof localStorage !== "undefined") {
    const storedLanguage = localStorage.getItem("AppLanguage");

    if (storedLanguage !== null) {
        localLanguage = storedLanguage as Language;
    } else {
        if (typeof navigator !== "undefined") {
            const langue = navigator.language;
            if (langue.length > 0) {
                const split_langue = langue.split("-");
                if (
                    split_langue[0] &&
                    Object.values(Language).includes(
                        split_langue[0].toLowerCase() as Language,
                    )
                ) {
                    localLanguage = split_langue[0].toLowerCase() as Language;
                    localStorage.setItem("AppLanguage", localLanguage);
                }
            }
        }
    }
}

const language = localLanguage ? localLanguage : Language.UND;

const getTranslations = (lang: Language) => {
    switch (lang) {
        case Language.EN:
            return en;

        case Language.ES:
            return es;

        case Language.DE:
            return de;

        case Language.PT:
            return pt;

        case Language.IT:
            return it;

        case Language.PL:
            return pl;

        case Language.NL:
            return nl;

        case Language.RO:
            return ro;

        case Language.CS:
            return cs;

        case Language.HU:
            return hu;

        case Language.SV:
            return sv;

        case Language.EL:
            return el;

        case Language.BG:
            return bg;

        case Language.DA:
            return da;

        case Language.FI:
            return fi;

        case Language.SK:
            return sk;

        case Language.HR:
            return hr;

        case Language.LT:
            return lt;

        case Language.SL:
            return sl;

        case Language.LV:
            return lv;

        case Language.EE:
            return ee;

        default:
            return fr;
    }
};
const translations = getTranslations(language);

const initialState: AppLanguage = {
    language: language,
    translations: translations,
    defaultTranslations: en,
};

const LanguageSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        ChangeAppLanguage(
            state,
            action: PayloadAction<{ language: Language }>,
        ) {
            const language = action.payload.language;

            if (typeof localStorage !== "undefined") {
                localStorage.setItem("AppLanguage", language);
            }
            state.language = language;
            state.translations = getTranslations(language);
        },
    },
});

export const { ChangeAppLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;
