import React from "react";
import "animate.css";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import UtilsService from "../../../Services/UtilsService";
import LogoutSvg from "../Svg/LogoutSvg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { UserType } from "../../../Models/User";
import PartenarUser from "../../../Models/UserPartenar";
import { useJoinAccount } from "../../../App/Provider/JoinAccountProvider";
import alert_message from "../../../Services/Alert/AlertMessage";
import CopySvg from "../Svg/CopySvg";
import UserRoundCogDropdownSvg from "../Svg/UserRoundCogDropdownSvg";

const Dropdown = () => {
    const { user, isAdmin } = useAuthentication();
    const { joinParents } = useJoinAccount();
    const { translate } = translator();
    const [show, setShow] = useState<boolean>(false);

    const user_pics =
        user && user.photo
            ? UtilsService.getPhotoUrl(user.photo)
            : UtilsService.getPulicImage("/dist/image/account.webp");

    const join_account_pics = UtilsService.getPulicImage(
        "/dist/image/joint-account.webp",
    );
    const is_minor =
        user && UtilsService.isMinor(new Date(user.date_of_birth || ""));
    const tooltipRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target as Node)
            ) {
                setShow(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]);

    const handleCloseTooltip = () => {
        setShow(false);
    };
    return (
        <div className="w-8 h-8 ">
            <NavLink to="/settings" className="border-none use-tooltip-profil">
                <img
                    alt="User Dropdown"
                    className="rounded-full w-8 h-8 object-fill"
                    src={
                        user && user.type === UserType.JOIN
                            ? join_account_pics
                            : user_pics
                    }
                />
            </NavLink>
        </div>
    );
};

export default Dropdown;
