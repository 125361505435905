import React from "react";
import Select from "react-tailwindcss-select";
import { Option as DOption } from "react-tailwindcss-select/dist/components/type";

import translator from "../../../Components/Extra/Translation/Translate";
import { useState } from "react";
import SaveBouton from "../../../Components/Extra/Bouton/SaveBouton";
import { IAmbassador } from "../../../Models/User";
import AmbassadorService from "../../../Services/Ambassador/AmbassadorService";
import alert_message from "../../../Services/Alert/AlertMessage";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";
import { NavLink, useParams } from "react-router-dom";

const bg = "/dist/image/bg-top.webp";
const Ambassadeurs = "/dist/image/Ambassadeurs.webp";
const phone = "/dist/image/App-Phone-1.webp";
const phone2 = "/dist/image/App-Phone-2.webp";
const img1 = "/dist/image/Join.webp";
const imgJ = "/dist/image/Personnal.webp";
const b33 = "/dist/image/Laptop1.webp";
const imgunder2 = "/dist/image/Childs.webp";

const Referrals = () => {
    const { translate } = translator();
    const { lang } = useParams();

    return (
        <>
            {/* First section */}
            <section className="text-lg text-[white] font-medium break-words">
                <HelmetComponent
                    title={translate("Settings", "Affiliation")}
                    description={translate("SEO", "Desc_Ambassador")}
                    link="/referral"
                    keywords={translate("SEO", "Keys_Ambassador")}
                    imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                    largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                    addPostfixTitle={true}
                    noIndex={true}
                ></HelmetComponent>
                <div
                    className=" h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat  flex justify-center items-center text-white"
                    style={{ backgroundImage: `url(${bg})` }}
                >
                    <div className="md:!pt-[50px] pt-[40px] w-[90%] m-auto">
                        <h1 className="pt-5 md:!text-3xl text-2xl font-bold tracking-wide text-center">
                            {translate("Settings", "Affiliation")}
                        </h1>
                        <p className="md:!text-xl text-center tracking-wider text-lg pt-5">
                            {translate("Affiliation", "Invite_With_Friends")}
                        </p>
                    </div>
                </div>
            </section>

            {/* Second section */}
            <section className="bg-white">
                <div className=" -z-40 lg:grid w-[90%] max-w-7xl mx-auto my-[125px] ">
                    <div className=" lg:flex justify-between grid">
                        <div
                            className=" lg:w-[46%] my-auto"
                            data-aos="fade-right"
                            data-aos-offset="200"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="800"
                        >
                            <div className="text-lg my-8">
                                <h2 className=" md:!text-3xl text-2xl font-bold">
                                    {translate("Affiliation", "Refer_Win")}
                                </h2>
                                <p className=" my-3 font-normal">
                                    {translate(
                                        "Affiliation",
                                        "As_An_Ambassador_You_Have",
                                    )}
                                </p>
                                <p className="font-normal">
                                    {translate(
                                        "Affiliation",
                                        "Join_Us_Now_To_Become",
                                    )}
                                </p>
                            </div>
                        </div>
                        <div
                            className=" lg:w-[46%] "
                            data-aos="fade-left"
                            data-aos-offset=""
                            data-aos-easing=""
                            data-aos-duration="800"
                        >
                            <div className=" ">
                                <img
                                    className=" rounded-xl"
                                    src={Ambassadeurs}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-[#F1F5F9] py-28  ">
                <div className=" text-xl text-black -z-40 pb-10 w-[90%] max-w-7xl mx-auto  ">
                    <div className="text-center">
                        <h3 className=" md:!text-3xl text-2xl font-bold ">
                            {translate("Affiliation", "How_it_works")}
                        </h3>
                        <p className="text-lg font-normal">
                            {translate("Affiliation", "How_it_works2")}
                        </p>
                    </div>
                </div>
                <div className="text-black w-[90%] rounded-lg max-w-7xl mx-auto my-5 grid grid-cols-1 lg:!grid-cols-2">
                    <div className="bg-white text-center grid grid-cols-1  gap-y-2 p-5 m-3 rounded-lg">
                        <div className="min-h-[175px]">
                            <div className=" text-sm flex items-center justify-center pt-3 pb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    fill="currentColor"
                                    className="bi bi-credit-card"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"></path>
                                    <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h2a1 1 0 0 1-1-1v-1z"></path>
                                </svg>{" "}
                            </div>
                            <h2 className="text-lg  md:!text-xl font-bold">
                                {translate("HOME_PAGE", "Utilisation")}
                            </h2>
                            <p className=" pt-2 pb-2 text-base md:!text-lg font-normal">
                                {translate("HOME_PAGE", "Utilisation2")}
                            </p>
                        </div>
                    </div>

                    <div className="bg-white text-center grid grid-cols-1  gap-y-2 p-5 m-3 rounded-lg">
                        <div className="min-h-[175px]">
                            <div className=" text-sm flex items-center justify-center  pt-3 pb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-user-round-cog "
                                >
                                    <path d="M2 21a8 8 0 0 1 10.434-7.62" />
                                    <circle cx="10" cy="8" r="5" />
                                    <circle cx="18" cy="18" r="3" />
                                    <path d="m19.5 14.3-.4.9" />
                                    <path d="m16.9 20.8-.4.9" />
                                    <path d="m21.7 19.5-.9-.4" />
                                    <path d="m15.2 16.9-.9-.4" />
                                    <path d="m21.7 16.5-.9.4" />
                                    <path d="m15.2 19.1-.9.4" />
                                    <path d="m19.5 21.7-.4-.9" />
                                    <path d="m16.9 15.2-.4-.9" />
                                </svg>
                            </div>
                            <h2 className="text-lg  md:!text-xl font-bold">
                                {translate("HOME_PAGE", "Suivi")}
                            </h2>
                            <p className=" pt-2 pb-2 text-base md:!text-lg font-normal">
                                {translate("HOME_PAGE", "Suivi2")}
                            </p>
                        </div>
                    </div>

                    <div className="bg-white text-center grid grid-cols-1  gap-y-2 p-5 m-3 rounded-lg">
                        <div className="min-h-[175px]">
                            <div className=" text-sm flex items-center justify-center  pt-3 pb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-pie-chart"
                                >
                                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                                    <path d="M22 12A10 10 0 0 0 12 2v10z" />
                                </svg>{" "}
                            </div>
                            <h2 className="text-lg  md:!text-xl font-bold">
                                {translate("HOME_PAGE", "Coffre")}
                            </h2>
                            <p className=" pt-2 pb-2 text-base md:!text-lg font-normal">
                                {translate("HOME_PAGE", "Coffre2")}
                            </p>
                        </div>
                    </div>

                    <div className="bg-white text-center grid grid-cols-1  gap-y-2 p-5 m-3 rounded-lg">
                        <div className="min-h-[175px]">
                            <div className=" text-sm flex items-center justify-center  pt-3 pb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-shield-check"
                                >
                                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
                                    <path d="m9 12 2 2 4-4" />
                                </svg>{" "}
                            </div>
                            <h2 className="text-lg  md:!text-xl font-bold">
                                {translate("HOME_PAGE", "Sécurisé")}
                            </h2>
                            <p className=" pt-2 pb-2 text-base md:!text-lg font-normal">
                                {translate("HOME_PAGE", "Sécurisé2")}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Second section */}
            <section id="" className="bg-white">
                <div className=" text-xl text-black -z-40 pt-36 pb-36 w-[90%] max-w-7xl mx-auto  ">
                    <div className="md:flex justify-between grid ">
                        <div
                            className=" md:w-[46%]  "
                            data-aos="fade-right"
                            data-aos-offset=""
                            data-aos-easing=""
                            data-aos-duration="800"
                        >
                            <div className="md:!py-0 py-5">
                                <img
                                    className=" h-[400px] min-h-[500px] max-h-[500px] max-w-full align-baseline mx-auto "
                                    src={phone}
                                    alt=""
                                />
                            </div>
                        </div>

                        <div
                            className=" md:w-[46%] my-auto text-lg"
                            data-aos="fade-left"
                            data-aos-offset="100"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="800"
                        >
                            <div className="my-8">
                                <h2 className=" md:!text-3xl text-2xl font-bold pb-5">
                                    {translate("HOME_PAGE", "Open_account")}
                                </h2>
                                <div
                                    className="pt-3 lg:text-lg sm:text-base flex items-center pb-5
                                "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-file-pen mr-2 border-2 border-black rounded-lg p-2 dark:border-black"
                                    >
                                        <path d="M12 22h6a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v10" />
                                        <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                                        <path d="M10.4 12.6a2 2 0 1 1 3 3L8 21l-4 1 1-4Z" />
                                    </svg>{" "}
                                    <p className="ml-5 lg:text-lg sm:text-base font-bold">
                                        {translate("HOME_PAGE", "Sign")}

                                        <br />
                                        <p className="lg:text-lg sm:text-base font-normal">
                                            {translate("HOME_PAGE", "Sign2")}
                                        </p>
                                    </p>
                                </div>

                                <div
                                    className="pt-3 lg:text-lg sm:text-base flex items-center pb-5
                                "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-fingerprint mr-2 border-2 border-black rounded-lg p-2 dark:border-black"
                                    >
                                        <path d="M2 12C2 6.5 6.5 2 12 2a10 10 0 0 1 8 4" />
                                        <path d="M5 19.5C5.5 18 6 15 6 12c0-.7.12-1.37.34-2" />
                                        <path d="M17.29 21.02c.12-.6.43-2.3.5-3.02" />
                                        <path d="M12 10a2 2 0 0 0-2 2c0 1.02-.1 2.51-.26 4" />
                                        <path d="M8.65 22c.21-.66.45-1.32.57-2" />
                                        <path d="M14 13.12c0 2.38 0 6.38-1 8.88" />
                                        <path d="M2 16h.01" />
                                        <path d="M21.8 16c.2-2 .131-5.354 0-6" />
                                        <path d="M9 6.8a6 6 0 0 1 9 5.2c0 .47 0 1.17-.02 2" />
                                    </svg>{" "}
                                    <p className="ml-5 lg:text-lg sm:text-base font-bold">
                                        {translate("HOME_PAGE", "Kyc")}

                                        <br />
                                        <p className="lg:text-lg sm:text-base font-normal">
                                            {translate("HOME_PAGE", "Kyc2")}
                                        </p>
                                    </p>
                                </div>

                                <div
                                    className="pt-3 lg:text-lg sm:text-base flex items-center pb-5
                                "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="1.80"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-user-check-2 mr-2 border-2 border-black rounded-lg p-2 dark:border-black"
                                    >
                                        <path d="M14 19a6 6 0 0 0-12 0" />
                                        <circle cx="8" cy="9" r="4" />
                                        <polyline points="16 11 18 13 22 9" />
                                    </svg>{" "}
                                    <p className="ml-5 lg:text-lg sm:text-base font-bold">
                                        {translate("HOME_PAGE", "Vérif")}

                                        <br />
                                        <p className="lg:text-lg sm:text-base font-normal">
                                            {translate("HOME_PAGE", "Vérif2")}
                                        </p>
                                    </p>
                                </div>

                                <div
                                    className="pt-3 text-lg flex items-center pb-5
                                "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-rocket mr-2 border-2 border-black rounded-lg p-2 dark:border-black"
                                    >
                                        <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
                                        <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
                                        <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
                                        <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
                                    </svg>{" "}
                                    <p className="ml-5 lg:text-lg sm:text-base font-bold">
                                        {translate("HOME_PAGE", "Welcome")}
                                        <br />
                                        <p className="lg:text-lg sm:text-base font-normal">
                                            {translate("HOME_PAGE", "Welcome2")}
                                        </p>
                                    </p>
                                </div>

                                <NavLink
                                    to={`${lang ? `/${lang}` : ""}/register`}
                                    className="btn btn-perfect text-center text-base px-4 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6 dark:border-white"
                                >
                                    {translate(
                                        "HOME_PAGE",
                                        "Create_An_Account",
                                    )}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Referrals;
