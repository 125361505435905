import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Landing from "../../Layouts/Landing/Landing";

import useAuthentication from "../../Services/Authentication/useAuthentication";

interface IProps {
    children?: JSX.Element;
}

export default function AnonymousRouteProvider({ children }: IProps) {
    const { isAuthenticated, user } = useAuthentication();
    const location = useLocation();
    const navigate = useNavigate();
    const { from } = location.state || { from: { pathname: "/dashboard" } };
    const redirection = from !== "/logout" ? from : "/";

    if (
        user &&
        (!user.address || !user.country || !user.phone || !user.date_of_birth)
    ) {
        return <Navigate to="/settings?show_modal=yes" replace />;
    }

    if (!isAuthenticated) {
        return children ? (
            children
        ) : (
            <Landing>
                <Outlet />
            </Landing>
        );
    } else {
        return (
            <Navigate
                to={redirection === "/" ? "/dashboard" : redirection}
                replace
            />
        );
    }
}
