const DataTablePageLength = ({
    perPage,
    setPerPage,
    handlePerRowsChange,
}: {
    perPage: number;
    setPerPage: (page: number) => void;
    handlePerRowsChange?: (newPerPage: number) => void;
}) => {
    const paginations = [10, 25, 50, 100];
    return (
        <select
            className="form-select box mt-3 w-20 sm:mt-0 dark:!border dark:!border-slate-600 dark:!rounded-lg dark:!bg-black"
            onChange={(e) => {
                setPerPage(Number(e.target.value));
                if (handlePerRowsChange)
                    handlePerRowsChange(Number(e.target.value));
            }}
            defaultValue={perPage}
        >
            {paginations.map((length) => {
                return (
                    <option key={`pl-${length}`} value={length}>
                        {length}
                    </option>
                );
            })}
        </select>
    );
};

export default DataTablePageLength;
