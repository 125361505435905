import React from "react";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";
import translator from "../../../Components/Extra/Translation/Translate";

const About = () => {
    const { translate } = translator();
    return (
        <section className=" text-lg text-[#333] font-medium break-words  ">
            <HelmetComponent
                title={translate("SEO", "Title_About")}
                description={translate("SEO", "Desc_About")}
                link="/about"
                keywords={translate("SEO", "Keys_About")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>
            <div
                className="  h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat  flex justify-center items-center text-white"
                style={{ backgroundImage: `url(/dist/image/bg-top.webp)` }}
            >
                <h1 className="pt-10  md:!text-3xl text-2xl font-bold tracking-wide text-center ">
                    {translate("ABOUT_PAGE", "About_Us")}
                </h1>
            </div>
            <div className=" bg-white py-[100px]">
                <div>
                    <div className=" w-[80%] max-w-[1080px] m-auto sm:text-lg py-6 text-base   ">
                        <div className=" pb-10 mb-[36px]    ">
                            <h3 className=" md:!text-3xl text-2xl font-bold pb-[10px] ">
                                {translate("ABOUT_PAGE", "The_Beginning")}
                            </h3>
                            <p className=" text-lg font-normal break-words text-left pb-[18px]  ">
                                {translate(
                                    "ABOUT_PAGE",
                                    "Immersed_In_The_World",
                                )}
                            </p>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate(
                                    "ABOUT_PAGE",
                                    "At_Infinexia_We_Are_Team",
                                )}
                            </p>
                            <p className=" text-lg font-normal ">
                                {translate("ABOUT_PAGE", "Our_Vision")}
                            </p>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate("ABOUT_PAGE", "In_This_Quest")}
                            </p>
                            <p className=" text-lg font-normal pb-[px]">
                                {translate("ABOUT_PAGE", "Thus_At_Infinexia")}
                            </p>
                        </div>

                        <div className=" pb-10 pt-[18px]">
                            <h3 className=" md:!text-3xl text-2xl font-bold pb-[10px]">
                                {translate(
                                    "ABOUT_PAGE",
                                    "Origin_Of_The_Name_Infinexia",
                                )}
                            </h3>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate("ABOUT_PAGE", "The_Name_Infinexia")}
                            </p>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate("ABOUT_PAGE", "Infinexia_Embodies")}
                            </p>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate(
                                    "ABOUT_PAGE",
                                    "Furthermore_The_Word",
                                )}
                            </p>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate(
                                    "ABOUT_PAGE",
                                    "Infinexia_Symbolizes",
                                )}
                            </p>
                            <p className=" text-lg font-normal pb-[18px]">
                                {translate(
                                    "ABOUT_PAGE",
                                    "The_Choice_The_Name_Infinexia",
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
