import React from "react";

const FramerSvg = () => {
    return (
        <span className="rotate-90-m">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="log-out"
                data-lucide="log-out"
                className="lucide lucide-log-out block mx-auto"
            >
                <path d="M9 21H5a2 2 0 01-2-2V5a2 2 0 012-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
            </svg>
        </span>
    );
};

export default FramerSvg;
