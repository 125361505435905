import React from "react";
import { CoOwnerRequest } from "../Models/UserAssetCoOwner";
import UserAssetCoOwnerService from "../Services/UserAssetCoOwner/UserAssetCoOwner";
import alert_message from "../Services/Alert/AlertMessage";
import translator from "../Components/Extra/Translation/Translate";
import { refresh } from "aos";
import { useAssets } from "../App/Provider/AssetsProvider";

export const useCoOwnerRequest = () => {
    const { refreshAssets } = useAssets();
    const { translate } = translator();
    const [coOwnerRequest, setCoOwnerRequest] = React.useState<
        Array<CoOwnerRequest>
    >([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const getAllRequest = async () => {
        setLoading(true);
        try {
            const res = await UserAssetCoOwnerService.getRequest();
            setCoOwnerRequest(res);
            setLoading(false);
        } catch (err: any) {
            alert_message.error(translate("CO_ONWER_REQUEST", err));
            setError(true);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        getAllRequest();
        const interval = setInterval(() => {
            getAllRequest();
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    return {
        coOwnerRequest,
        loading,
        error,
        refresh: () => {
            getAllRequest();
            refreshAssets();
        },
    };
};

export const useCoOwnerRequestAction = (refresh?: () => void) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const { translate } = translator();

    const decline = async (id: number) => {
        setLoading(true);
        try {
            const res = await UserAssetCoOwnerService.declineRequest(id);
            alert_message.success(translate("CO_ONWER_REQUEST", res));
            setLoading(false);
            if (refresh) refresh();
            return true;
        } catch (err: any) {
            alert_message.error(translate("CO_ONWER_REQUEST", err));
            setError(true);
            setLoading(false);
            return false;
        }
    };

    const accept = async (id: number): Promise<boolean> => {
        setLoading(true);
        try {
            const res = await UserAssetCoOwnerService.acceptRequest(id);
            alert_message.success(translate("CO_ONWER_REQUEST", res));
            setLoading(false);
            if (refresh) refresh();

            return true;
        } catch (err: any) {
            alert_message.error(translate("CO_ONWER_REQUEST", err));
            setError(true);
            setLoading(false);

            return false;
        }
    };

    const quit = async (abbreviation: string): Promise<boolean> => {
        setLoading(true);
        try {
            const res = await UserAssetCoOwnerService.quitRequest(abbreviation);
            alert_message.success(translate("CO_ONWER_REQUEST", res));
            setLoading(false);
            if (refresh) refresh();
            return true;
        } catch (err: any) {
            alert_message.error(translate("CO_ONWER_REQUEST", err));
            setError(true);
            setLoading(false);
            return false;
        }
    };

    return { accept, decline, loading, error, quit };
};
