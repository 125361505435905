import React from "react";
import "animate.css";
import { ModalProvider } from "../../../../Extra/Modal/ModalContext";
import BtnStopSubsModal from "./btnStopSubsModal";

const StopSubsModal = ({ id }: { id: string }) => {
    return (
        <>
            <ModalProvider>
                <BtnStopSubsModal id={id} />
            </ModalProvider>
        </>
    );
};

export default StopSubsModal;
