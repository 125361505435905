import React, { useEffect, useState } from "react";
import TransactionService from "../../Services/Wallets/TransactionService";
import MenuListAdmin from "./MenuListAdmin";
import NavAdmin from "./NavAdmin/NavAdmin";
import { OTransactionCount } from "../../Models/Transaction";

const SideAdmin = () => {
    const [transactionCount, setTransactionCount] = useState<
        OTransactionCount | undefined
    >();
    const getTransactionCount = async () => {
        const res = await TransactionService.getAllTransactionsCount();
        setTransactionCount(res);
    };
    useEffect(() => {
        getTransactionCount();
    }, []);
    return (
        <nav className="side-nav mdg-side-nav menu-admin scrollbar-hidden !relative bg-black">
            <ul className="mt-20">
                {MenuListAdmin.map((menu, i) => (
                    <NavAdmin
                        key={i}
                        name={menu.name}
                        icon={menu.icon}
                        path={menu.path}
                        childs={menu.childs}
                        transactionCount={transactionCount}
                    />
                ))}
            </ul>
        </nav>
    );
};

export default SideAdmin;
