import { AnyIfEmpty } from "react-redux";
import {
    IJoinAccountRequestPayload,
    JoinRequestStatus,
    OUserJoinAccountRequest,
} from "../../Models/JoinAccountRequest";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

export interface JoinRequest {
    id: number;
    offeror: {
        first_name: string;
        last_name: string;
        uid: string;
    };
    recipient: {
        first_name: string;
        last_name: string;
        uid: string;
    };
}

const save = async (
    payload: IJoinAccountRequestPayload,
    translate: any,
): Promise<any> => {
    return axios
        .post("/join-account-request/add-join", payload)
        .then((res) => {
            const { data } = res;
            if (data.code === "SUCCESS") {
                return Promise.resolve(
                    translate("BACKEND_RESULT_CODE", data.message),
                );
            }
        })
        .catch((err: any) => {
            return Promise.reject(err.response.data);
        });
};

const getUserJoinRequest =
    async (): Promise<OUserJoinAccountRequest | null> => {
        return axios
            .get(`/join-account-request/user-join-request`)
            .then(({ data }) => {
                return data;
            })
            .catch((err: any) => {
                return Promise.reject(UtilsService.getAxiosErrorMessage(err));
            });
    };

const updateStatus = async (
    status: JoinRequestStatus,
    id: number,
): Promise<string> => {
    return axios
        .post(`/join-account-request/recipient-response`, { status, id })
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const JoinAccountRequestService = {
    save,
    getUserJoinRequest,
    updateStatus,
};

export default JoinAccountRequestService;
