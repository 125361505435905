import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
} from "react";
import User from "../../Models/User";
import UserService from "../../Services/User/UserService";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import { useParams } from "react-router-dom";

interface Account18ContextData {
    accountUid: string | null;
    setAccountUid: (id: string | null) => void;
    setUser: (user: User | undefined) => void;
    user?: User;
    refreshUser: () => void;
}

const Account18Context = createContext<Account18ContextData | undefined>(
    undefined,
);

interface Account18ProviderProps {
    children: React.ReactNode;
}

const Account18Provider: React.FC<Account18ProviderProps> = ({ children }) => {
    const { child_uid } = useParams();
    const [accountUid, setAccountUid] = useState<string | null>(null);

    const [user, setUser] = useState<User | undefined>();
    const getUserInfo = async (uid: string | null) => {
        if (uid) {
            const res = await UserService.getInformationUserByUid(uid);
            if (res.error) {
                return;
            }
            try {
                setUser(res.response.data);
            } catch {}
        }
    };

    const refreshOnDirectAccess = useCallback(async () => {
        if (child_uid && accountUid != child_uid) {
            setUser(undefined);
            getUserInfo(child_uid);
            setAccountUid(child_uid);
        }
    }, [child_uid]);

    const refreshUser = () => {
        getUserInfo(accountUid);
    };

    useEffect(() => {
        refreshOnDirectAccess();
    }, [refreshOnDirectAccess]);

    return (
        <Account18Context.Provider
            value={{ accountUid, setAccountUid, refreshUser, user, setUser }}
        >
            {children}
        </Account18Context.Provider>
    );
};

const useAccount18Context = (): Account18ContextData => {
    const context = useContext(Account18Context);

    if (!context) {
        throw new Error(
            "useAdminUserContext must be used within an AdminUserProvider",
        );
    }

    return context;
};

export { Account18Provider, useAccount18Context };
export type { Account18ContextData };
