import React from "react";

const OpenMenuSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            icon-name="bar-chart-2"
            data-lucide="bar-chart-2"
            className="lucide lucide-bar-chart-2 h-8 w-8 -rotate-90 text-white"
        >
            <line x1="18" y1="20" x2="18" y2="10"></line>
            <line x1="12" y1="20" x2="12" y2="4"></line>
            <line x1="6" y1="20" x2="6" y2="14"></line>
        </svg>
    );
};

export default OpenMenuSvg;
