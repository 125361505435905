import React from "react";
import { ModalContext } from "../../Extra/Modal/ModalContext";
import translator from "../../Extra/Translation/Translate";
import Notice from "../../../Models/Notice";
import { LangType } from "../../../Models/Lang";
import VuSvg from "../Svg/VuSvg";
import MessageSvg from "../Svg/MessageSvg";
import UtilsService from "../../../Services/UtilsService";
import Toggle from "../../Earn/Toggle";
import NoticeService from "../../../Services/Notice/NoticeService";
import async from "react-select/dist/declarations/src/async/index";
import { Language } from "../../../globalState/reducers/Language.reducer";

const NoticeContent = ({
    item,
    lang,
    isView,
}: {
    item: Notice;
    lang: Language;
    isView: boolean;
}) => {
    const { translate } = translator();
    const description = (item as any)[`description_${lang}`]
        ? (item as any)[`description_${lang}`]
        : item.description;

    const title = (item as any)[`title_${lang}`]
        ? (item as any)[`title_${lang}`]
        : item.title;
    // if (isView == false) {
    // await NoticeService.addUserInformation(Number(item.id));
    // }
    const createMarkup = () => {
        return {
            // Utilisez un opérateur ternaire pour déterminer la description en fonction de la langue
            __html: description,
        };
    };

    return (
        <>
            <div className="text-lg text-center uppercase">
                {translate("Admin_Dashboard", "Important_Notes")}
            </div>
            <div className="flex items-center justify-center mt-3">
                {item.photo && (
                    <div className="w-12 h-12 p-1">
                        <img
                            src={item.photo}
                            alt=""
                            className="object-cover h-full rounded-lg"
                        />
                    </div>
                )}
                <div className="font-bold ml-1 text-base">{title}</div>
            </div>
            <div className="p-5  block mt-4 mb-2.5">
                <div
                    className="text-justify"
                    dangerouslySetInnerHTML={createMarkup()}
                ></div>
            </div>
        </>
    );
};

const InfoViewModal = ({
    item,
    lang,
    refresh,
}: {
    item: any;
    lang: Language;
    refresh: () => void;
}) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    const TextWithEllipsis = (text: string, maxLength: number) => {
        if (text.length <= maxLength) {
            return text;
        } else {
            const truncatedText = text.slice(0, maxLength) + "...";
            return truncatedText;
        }
    };

    const parseAndTruncateHTML = (htmlContent: string, maxLength: number) => {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(htmlContent, "text/html");
        const plainText = parsedDocument.body.textContent || "";
        return TextWithEllipsis(plainText, maxLength);
    };

    const description = (item.notice as any)[`description_${lang}`]
        ? (item.notice as any)[`description_${lang}`]
        : item.notice.description;

    const title = (item.notice as any)[`title_${lang}`]
        ? (item.notice as any)[`title_${lang}`]
        : item.notice.title;

    return (
        <div
            className={`${
                !!item.userInfo
                    ? "bg-view p-2 rounded-b-lg rounded-r-lg cursor-pointer mb-2"
                    : "bg-not-view p-2 rounded-b-lg rounded-r-lg cursor-pointer mb-2"
            }`}
            role="button"
            onClick={async () => {
                if (item.userInfo == false) {
                    await NoticeService.addUserInformation(item.notice.id);
                    refresh();
                }
                handleModal(
                    <NoticeContent
                        item={item.notice}
                        lang={lang}
                        isView={item.userInfo}
                    />,
                );
            }}
            title={translate("Notification", "See")}
        >
            <div className="flex justify-between">
                <div>
                    <div className="inline-flex text-xs font-medium items-center">
                        {!!item.userInfo ? <VuSvg /> : <MessageSvg />}
                        &nbsp;
                        {title}
                    </div>
                    <div
                        className={
                            !Toggle
                                ? "block truncate mt-2 text-xs text-left"
                                : "block mt-2 text-xs text-left"
                        }
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: parseAndTruncateHTML(description, 50),
                            }}
                        />
                    </div>
                </div>
                <div className="text-xs self-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-eye"
                    >
                        <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                        <circle cx="12" cy="12" r="3" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default InfoViewModal;
