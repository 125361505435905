import React from "react";
import { NavLink } from "react-router-dom";
import UtilsService from "../../Services/UtilsService";

const SideMobile = () => {
    return (
        <div className="mobile-menu md:hidden">
            <div className="mobile-menu-bar">
                <NavLink
                    to="/"
                    className="mr-auto flex sm:mt-2.5 sm:pl-1 md:mt-0 md:pl-0 lg:mt-0 lg:pl-0 xl:mt-0 xl:pl-0 2xl:mt-0 2xl:pl-0"
                >
                    <img
                        alt="Logo Infinexia"
                        className="w-[50%]"
                        src={UtilsService.getPulicImage(
                            "/dist/image/Logo-Infinexia.webp",
                        )}
                    />
                </NavLink>
            </div>
        </div>
    );
};

export default SideMobile;
