export interface IGovernanceVote {
    governance_id: number;
    status?: boolean;
}

export default class GovernanceVote {
    id!: number;
    status!: boolean;
    created_at?: Date;
    updated_at?: Date;
    governanceId?: number;

    constructor(id: number, status: boolean) {
        this.status = status;
        this.id = id;
    }
}
