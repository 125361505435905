import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import useAuthentication from "../Services/Authentication/useAuthentication";
import Transaction, { WalletHistoryType } from "../Models/Transaction";
import UtilsService, { formatAmount } from "../Services/UtilsService";

import "../Components/Transactions/Print/pdf.css";
import { useJoinAccount } from "./Provider/JoinAccountProvider";
import PartenarUser from "../Models/UserPartenar";
interface ContextProps {
    transactions: any[];
    setTransaction: (transactions: any) => any;
    print: () => any;
    setDates: (dates: any) => any;
}
const TransactionItem = ({ transaction }: { transaction: Transaction }) => {
    const { user } = useAuthentication();
    const type = transaction.type;
    const includedValues = [
        WalletHistoryType.WITHDRAW,
        WalletHistoryType.WITHDRAW_MANUAL,
        WalletHistoryType.WITHDRAW_CRYPTO,
        WalletHistoryType.WITHDRAW_KALYPAY,
        WalletHistoryType.TRANSFERT_OUT,
        WalletHistoryType.SWAPOUT,
        WalletHistoryType.ORDER_PACKBOOSTER,
        WalletHistoryType.PURCHASE_FIDELITY,
    ];
    const operation = `${
        !includedValues.includes(type) ? "+ " : "- "
    } ${formatAmount(
        transaction.amount,
        transaction.user_wallet.type === "crypto" ? 8 : 2,
    )}`;
    let debit = "";
    let credit = "";
    if (includedValues.includes(type)) {
        // debit = `${transaction.amount}`;
        debit = UtilsService.getConvertedFormatedAmount(
            transaction.amount,
            user?.base_currency,
        );
    } else {
        // credit = `${transaction.amount}`;
        credit = UtilsService.getConvertedFormatedAmount(
            transaction.amount,
            user?.base_currency,
        );
    }
    return (
        <tr key={transaction.id}>
            <td className="text-center">{transaction.date_fr}</td>
            <td className="text-center">{transaction.description}</td>
            <td className="text-center">{debit}</td>
            <td className="text-center">{credit}</td>
        </tr>
    );
};
const ExportPdfContext = createContext<ContextProps>({
    transactions: [],
    setTransaction: (transactions: any) => [],
    print: () => {
        console.log("");
    },
    setDates: (dates: any) => [],
});

export default function ExportPdfProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const componentRef = useRef(null);
    const [transactions, setTransaction] = useState<any[]>([]);
    const [dates, setDates] = useState<any>({
        endDate: undefined,
        startDate: undefined,
    });
    const { user, isJoin } = useAuthentication();
    const { joinParents } = useJoinAccount();

    const { debit, credit, total } = useMemo(() => {
        let totalCredit = 0;
        let totalDebit = 0;
        for (const trans of transactions) {
            const type = trans.type;
            const includedValues = [
                WalletHistoryType.WITHDRAW,
                WalletHistoryType.WITHDRAW_MANUAL,
                WalletHistoryType.WITHDRAW_CRYPTO,
                WalletHistoryType.WITHDRAW_KALYPAY,
                WalletHistoryType.TRANSFERT_OUT,
                WalletHistoryType.SWAPOUT,
                WalletHistoryType.ORDER_PACKBOOSTER,
                WalletHistoryType.PURCHASE_FIDELITY,
            ];
            if (includedValues.includes(type)) {
                totalDebit = totalDebit + trans.usd_price;
            } else {
                totalCredit = totalCredit + trans.usd_price;
            }
        }

        return {
            debit: UtilsService.getConvertedFormatedAmount(
                totalDebit,
                user?.base_currency,
            ),
            credit: UtilsService.getConvertedFormatedAmount(
                totalCredit,
                user?.base_currency,
            ),
            total: UtilsService.getConvertedFormatedAmount(
                totalCredit - totalDebit,
                user?.base_currency,
            ),
        };
    }, [transactions.length]);

    const handlePrint = () => {
        const printDiv = document.getElementById("id-printable-element");
        if (printDiv) {
            printDiv.classList.remove("hidden");
            import("print-js").then((module) => {
                const printJS = module.default;

                printJS({
                    printable: "id-printable-element",
                    type: "html",
                    documentTitle: "Transactions",
                    headerStyle: "font-weight: 100;",
                    css: "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css",
                });
                printDiv.classList.add("hidden");
            });
        }
    };

    useEffect(() => {
        const linkElement = document.createElement("link");
        linkElement.rel = "stylesheet";
        // linkElement.href = UtilsService.getPulicImage("/dist/table.css");
        linkElement.href = UtilsService.getPulicImage("/dist/table.css");
        if (componentRef.current)
            (componentRef.current as any).appendChild(linkElement);
    }, []);

    return (
        <ExportPdfContext.Provider
            value={{
                transactions,
                setTransaction,
                print: handlePrint,
                setDates,
            }}
        >
            {children}
            <div
                id="id-printable-element"
                className="container hidden  "
                ref={componentRef}
            >
                <div
                    className="row"
                    style={{ display: "flex", height: "195px" }}
                >
                    <div
                        className="col-lg-6 "
                        style={{ display: "block", width: "475px" }}
                    >
                        <div style={{ height: "60px" }}>
                            <img
                                style={{
                                    maxWidth: "200px",
                                    maxHeight: "60px",
                                    marginBottom: "25px",
                                    marginLeft: "-10px",
                                }}
                                src={UtilsService.getPulicImage(
                                    "/dist/image/Logo-Infinexia-Full.png",
                                )}
                                alt=""
                            />
                        </div>

                        <div className="company" style={{ height: "110px" }}>
                            <div>The old station house</div>
                            <div>15A Main Street Blackrock</div>
                            <div>Co. Dublin, A94 T8P8</div>
                            <div>Irland</div>
                        </div>
                    </div>

                    <div
                        className="col-lg-6"
                        style={{
                            display: "block",
                            marginRight: "20px",
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    height: "60px",
                                }}
                            >
                                <b
                                    style={{
                                        fontSize: "25px",
                                        fontWeight: 1000,
                                    }}
                                >
                                    {" "}
                                    RELEVÉ DE COMPTE
                                </b>
                                {dates.startDate && dates.endDate ? (
                                    <div>
                                        du mois{" "}
                                        {UtilsService.getBeautifulFormatedDateFr(
                                            dates.startDate,
                                        )}{" "}
                                        au{" "}
                                        {UtilsService.getBeautifulFormatedDateFr(
                                            dates.endDate,
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        du mois{" "}
                                        {UtilsService.getBeautifulFormatedDateFr(
                                            new Date(),
                                        )}
                                    </div>
                                )}
                            </div>

                            {isJoin ? (
                                <div
                                    className="user-name"
                                    style={{
                                        maxWidth: "250px",
                                        lineHeight: "1.2",
                                        height: "105px",
                                    }}
                                >
                                    {joinParents.map((elt: PartenarUser) => (
                                        <div>
                                            {" "}
                                            {elt.first_name +
                                                " " +
                                                elt.last_name}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <>
                                    <div
                                        className="user-name"
                                        style={{
                                            maxWidth: "250px",

                                            height: "105px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "block",
                                            }}
                                        >
                                            {user?.first_name +
                                                " " +
                                                user?.last_name}
                                        </div>
                                        <div
                                            style={{
                                                display: "block",
                                            }}
                                        >
                                            {user?.address || ""}
                                        </div>
                                        <div
                                            style={{
                                                display: "block",
                                            }}
                                        >
                                            {user?.post_code || ""}{" "}
                                            {user?.city || ""}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className="row"
                    style={{ display: "flex", height: "75px" }}
                >
                    <div
                        className="col-lg-6 "
                        style={{ display: "block", width: "475px" }}
                    >
                        <div>
                            <div style={{ height: "20px" }}>{""}</div>
                            <div>N° de compte {user?.uid}</div>
                            <div>
                                Monnaie du compte:{" "}
                                {user?.base_currency?.currency}
                            </div>
                        </div>
                    </div>

                    <div
                        className="col-lg-6"
                        style={{
                            display: "block",
                            marginRight: "20px",
                            width: "250px",
                        }}
                    >
                        <div>
                            <div>
                                Total des credits :{" "}
                                <b>
                                    {/* {UtilsService.formatAmount(debit, 2)} $ */}
                                    {debit}
                                </b>
                            </div>
                            <div>
                                Total des debits :{" "}
                                <b>
                                    {/* {UtilsService.formatAmount(credit, 2)} $ */}
                                    {credit}
                                </b>
                            </div>
                            <div>
                                Solde de cloture :{" "}
                                <b>
                                    {/* {UtilsService.formatAmount(
                                            credit - debit,
                                            2,
                                        )}{" "}
                                        $ */}
                                    {total}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="place-here"></div>

                <div className="row" style={{ marginTop: "10px" }}>
                    <table
                        id="id-transaction"
                        className="table table-bordered table-striped"
                        style={{ width: "100%", height: "auto" }}
                    >
                        <thead
                            className="thead-dark"
                            style={{ borderBottom: "1px solid black" }}
                        >
                            <tr style={{ fontSize: "16px" }}>
                                <th
                                    scope="col"
                                    className="text-center"
                                    style={{ width: "10%" }}
                                >
                                    DATE
                                </th>
                                <th
                                    scope="col"
                                    className="text-center"
                                    style={{ width: "60%" }}
                                >
                                    OPÉRATIONS
                                </th>
                                <th
                                    scope="col"
                                    className="text-center"
                                    style={{ fontSize: "50px", width: "10%" }}
                                >
                                    DÉBIT
                                </th>
                                <th
                                    scope="col"
                                    className="text-center"
                                    style={{ fontSize: "50px", width: "10%" }}
                                >
                                    CRÉDIT
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.length > 0 ? (
                                transactions.map(
                                    (
                                        transaction: Transaction,
                                        index: number,
                                    ) => (
                                        <TransactionItem
                                            transaction={transaction}
                                            key={index}
                                        />
                                    ),
                                )
                            ) : (
                                <tr style={{ border: "1px solid" }}>
                                    <td></td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            fontWeight: "1000",
                                            fontSize: "21px",
                                        }}
                                    >
                                        Aucune opération durant cette période
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </ExportPdfContext.Provider>
    );
}

export const usePdfExport = () => useContext(ExportPdfContext);
