import { useBaseCurrency } from "../App/Provider/BaseCurrencyProvider";
import useAuthentication from "../Services/Authentication/useAuthentication";
import { roundTo } from "./useWalletValue";

export type Currency = {
    symbol: string;
    unit: number;
    currency: string;
};

export function useCurrencySwitcher() {
    const { base } = useBaseCurrency();

    const usdCurrency: Currency = {
        symbol: "$",
        unit: 1,
        currency: "USD",
    };

    const { user } = useAuthentication();

    const userCurrency =
        user && user.base_currency ? (user.base_currency as Currency) : base;

    const formatWithCurrency = ({
        value,
        currency,
        minimumPrecision = 2,
        maximumPrecision = 2,
    }: {
        value: number;
        currency: string;
        minimumPrecision?: number;
        maximumPrecision?: number;
    }) => {
        return value
            .toLocaleString(undefined, {
                style: "currency",
                currency: currency,
                maximumFractionDigits: minimumPrecision,
                minimumFractionDigits: maximumPrecision,
                roundingMode: "floor" as any,
            } as any)
            .replace("US", "");
    };

    const convert = ({
        amount,
        from,
        to,
        precision = 2,
    }: {
        amount: number;
        from: Currency;
        to: Currency;
        precision?: number;
    }) => {
        let value = amount;
        if (!(from.currency === to.currency || from.unit === to.unit)) {
            value = amount * (from.unit / to.unit);
        }

        return roundTo(value, precision);
    };

    const convertAndFormat = ({
        amount,
        from,
        to,
        maximumPrecision = 2,
        minimumPrecision = 2,
    }: {
        amount: number;
        from: Currency;
        to: Currency;
        maximumPrecision: number;
        minimumPrecision: number;
    }) => {
        const converted = convert({
            amount,
            from,
            to,
            precision: maximumPrecision,
        });
        return formatWithCurrency({
            value: converted,
            currency: to.currency,
            maximumPrecision: maximumPrecision,
            minimumPrecision: minimumPrecision,
        });
    };

    const convertFromBase = ({
        amount,
        to,
        precision,
    }: {
        amount: number;
        to: Currency;
        precision: number;
    }) => {
        return convert({
            amount,
            from: base,
            to,
            precision,
        });
    };

    const convertAndFormatFromBase = ({
        amount,
        to,
        maximumPrecision = 2,
        minimumPrecision = 2,
    }: {
        amount: number;
        to: Currency;
        maximumPrecision?: number;
        minimumPrecision?: number;
    }) => {
        const converted = convertFromBase({
            amount,
            to: to,
            precision: maximumPrecision,
        });
        return formatWithCurrency({
            value: converted,
            currency: to.currency,
            maximumPrecision: maximumPrecision,
            minimumPrecision: minimumPrecision,
        });
    };

    const convertAndFormatFromBaseToUser = ({
        amount,
        precision = 2,
    }: {
        amount: number;
        precision?: number;
    }) => {
        return convert({ amount, from: base, to: userCurrency, precision });
    };

    const convertAndFormatFromUBaseToUserWithCurrency = ({
        amount,
        maximumPrecision = 2,
        minimumPrecision = 2,
    }: {
        amount: number;
        maximumPrecision?: number;
        minimumPrecision?: number;
    }) => {
        const value = convertAndFormatFromBaseToUser({
            amount,
            precision: maximumPrecision,
        });
        return formatWithCurrency({
            value,
            currency: userCurrency.currency,
            maximumPrecision,
            minimumPrecision,
        });
    };

    const convertFormUsdToBase = (amount: number, precision = 2) => {
        return convert({
            amount,
            from: usdCurrency,
            to: base,
            precision,
        });
    };

    const convertFromUsdToUserCurrency = (amount: number, precision = 2) => {
        return convert({
            amount,
            from: usdCurrency,
            to: userCurrency,
            precision,
        });
    };

    const convertFromUsdToUserCurrencyWithCurrency = (
        amount: number,
        precision = 2,
    ) => {
        const value = convert({
            amount,
            from: usdCurrency,
            to: userCurrency,
            precision,
        });

        return formatWithCurrency({
            value,
            currency: userCurrency.currency,
            maximumPrecision: precision,
        });
    };

    const conversionRate = (fromUsd: number, toUsd: number) => {
        return fromUsd / toUsd;
    };

    return {
        formatWithCurrency,
        convert,
        convertAndFormat,
        convertAndFormatFromBase,
        base,
        userCurrency,
        convertAndFormatFromBaseToUser,
        convertAndFormatFromUBaseToUserWithCurrency,
        convertFormUsdToBase,
        convertFromUsdToUserCurrency,
        convertFromUsdToUserCurrencyWithCurrency,
        conversionRate,
    };
}
