import React, { useState } from "react";
import Governance from "../../Models/Governance";
import GovernanceVote, { IGovernanceVote } from "../../Models/GovernanceVote";
import GovernanceVoteService from "../../Services/GovernanceVoteService/GovernanceVoteService";
import alert_message from "../../Services/Alert/AlertMessage";
import UtilsService from "../../Services/UtilsService";
import translator from "../Extra/Translation/Translate";
import { LangType } from "../../Models/Lang";

const GouvernanceItems = ({
    governance,
    refresh,
    // ableToVote,
    userVote,
    lang,
}: {
    governance: Governance;
    refresh: () => void;
    // ableToVote: boolean;
    userVote: GovernanceVote[];
    lang: LangType;
}) => {
    const { translate } = translator();
    const [seeDescr, setSeeDescr] = useState<boolean>(false);
    const allvote = governance.vote
        ? governance.vote.length +
          governance.default_no +
          governance.default_yes
        : governance.default_no + governance.default_yes;

    const forVote = governance.vote
        ? governance.vote.filter((vote: GovernanceVote) => vote.status === true)
              .length + governance.default_yes
        : governance.default_yes;

    const forPercent =
        allvote > 0 ? UtilsService.getPercentValue(forVote, allvote) : 0;
    const againstPercent = allvote > 0 ? 100 - forPercent : 0;

    const createMarkup = () => {
        return {
            __html:
                lang == LangType.FR
                    ? governance.description
                    : lang == LangType.EN
                      ? governance.description_en
                      : governance.description_es,
        };
    };

    const handleVote = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        status: boolean,
    ) => {
        event.preventDefault();
        const payload: IGovernanceVote = {
            governance_id: governance.id,
            status: status,
        };

        const res = GovernanceVoteService.createOrUpdate(payload, translate);

        alert_message.promise("...", res).then(() => refresh());
    };

    const voted = userVote.filter(
        (elt: GovernanceVote) => elt.governanceId === governance.id,
    );

    const expiredDate = new Date(governance.end_date);
    const isExpired = new Date() > expiredDate;

    const show_button = voted && voted.length > 0 ? false : true;
    return (
        <div className="intro-y box col-span-12">
            <div className="p-4 block w-full">
                <div className="md-flex w-full items-center">
                    <div className="block md-w-75 p-2">
                        <div className="md-flex items-center justify-between">
                            <div className="text-lg font-medium block">
                                {lang == LangType.FR
                                    ? governance.title
                                    : lang == LangType.EN
                                      ? governance.title_en
                                      : governance.title_es}
                                <div className="flex text-base items-center">
                                    {translate("Governance", "Date_vote")}
                                    <div className="ml-2 text-sm text-slate-500 mt-0.5">
                                        {translate("Governance", "Date_du")}
                                        {UtilsService.getBeautifulFormatedDateFr(
                                            new Date(governance.start_date),
                                        )}
                                    </div>
                                    <div className="ml-2 text-sm text-slate-500 mt-0.5">
                                        {translate("Governance", "Date_au")}
                                        {UtilsService.getBeautifulFormatedDateFr(
                                            new Date(governance.end_date),
                                        )}
                                    </div>
                                </div>
                                <div className="text-sm text-slate-500">
                                    {lang == LangType.FR
                                        ? governance.subtitle
                                        : lang == LangType.EN
                                          ? governance.subtitle_en
                                          : governance.subtitle_es}
                                </div>{" "}
                                {isExpired && (
                                    <div className="text-sm text-red-500">
                                        {translate(
                                            "Governance",
                                            "Voting_period_ended",
                                        )}{" "}
                                        :{" "}
                                        {UtilsService.getBeautifulFormatedDateFr(
                                            expiredDate,
                                        )}
                                    </div>
                                )}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn bg-transparent !border-none flex items-center"
                                    onClick={() => setSeeDescr(!seeDescr)}
                                >
                                    {seeDescr ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="lucide lucide-chevron-up"
                                        >
                                            <polyline points="18 15 12 9 6 15" />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="lucide lucide-chevron-down"
                                        >
                                            <polyline points="6 9 12 15 18 9" />
                                        </svg>
                                    )}
                                    Description
                                </button>
                            </div>
                        </div>
                        {seeDescr ? (
                            <div
                                className="text-xs text-slate-500 my-2 leading-5 border-l-[0.5px] border-slate-400/60 pl-2"
                                dangerouslySetInnerHTML={createMarkup()}
                            ></div>
                        ) : (
                            <></>
                        )}

                        <div className="mt-6 w-full p-2">
                            <div className="flex justify-between mb-2">
                                <div>
                                    {UtilsService.formatAmount(forPercent, 2)} %{" "}
                                    <span className="text-[#3695D2] ml-2">
                                        {translate("Governance", "Yes")}
                                    </span>
                                </div>
                                <div>
                                    {UtilsService.formatAmount(
                                        againstPercent,
                                        2,
                                    )}{" "}
                                    %{" "}
                                    <span className="text-gray-600 ml-2">
                                        {translate("Governance", "No")}
                                    </span>
                                </div>
                            </div>
                            <div className="h-1.5 bg-gray-400 w-full rounded-lg">
                                <div
                                    className="h-1.5 xia-bg-gradient-circle rounded-lg"
                                    style={{ width: `${forPercent}%` }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center md-w-25">
                        <div className="block">
                            <div>
                                {show_button
                                    ? translate(
                                          "Governance",
                                          "Submit_your_vote",
                                      )
                                    : translate(
                                          "Governance",
                                          "You_have_already_voted",
                                      )}
                            </div>

                            {show_button && (
                                <div className="flex space-x-2 justify-center mt-2">
                                    <button
                                        className="btn xia-bg-gradient-circle hover:bg-[#3695D2] text-white"
                                        onClick={(
                                            event: React.MouseEvent<
                                                HTMLButtonElement,
                                                MouseEvent
                                            >,
                                        ) => handleVote(event, true)}
                                        // disabled={!ableToVote}
                                    >
                                        {translate("Governance", "Yes")}
                                    </button>
                                    <button
                                        className="btn bg-[#1E293B] swal2-cancel hover:bg-bg-gray-200 !border-none text-white"
                                        onClick={(
                                            event: React.MouseEvent<
                                                HTMLButtonElement,
                                                MouseEvent
                                            >,
                                        ) => handleVote(event, false)}
                                        // disabled={!ableToVote}
                                    >
                                        {translate("Governance", "No")}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GouvernanceItems;
