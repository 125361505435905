import React, { useMemo, useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import { ModalContext } from "../../../../Extra/Modal/ModalContext";
import translator from "../../../../Extra/Translation/Translate";
import StopSubs from "./StopSubs";
import CryptoService from "../../../../../Services/Wallets/CryptoService";
import Swal from "sweetalert2";
import alert_message from "../../../../../Services/Alert/AlertMessage";
import { renderToStaticMarkup } from "react-dom/server";
import { useNavigate } from "react-router-dom";
import FidelityService from "../../../../../Services/FidelityService/FidelityService";
import { useFlyoutContext } from "../../../../../App/Provider/FlyOutProvider";
const BtnStopSubsModal = ({ id }: { id: string }) => {
    const { handleModal } = React.useContext(ModalContext);
    const { translate } = translator();
    const [processing, setProcessing] = useState<boolean>(false);
    const {
        flyoutOpen,
        setFlyoutOpen,
        isRefresh,
        activeComponent,
        setActiveComponent,
        setContent,
        setTitle,
    } = useFlyoutContext();
    const navigate = useNavigate();

    const stopSubsById = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        event.preventDefault();
        setProcessing(true);

        const text = renderToStaticMarkup(
            <>
                <p>{translate("SUBSCRIPTION2", "Description_1")}</p>
                <br />
                <p>{translate("SUBSCRIPTION2", "Description_2")}?</p>
            </>,
        );

        Swal.fire({
            title: translate("SUBSCRIPTION2", "Stop_My_Subscription"),
            html: text,
            showCloseButton: true,
            // showCancelButton: true,
            confirmButtonColor:
                "radial-gradient(circle at center,#3695D2 0%,#303B8E 100%)",
            confirmButtonText: translate("SUBSCRIPTION2", "Confirm"),
            // cancelButtonText: translate("Admin_Assets", "Changer d’abonnement"),
            showDenyButton: true,
            denyButtonText: translate("SUBSCRIPTION2", "Change_Subscription"), // Replace with your actual translation key
        }).then((res) => {
            if (res.isConfirmed) {
                try {
                    const promise = FidelityService.stopSubsById(
                        Number(id),
                        translate,
                    );

                    alert_message
                        .promise(
                            translate("BACKEND_RESPONSE", "Update_In_Progress"),
                            promise,
                        )
                        .finally(() => setProcessing(false));
                    setFlyoutOpen(false);
                    setActiveComponent(undefined);
                } catch (error) {}
            } else if (res.isDenied) {
                navigate("/cards");
            }
        });
    };

    return (
        <button
            type="button"
            className="btn !bg-red-600 text-white w-25 hidden md:block"
            // onClick={() => handleModal(<StopSubs />)}
            onClick={(event) => stopSubsById(event)}
        >
            {translate("SUBSCRIPTION", "Stop_subs")}
        </button>
    );
};

export default BtnStopSubsModal;
