import React from "react";
import { NavLink, useParams } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import HelmetComponent from "../../Admin/Layout/HelmetComponent/HelmetComponent";

const Introduction = () => {
    const { translate } = translator();
    const { lang } = useParams();
    return (
        <>
            <HelmetComponent
                title={translate("SEO", "Title_DocAPI")}
                description={translate("SEO", "Desc_DocAPI")}
                link="/documentation/introduction"
                keywords={translate("SEO", "Keys_DocAPI")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={true}
            ></HelmetComponent>
            <Banner title={translate("DOC_API_PAGE", "OFFICIAL_DOC")}></Banner>
            <article className="flex xl:space-x-12">
                <div className="min-w-0">
                    <div className="md:hidden flex items-center mb-8">
                        <div className="flex items-center text-sm whitespace-nowrap min-w-0 ml-3">
                            <span className="text-slate-600 dark:text-slate-400">
                                Documentation
                            </span>
                            <svg
                                className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-500"
                                width="8"
                                height="10"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                            </svg>
                            <span className="text-slate-800 font-medium truncate dark:text-slate-200">
                                Fundamentals
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="text-slate-600 dark:text-slate-400 space-y-6">
                            <div className="space-y-6">
                                <div className="space-y-4">
                                    <p className="text-lg text-slate-600 dark:text-slate-400">
                                        {translate(
                                            "DOC_API_PAGE",
                                            "FIRST_PARAGRAPH",
                                        )}
                                    </p>
                                    <p className="text-lg text-slate-600 dark:text-slate-400">
                                        {translate(
                                            "DOC_API_PAGE",
                                            "SECOND_PARAGRAPH",
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="space-y-6">
                                <div className="space-y-4">
                                    <p className="text-lg text-slate-600 dark:text-slate-400">
                                        <span className="">
                                            {translate(
                                                "DOC_API_PAGE",
                                                "PAYMENT_PARAGRAPH",
                                            )}
                                        </span>
                                        <span className="">
                                            {" "}
                                            <NavLink
                                                to={`${
                                                    lang ? `/${lang}` : ""
                                                }/documentation/payment`}
                                                className={"underline"}
                                            >
                                                #payment
                                            </NavLink>
                                        </span>
                                    </p>
                                    <p className="text-lg text-slate-600 dark:text-slate-400 pt-2">
                                        <span className="">
                                            {" "}
                                            {translate(
                                                "DOC_API_PAGE",
                                                "TRANSFER_PARAGRAPH",
                                            )}
                                        </span>
                                        <span className="">
                                            {" "}
                                            <NavLink
                                                to={`${
                                                    lang ? `/${lang}` : ""
                                                }/documentation/transfer`}
                                                className={"underline"}
                                            >
                                                #transfer
                                            </NavLink>
                                        </span>
                                    </p>

                                    <p className="text-lg text-slate-600 dark:text-slate-400 pt-2">
                                        <span className="">
                                            {translate(
                                                "DOC_API_PAGE",
                                                "WEBHOOK_PARAGRAPH",
                                            )}
                                        </span>
                                        <span className="">
                                            {" "}
                                            <NavLink
                                                to={`${
                                                    lang ? `/${lang}` : ""
                                                }/documentation/payment#webhook`}
                                                className={"underline"}
                                            >
                                                #webhook
                                            </NavLink>
                                        </span>
                                    </p>

                                    <p className="text-lg text-slate-600 dark:text-slate-400 pt-10 pb-2">
                                        <span className="">
                                            {translate(
                                                "DOC_API_PAGE",
                                                "FAQ_PARAGRAPH",
                                            )}
                                        </span>
                                        <span className="">
                                            {" "}
                                            <NavLink
                                                to="https://infinexia.tawk.help/category/api-infinexia"
                                                className={"underline"}
                                            >
                                                {translate(
                                                    "HOME_PAGE",
                                                    "Help_Center",
                                                )}
                                            </NavLink>
                                        </span>
                                    </p>
                                    <p className="text-lg text-slate-600 dark:text-slate-400">
                                        {translate(
                                            "DOC_API_PAGE",
                                            "SUPPORT_PARAGRAPH",
                                        )}
                                    </p>

                                    <p className="text-lg text-slate-600 dark:text-slate-400 pt-10 pb-2">
                                        <span className="">
                                            {translate(
                                                "DOC_API_PAGE",
                                                "PLUGIN_PARAGRAPH_2-1",
                                            )}
                                        </span>
                                        <span className="">
                                            {" "}
                                            <a
                                                href="https://infinexia.com/plugin/infinexia-gateway.zip"
                                                className={"underline"}
                                                download={true}
                                            >
                                                Infinexia Gateway for
                                                Woocommerce
                                            </a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SecondaryNav />
            </article>
        </>
    );
};

export default Introduction;
